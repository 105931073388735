import { useState, useEffect, createContext, useContext, useRef } from "react";
import * as authHelper from "./AuthHelper";
import { AuthContextProps, UserModel } from "./";
import { LayoutSplashScreen } from "../../../components/core";
import { getUser } from "../";
import { useAccount } from "../../../contexts/GenContext";
import { toBase64ID } from "../../../utils";
import { ShippingAddress } from "../../../graphql/order";

const initAuthContextPropsState = {
  access: authHelper.getAccess(),
  saveAccess: () => {},
  refresh: authHelper.getRefresh(),
  saveRefresh: () => {},
  currentIdentifier: undefined,
  setCurrentIdentifier: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentType: undefined,
  setCurrentType: () => {},
  isMfaEnabled: false,
  setMfaEnabled: () => {},
  isLocked: false,
  setLocked: () => {},
  lockedExpiry: undefined,
  setLockedExpiry: () => {},
  getAddress: () => null,
  userIdentifier: undefined,
  setUserIdentifier: () => {},
  getIdentifier: () => undefined,
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);
AuthContext.displayName = "AuthenticationContext";

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [access, setAccess] = useState<string | undefined>(
    authHelper.getAccess()
  );
  const [refresh, setRefresh] = useState<string | undefined>(
    authHelper.getRefresh()
  );

  const [currentIdentifier, setCurrentIdentifier] = useState<
    string | undefined
  >();
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [currentType, setCurrentType] = useState<number | undefined>();
  const [isMfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [isLocked, setLocked] = useState<boolean>(false);
  const [lockedExpiry, setLockedExpiry] = useState<Date | undefined>();
  const [userIdentifier, setUserIdentifier] = useState<string | undefined>();

  const saveAccess = (access: string | undefined) => {
    setAccess(access);
    if (access) {
      authHelper.setAccess(access);
    } else {
      authHelper.removeAccess();
    }
  };

  const saveRefresh = (refresh: string | undefined) => {
    setRefresh(refresh);
    if (refresh) {
      authHelper.setRefresh(refresh);
    } else {
      authHelper.removeRefresh();
    }
  };

  const getAddress = () => {
    let address = "";
    if (!currentUser) return address;
    if (currentUser.details.postalAddress) {
      address = currentUser.details.postalAddress;
    }
    if (currentUser.details.suburb) {
      address = address + ", " + currentUser.details.suburb;
    }
    if (currentUser.details.state) {
      address = address + ", " + currentUser.details.state;
    }
    if (currentUser.details.postcode) {
      address = address + ", " + currentUser.details.postcode;
    }
    return address;
  };

  const logout = async () => {
    saveAccess(undefined);
    saveRefresh(undefined);
    setCurrentIdentifier(undefined);
    setCurrentUser(undefined);
    setCurrentType(undefined);
    setMfaEnabled(false);
    setUserIdentifier(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        access,
        saveAccess,
        refresh,
        saveRefresh,
        currentIdentifier,
        setCurrentIdentifier,
        currentUser,
        setCurrentUser,
        currentType,
        setCurrentType,
        isMfaEnabled,
        setMfaEnabled,
        isLocked,
        lockedExpiry,
        setLockedExpiry,
        setLocked,
        getAddress,
        userIdentifier,
        setUserIdentifier,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = ({ children }: { children: React.ReactNode }) => {
  const {
    logout,
    setCurrentUser,
    setCurrentType,
    setMfaEnabled,
    // setLocked,
    // setLockedExpiry,
    setUserIdentifier,
  } = useAuth();

  const { saveAccount } = useAccount();

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUser();
          if (user) {
            const primaryAddress = user?.shippingAddresses?.find(
              (a: ShippingAddress) => a?.primaryAddress
            );

            const updatedUser: UserModel = {
              ...user,
              primaryAddress: primaryAddress
                ? primaryAddress
                : user?.shippingAddresses?.length > 0
                ? user?.shippingAddresses[0]
                : null,
            };

            setCurrentUser(updatedUser);
            saveAccount(updatedUser);
            setCurrentType(updatedUser.customerType);
            setMfaEnabled(updatedUser.enableMfa);
            // setLocked(updatedUser.isLocked);
            // setLockedExpiry(updatedUser.lockedExpiry);
            const identifier = toBase64ID(updatedUser.customerCode);
            setUserIdentifier(identifier);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    (async () => {
      if (authHelper.getRefresh()) {
        await requestUser();
      } else {
        logout();
        setShowSplashScreen(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
