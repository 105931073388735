import { gql } from "@apollo/client/core";

export const FETCH_CART = gql`
  query FetchCart($cartId: String!) {
    fetchCart(cartId: $cartId) {
      customerPrice
      id
      inventory
      minimumQuantity
      product {
        id
        name
        handle
        featureImageUrl
        galleryImages {
          imageUrl
        }
        hasVariants
        variants {
          stockCode
          comparePrice
          customerBasePrice
          customerSpecialPrice {
            endDate
            price
          }
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
          minimumQuantity
          inventory
        }
      }
      productId
      quantity(cartId: $cartId)
      stockCode
      variantTitle {
        name
        option {
          name
        }
      }
    }
  }
`;

export const UPDATE_CART = gql`
  mutation CartUpdate($cartId: String!, $productId: Int!, $quantity: Int!) {
    cartUpdate(
      input: {
        params: { cartId: $cartId, productId: $productId, quantity: $quantity }
      }
    ) {
      message
    }
  }
`;

export const DELETE_CART = gql`
  mutation CartDelete($cartId: String!, $productIds: [ID!]!) {
    cartDelete(input: { cartId: $cartId, productIds: $productIds }) {
      message
    }
  }
`;

export type CartItem = {
  id: string;
  stockCode: string;
  productId: number;
  customerPrice: number;
  quantity: number;
  minimumQuantity: number;
  inventory: number;
  product: {
    name: string;
    handle: string;
    featureImageUrl: string;
  };
  variantTitle: {
    name: string;
    option: {
      name: string;
    };
  }[];
};

export enum CartAction {
  PLUS,
  MINUS,
  REMOVE,
}