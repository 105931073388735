import { useCallback, useEffect, useState, Fragment, useMemo } from "react";
import { useQuery } from "@apollo/client/react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/solid";

import placeholder from "../../assets/placeholder.svg";
import { useNotifyContext, NotifyType } from "../../contexts/NotifyContext";
import { Swatch, Price, Timer } from "./";
import { classNames } from "../../utils";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ErrorFallback } from "../../components/core";
import {
  FETCH_PRODUCT,
  type Product,
  ProductAction,
  ProductVariantQty,
} from "../../graphql/shop";
import { Button } from "../form";
import { Logo } from "../../animations";
import { useCart } from "../../modules/cart/core/CartProvider";

export function ProductSidebar({ archive }: { archive: string }) {
  const { handle } = useParams<{ handle: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      return navigate(`/${archive}`, { replace: true });
    }, 400);
  }, [archive, navigate]);

  const { data, loading, error } = useQuery(FETCH_PRODUCT, {
    variables: {
      handle,
    },
    onCompleted(data) {
      console.log("data", data);
      if (!data || !data.fetchProduct)
        return <Navigate to={`/${archive}`} replace />;
    },
  });

  const product = useMemo<Product | null>(() => {
    setTimeout(() => {
      setOpen(true);
    }, 200);
    return data?.fetchProduct ?? null;
  }, [data]);

  if (error) return <ErrorFallback error={error} />;

  // if (networkStatus === 1) {
  //   return <div>Loading...</div>;
  // }

  // const isRefetching = networkStatus === 3;

  // console.log("isRefetching", isRefetching);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-xl font-medium text-primary-900">
                          {product?.name || ""}
                        </Dialog.Title>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-900 hover:text-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                            onClick={handleClose}
                          >
                            <span className="sr-only">Close cart</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <div className="flex h-full flex-col items-center justify-center">
                        <Logo />
                      </div>
                    ) : product ? (
                      <PreviewData product={product} />
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function PreviewData({ product }: { product: Product }) {
  const { addNotify } = useNotifyContext();
  const { cartItems, findCart, addCart, updateCart, removeCart } = useCart();
  const [loading, setLoading] = useState(false);
  const firstVariant = product.variants[0];
  const [currentVariant, setCurrentVariant] = useState<ProductVariantQty>({
    ...firstVariant,
    quantity: 0,
  });

  useEffect(() => {
    const cartVariant = findCart(currentVariant.id);
    if (cartVariant) {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: cartVariant.quantity,
      }));
    } else {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: 0,
      }));
    }
  }, [cartItems, currentVariant.id, findCart]);

  const handleAddToCart = useCallback(() => {
    setLoading(true);
    addCart(product, currentVariant, currentVariant.minimumQuantity || 1)
      .then(() => {
        addNotify({
          type: NotifyType.SUCCESS,
          title: "Added to cart",
        });
      })
      .catch((error: { message: string }) => {
        console.log(error);
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to add to cart",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addCart, addNotify, currentVariant, product]);

  const handleRemoveFromCart = useCallback(() => {
    setLoading(true);
    removeCart(currentVariant.id)
      .catch((error: { message: string }) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to update cart",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentVariant.id, removeCart, addNotify]);

  const handleUpdate = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      action: ProductAction
    ) => {
      e.preventDefault();

      const cartVariant = findCart(currentVariant.id);
      if (!cartVariant) {
        handleAddToCart();
        return;
      }

      setLoading(true);
      switch (action) {
        case ProductAction.PLUS:
          if (currentVariant.quantity >= currentVariant.inventory) {
            addNotify({
              type: NotifyType.ERROR,
              title: "Maximum quantity reached",
            });
            setLoading(false);
            return;
          }
          updateCart(currentVariant.id, currentVariant.quantity + 1)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case ProductAction.MINUS:
          if (currentVariant.quantity <= currentVariant.minimumQuantity) {
            addNotify({
              type: NotifyType.ERROR,
              title: "Minimum quantity reached",
            });
            setLoading(false);
            return;
          }
          if (currentVariant.quantity === 1) {
            removeCart(currentVariant.id)
              .catch((error: { message: string }) => {
                addNotify({
                  type: NotifyType.ERROR,
                  title: "Failed to update cart",
                  message: error.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
            return;
          }

          updateCart(currentVariant.id, currentVariant.quantity - 1)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }
    },
    [
      addNotify,
      currentVariant.id,
      currentVariant.inventory,
      currentVariant.minimumQuantity,
      currentVariant.quantity,
      findCart,
      handleAddToCart,
      removeCart,
      updateCart,
    ]
  );

  return (
    <>
      <div className="flex-1 divide-y divide-gray-200 overflow-y-auto px-6 pb-6">
        <div className="relative space-y-4 md:space-y-6">
          <div>
            <Timer
              price={currentVariant.customerSpecialPrice}
              className="mb-6 text-red-500"
            />
            <Tab.Group as="div" className="flex flex-col-reverse">
              {product.galleryImages.length > 0 ? (
                <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-6">
                    {currentVariant.variantImageUrl ? (
                      <Tab
                        key={product.id + "-image-" + currentVariant.id}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only"> {product.name} </span>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={currentVariant.variantImageUrl}
                                alt={product.name}
                                className="h-full w-full object-cover object-center"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-primary-500"
                                  : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ) : (
                      <Tab
                        key={product.id + "-image-" + currentVariant.id}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only"> {product.name} </span>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={
                                  product.featureImageUrl
                                    ? product.featureImageUrl
                                    : placeholder
                                }
                                alt={product.name}
                                className="h-full w-full object-cover object-center"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-primary-500"
                                  : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    )}
                    {product.galleryImages.map((image, index) => (
                      <Tab
                        key={`${product.id}-image-${index}`}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only"> {product.name} </span>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={image.imageUrl}
                                alt={product.name}
                                className="h-full w-full object-cover object-center"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-primary-500"
                                  : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
              ) : null}

              <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                {currentVariant.variantImageUrl ? (
                  <Tab.Panel key={product.id + "-thumb-" + currentVariant.id}>
                    <img
                      src={currentVariant.variantImageUrl}
                      alt={product.name}
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                  </Tab.Panel>
                ) : (
                  <Tab.Panel key={product.id + "-thumb-" + currentVariant.id}>
                    <img
                      src={
                        product.featureImageUrl
                          ? product.featureImageUrl
                          : placeholder
                      }
                      alt={product.name}
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                  </Tab.Panel>
                )}
                {product.galleryImages.map((image, index) => (
                  <Tab.Panel key={`${product.id}-thumb-${index}`}>
                    <img
                      src={image.imageUrl}
                      alt={product.name}
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>

          {product.variants.length > 1 ? (
            <Swatch
              variants={product.variants}
              currentVariant={currentVariant}
              setCurrentVariant={setCurrentVariant}
              className="my-4 space-y-2 rounded-xl bg-greyish p-4"
              itemClass="flex items-center justify-between text-sm font-medium"
              labelClass="text-primary-900/90 capitalize"
              valueClass="text-gray-900 block w-1/2 rounded-lg border-gray-200 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
            />
          ) : null}

          <div>
            <h3 className="mb-2 font-medium text-gray-900">Information</h3>
            <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Department</dt>
                <dd className="text-gray-900">{product.department.name}</dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Category</dt>
                <dd className="text-gray-900">
                  {product.categories.flatMap((c) => c.name).join(", ")}
                </dd>
              </div>
              {currentVariant.minimumQuantity > 1 ? (
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Mininum qty</dt>
                  <dd className="text-gray-900">
                    {currentVariant.minimumQuantity}
                  </dd>
                </div>
              ) : null}
            </dl>
          </div>

          {product.description?.trim()?.length ? (
            <div>
              <h3 className="mb-2 font-medium text-gray-900">Description</h3>
              <div
                className="prose text-sm font-light leading-relaxed text-gray-700"
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="relative m-6 rounded-xl bg-greyish p-4">
        <div className="flex items-center justify-between">
          <Price
            currentVariant={currentVariant}
            className="text-xl font-medium text-gray-500"
          />
          <div className="flex items-center">
            <div className="mr-3 flex items-center justify-center rounded-lg border border-gray-200 bg-white text-center">
              <Button
                variant="icon"
                className="inline-flex h-10 w-10 items-center justify-center bg-transparent transition hover:border-primary-700 hover:text-primary-700"
                onClick={(e) => handleUpdate(e, ProductAction.MINUS)}
                disabled={loading || currentVariant.quantity === 0}
              >
                <MinusSmallIcon aria-hidden="true" className="h-4 w-4" />
              </Button>
              <span className="w-full min-w-[2rem]">
                {currentVariant.quantity}
              </span>
              <Button
                variant="icon"
                className="inline-flex h-10 w-10 items-center justify-center bg-transparent transition hover:border-primary-700 hover:text-primary-700"
                onClick={(e) => handleUpdate(e, ProductAction.PLUS)}
                disabled={loading || currentVariant.inventory === 0}
              >
                <PlusSmallIcon aria-hidden="true" className="h-4 w-4" />
              </Button>
            </div>

            {currentVariant.inventory === 0 ? (
              <Button
                variant="warning"
                className="w-full justify-center whitespace-nowrap"
                disabled
              >
                Out of Stock
              </Button>
            ) : currentVariant.quantity > 0 ? (
              <Button
                variant="danger"
                onClick={handleRemoveFromCart}
                disabled={loading}
                loading={loading}
                className="w-full justify-center whitespace-nowrap"
              >
                Remove
              </Button>
            ) : (
              <Button
                onClick={handleAddToCart}
                disabled={loading}
                loading={loading}
                className="w-full justify-center whitespace-nowrap"
              >
                Add to Cart
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
