import { useState, useEffect, createContext, useContext, useRef } from "react";
import { CartContextProps } from "./";
import { useAuth } from "../../auth";
import * as cartHelper from "./CartHelper";
import { UserModel } from "../../auth/core";
import { toBase64ID } from "../../../utils";
import { Spinner } from "../../../animations";
import { Product, Variant } from "../../../graphql/shop";
import { CartItem } from "../../../graphql/cart";

const initCartContextPropsState = {
  isFetched: false,
  setFetched: () => {},
  cartItems: [],
  setCartItems: () => {},
  cartCount: 0,
  setCartCount: () => {},
  cartTotal: 0,
  setCartTotal: () => {},
  clearCart: async () => {},
  loadCart: async () => {},
  addCart: async () => {},
  removeCart: async () => {},
  updateCart: async () => {},
  findCart: () => undefined,
  cartOpen: false,
  toggleCart: () => {},
};

const CartContext = createContext<CartContextProps>(initCartContextPropsState);
CartContext.displayName = "AuthenticationContext";

const useCart = () => {
  return useContext(CartContext);
};

const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const { userIdentifier, setUserIdentifier } = useAuth();
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [cartCount, setCartCount] = useState<number>(0);
  const [cartTotal, setCartTotal] = useState<number>(0);
  const [cartOpen, setCartOpen] = useState<boolean>(false);
  const [isFetched, setFetched] = useState<boolean>(false);

  const toggleCart = (open?: boolean) => {
    setCartOpen(open ?? !cartOpen);
  };

  const loadCart = async (user: UserModel) => {
    if (!user) return;
    try {
      const identifier = toBase64ID(user.customerCode);
      setUserIdentifier(identifier);
      const { data } = await cartHelper.getCart(identifier);
      console.log("loadCart", data);
      const items: CartItem[] = data.fetchCart;
      setCartItems(items);
      const count = items.reduce((a, b) => a + b.quantity, 0);
      setCartCount(count);
      const total = items.reduce((a, b) => a + b.quantity * b.customerPrice, 0);
      setCartTotal(parseFloat(total.toFixed(2)));
      return items;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const clearCart = async () => {
    if (!userIdentifier) return;
    const variantIds = cartItems.map((item) => parseInt(item.id));
    if (variantIds.length === 0) return;
    try {
      const { data } = await cartHelper.deleteCart(userIdentifier, variantIds);
      setCartItems([]);
      setCartCount(0);
      setCartTotal(0);
      return data.cartDelete;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const addCart = async (
    product: Product,
    variant: Variant,
    quantity: number,
    waitFor: boolean = false
  ) => {
    if (!userIdentifier) return;
    try {
      const { data } = await cartHelper.setCart(
        userIdentifier,
        parseInt(variant.id),
        quantity
      );
      await new Promise((resolve) => setTimeout(resolve, waitFor ? 2500 : 0));
      const items = [
        ...cartItems,
        {
          customerPrice: variant.customerSpecialPrice
            ? parseInt(variant.customerSpecialPrice.price)
            : variant.customerBasePrice,
          id: variant.id,
          inventory: variant.inventory,
          minimumQuantity: variant.minimumQuantity,
          product: product,
          productId: parseInt(product.id),
          quantity: quantity,
          stockCode: variant.stockCode,
          variantTitle: variant.variantTitle,
        },
      ];
      setCartItems(items);
      const count = items.reduce((a, b) => a + b.quantity, 0);
      setCartCount(count);
      const total = items.reduce((a, b) => a + b.quantity * b.customerPrice, 0);
      setCartTotal(parseFloat(total.toFixed(2)));
      return data.cartUpdate;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const updateCart = async (variantId: string, quantity: number) => {
    if (!userIdentifier) return;
    try {
      const { data } = await cartHelper.setCart(
        userIdentifier,
        parseInt(variantId),
        quantity
      );
      const newItems = [...cartItems];
      const items = newItems.map((item) => {
        if (item.id === variantId) {
          return {
            ...item,
            quantity,
          };
        }
        return item;
      });
      setCartItems(items);
      const count = items.reduce((a, b) => a + b.quantity, 0);
      setCartCount(count);
      const total = items.reduce((a, b) => a + b.quantity * b.customerPrice, 0);
      setCartTotal(parseFloat(total.toFixed(2)));
      return data.cartUpdate;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const removeCart = async (variantId: string) => {
    if (!userIdentifier) return;
    try {
      const { data } = await cartHelper.deleteCart(userIdentifier, [
        parseInt(variantId),
      ]);
      const items = cartItems.filter((item) => item.id !== variantId);
      setCartItems(items);
      const count = items.reduce((a, b) => a + b.quantity, 0);
      setCartCount(count);
      const total = items.reduce((a, b) => a + b.quantity * b.customerPrice, 0);
      setCartTotal(parseFloat(total.toFixed(2)));
      return data.cartDelete;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const findCart = (id: string) => {
    return cartItems.find((item: CartItem) => item.id === id);
  };

  return (
    <CartContext.Provider
      value={{
        isFetched,
        setFetched,
        cartItems,
        setCartItems,
        cartCount,
        setCartCount,
        cartTotal,
        setCartTotal,
        clearCart,
        loadCart,
        addCart,
        removeCart,
        updateCart,
        findCart,
        cartOpen,
        toggleCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const CartInit = ({ children }: { children: React.ReactNode }) => {
  const { setCartItems, setCartCount, setCartTotal } = useCart();

  const { userIdentifier } = useAuth();
  const { isFetched, setFetched } = useCart();

  const didRequest = useRef(false);
  const [showCartLoader, setShowCartLoader] = useState(!isFetched);
  useEffect(() => {
    const requestCart = async () => {
      try {
        if (!didRequest.current) {
          if (!userIdentifier) return;
          const { data } = await cartHelper.getCart(userIdentifier);
          const items: CartItem[] = data.fetchCart;
          setFetched(true);
          setCartItems(items);
          const count = items.reduce((a, b) => a + b.quantity, 0);
          setCartCount(count);
          const total = items.reduce(
            (a, b) => a + b.quantity * b.customerPrice,
            0
          );
          setCartTotal(parseFloat(total.toFixed(2)));
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          console.log("fail 1");
        }
      } finally {
        setShowCartLoader(false);
      }

      return () => (didRequest.current = true);
    };

    (async () => {
      if (userIdentifier) {
        await requestCart();
      } else {
        console.log("fail 2");
        setShowCartLoader(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showCartLoader ? (
    <div className="mx-auto flex h-12 w-9 items-center justify-center">
      <Spinner className="text-primary-700" />
    </div>
  ) : (
    <>{children}</>
  );
};

export { CartProvider, CartInit, useCart };
