import {
  UserAccountModelKey,
  UserAccountModelStatus,
  UserModel,
} from "../modules/auth/core";
import { GenContextAction, IPage, InitGenContextPropsState } from "./GenType";

type GenReducerAction<GPayload> = {
  type: GenContextAction;
  payload?: GPayload;
};

export const GenReducer = (
  state: InitGenContextPropsState,
  action: GenReducerAction<any>
) => {
  switch (action.type) {
    case GenContextAction.UPDATE_PAGE:
      const { title, description } = action.payload as IPage;
      return {
        ...state,
        title,
        description,
      };

    case GenContextAction.TOGGLE_MENU:
      return {
        ...state,
        sidebarOpen:
          action.payload !== undefined ? action.payload : !state.sidebarOpen,
      };

    case GenContextAction.SAVE_ACCOUNT:
      const user: UserModel = action.payload;
      const steps = {
        ...state.account.steps,
      };

      if (action.payload) {
        steps[UserAccountModelKey.VENUE_TYPE] = user.details?.venueType
          ? UserAccountModelStatus.COMPLETED
          : UserAccountModelStatus.PENDING;
        steps[UserAccountModelKey.AVERAGE_SPEND] = user.details?.venueFoodSpend
          ? UserAccountModelStatus.COMPLETED
          : UserAccountModelStatus.PENDING;
        steps[UserAccountModelKey.ABN_NUMBER] = user.abnNo
          ? UserAccountModelStatus.COMPLETED
          : UserAccountModelStatus.PENDING;
        steps[UserAccountModelKey.CONTACT_DETAILS] =
          user.details?.postalAddress &&
          user.details?.suburb &&
          user.details?.state &&
          user.details?.postcode &&
          // user.details?.phoneNumber &&
          user.details?.mobileNumber
            ? UserAccountModelStatus.COMPLETED
            : UserAccountModelStatus.PENDING;

        const activeStepObject =
          Object.entries(steps).find(
            ([_, val], i) => val === UserAccountModelStatus.PENDING
          ) || [];
        const [activeStep] = activeStepObject;
        const completed = activeStep === undefined;
        const percentage = Math.round(
          (Object.values(steps).filter(
            (step) => step === UserAccountModelStatus.COMPLETED
          ).length /
            Object.values(steps).length) *
            100
        );

        return {
          ...state,
          account: {
            ...state.account,
            steps,
            activeStep,
            completed,
            percentage,
          },
        };
      }
      return state;

    case GenContextAction.TOGGLE_ACCOUNT:
      return {
        ...state,
        account: {
          ...state.account,
          open:
            action.payload !== undefined ? action.payload : !state.account.open,
        },
      };

    default:
      return state;
  }
};
