import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../";

import { Head } from "../../../components/core";

export function Expired() {
  const location: any = useLocation();
  const navigate = useNavigate();

  const {
    saveAccess,
    saveRefresh,
    setCurrentIdentifier,
    setCurrentUser,
    setCurrentType,
    setMfaEnabled,
    setUserIdentifier,
  } = useAuth();

  const [redirectIn, setRedirectIn] = useState(5);

  useEffect(() => {
    if (redirectIn === 0) {
        return navigate("/auth/login", { replace: true });
    }
    if (!redirectIn) return;
    const intervalId = setInterval(() => {
      setRedirectIn(redirectIn - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [navigate, redirectIn]);

  const didLogoutAndRedirect = useRef(false);
  const logoutAndRedirect = useCallback(async () => {
    saveAccess(undefined);
    saveRefresh(undefined);
    setCurrentIdentifier(undefined);
    setCurrentUser(undefined);
    setCurrentType(undefined);
    setMfaEnabled(false);
    setUserIdentifier(undefined);
  }, [
    saveAccess,
    saveRefresh,
    setCurrentIdentifier,
    setCurrentType,
    setCurrentUser,
    setMfaEnabled,
    setUserIdentifier,
  ]);

  useEffect(() => {
    if (!didLogoutAndRedirect.current) {
      logoutAndRedirect();
      didLogoutAndRedirect.current = true;
    }
  }, [logoutAndRedirect]);

  return (
    <>
      <Head title="Session Expired" />
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main>
            <div className="sm:flex">
              <p className="font-serif text-4xl font-extrabold text-primary-600 sm:text-5xl">
                ⚠️
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="mb-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                    {`${
                      location.state?.title
                        ? location.state.title
                        : "Session Expired"
                    }`}
                  </h1>
                  <p className="mt-1 text-base text-gray-500 capitalize-first">
                    {`${
                      location.state?.message
                        ? location.state.message
                        : "Your session has expired."
                    }`}
                    <br />
                    You will be redirected to the login page in <span>{redirectIn}</span> seconds.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-10 flex justify-center space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                to="/auth/login"
                className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                Sign In
              </Link>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
