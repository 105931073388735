import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { Field, Button, FieldUploader } from "../../../../components/form/";
import { ABNValidation, classNames, toSlug } from "../../../../utils";
import { Logo, Spinner } from "../../../../animations";
import {
  FETCH_ABN_DETAILS,
  IAbnDetails,
  IFetchAbnDetails,
  formatAbnDetails,
} from "../../../../graphql/account";
import { useLazyQuery } from "@apollo/client";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../auth";
import { CustomerType } from "../../../auth/core";

type IAccountProfile = {
  customerName: string;
  companyName: string;
  firstName: string;
  lastName: string;
  customerType: CustomerType;
  profileImageUrl: string;
  abnNo: string;
  storeEmail: string;
  storeContact: string;
  storePhoneNumber: string;
  storeFax: string;
  emailSpecials: boolean;
  emailImportantUpdates: boolean;
  deliveryInstruction: string;
};

declare module "yup" {
  interface StringSchema {
    abnNumber(message?: string): this;
  }
}

Yup.addMethod(Yup.string, "abnNumber", function (errorMessage) {
  return this.test(`test-abn`, errorMessage, function (value) {
    const { path, createError } = this;
    return ABNValidation(value) || createError({ path, message: errorMessage });
  });
});

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().when("customerType", {
    is: (val: number) => val === CustomerType.INDIVIDUAL,
    then: (schema) =>
      schema.min(2, "Too Short!").max(80, "Too Long!").required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lastName: Yup.string().when("customerType", {
    is: (val: number) => val === CustomerType.INDIVIDUAL,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  companyName: Yup.string().when("customerType", {
    is: (val: number) => val === CustomerType.COMPANY,
    then: (schema) =>
      schema.min(2, "Too Short!").max(80, "Too Long!").required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  profileImageUrl: Yup.string(),
  abnNo: Yup.string().abnNumber("Invalid ABN").required("Required"),
  storeEmail: Yup.string().email("Invalid email"),
  storeContact: Yup.string(),
  storePhoneNumber: Yup.string().required("Required"),
  storeFax: Yup.string(),
  emailSpecials: Yup.boolean().required("Required"),
  emailImportantUpdates: Yup.boolean().required("Required"),
  deliveryInstruction: Yup.string(),
});

export default function FormProfile({
  initialValues,
  onSubmit,
  onCancel,
}: {
  initialValues: IAccountProfile;
  onSubmit: (values: any, actions: any) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const [loadingAbn, setLoadingAbn] = useState(false);
  const [abnDetails, setAbnDetails] = useState<IAbnDetails | null>(null);
  const { currentUser } = useAuth();

  const [fetchAbnDetails] = useLazyQuery<{
    fetchAbnDetails: IFetchAbnDetails;
  }>(FETCH_ABN_DETAILS);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ProfileSchema,
    onSubmit: onSubmit,
  });

  const fetchingAbnDetails = useCallback(
    (abnNo: string) => {
      setLoadingAbn(true);
      fetchAbnDetails({
        variables: {
          abnNo,
        },
      })
        .then(({ data }) => {
          if (data?.fetchAbnDetails?.details) {
            if (data?.fetchAbnDetails?.details?.businessEntity201205) {
              const formated = formatAbnDetails(data?.fetchAbnDetails);
              setAbnDetails(formated);
            } else {
              setAbnDetails(null);
            }
          } else {
            setAbnDetails(null);
          }
        })
        .catch((e) => {
          setAbnDetails(null);
        })
        .finally(() => {
          setLoadingAbn(false);
        });
    },
    [fetchAbnDetails]
  );

  useEffect(() => {
    if (formik.values.abnNo.length < 11) {
      setAbnDetails(null);
      return;
    }
    fetchingAbnDetails(formik.values.abnNo);
  }, [fetchingAbnDetails, formik.values.abnNo]);

  return (
    <form className="mt-5" onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        {currentUser?.customerType === CustomerType.COMPANY ? (
          <Fragment>
            <div>
              <Field
                title={t("text_company_name")}
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                touched={formik.touched.companyName}
                errors={formik.errors.companyName}
              />
            </div>
            <div>
              <Field
                title={t("text_abn_no")}
                name="abnNo"
                onChange={(e) => {
                  const { value } = e.target;
                  const updatedValue = value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("abnNo", updatedValue);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.abnNo}
                touched={formik.touched.abnNo}
                errors={formik.errors.abnNo}
              />
              {loadingAbn ? (
                <div className="mt-4 text-center">
                  <Logo />
                </div>
              ) : abnDetails ? (
                <div className="mt-4 rounded-lg bg-primary-50 p-2 text-sm text-primary-900 md:p-4">
                  <ul className="space-y-2">
                    <li>
                      <b>{abnDetails.name}</b>
                    </li>
                    <li>ABN status: Active from {abnDetails.activeFrom}</li>
                    <li>Entity type: {abnDetails.type}</li>
                    <li>
                      Goods & Services Tax (GST): Registered from{" "}
                      {abnDetails.registeredFrom}
                    </li>
                    <li>Main business location: {abnDetails.address}</li>
                    <li className="flex flex-wrap">
                      <span className="mr-2">Buiness names:</span>
                      <Fragment>
                        {abnDetails.business.map((b) => (
                          <span
                            key={`b-${toSlug(b)}`}
                            className="mb-1 mr-1 inline-flex items-center rounded-full bg-primary-700 pl-2 pr-3 text-xs font-normal leading-6 text-white"
                          >
                            <span className="mr-1 h-3 w-3 rounded-full bg-white"></span>
                            {b}
                          </span>
                        ))}
                      </Fragment>
                    </li>
                  </ul>
                </div>
              ) : formik.values.abnNo.length === 11 ? (
                <div className="text-center">
                  <span className="mt-4 inline-block text-sm text-red-500">
                    <sup>*</sup> {t("text_abn_no_info")}
                  </span>
                </div>
              ) : null}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <Field
                title={t("text_firstname")}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                touched={formik.touched.firstName}
                errors={formik.errors.firstName}
              />
            </div>
            <div>
              <Field
                title={t("text_lastname")}
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                touched={formik.touched.lastName}
                errors={formik.errors.lastName}
              />
            </div>
          </Fragment>
        )}
        <div>
          <Field
            title={t("text_store_email")}
            name="storeEmail"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.storeEmail}
            touched={formik.touched.storeEmail}
            errors={formik.errors.storeEmail}
          />
        </div>
        <div>
          <Field
            title={t("text_store_contact")}
            name="storeContact"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.storeContact}
            touched={formik.touched.storeContact}
            errors={formik.errors.storeContact}
          />
        </div>
        <div>
          <Field
            title={t("text_store_phone_number")}
            name="storePhoneNumber"
            type="tel"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.storePhoneNumber}
            touched={formik.touched.storePhoneNumber}
            errors={formik.errors.storePhoneNumber}
          />
        </div>
        <div>
          <Field
            title={t("text_store_fax")}
            name="storeFax"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.storeFax}
            touched={formik.touched.storeFax}
            errors={formik.errors.storeFax}
          />
        </div>
        <div>
          <Switch.Group as="div" className="inline-flex items-center">
            <Switch
              checked={formik.values.emailSpecials}
              onChange={() => {
                formik.setFieldValue(
                  "emailSpecials",
                  !formik.values.emailSpecials
                );
              }}
              id="emailSpecials"
              className={classNames(
                formik.values.emailSpecials ? "bg-primary-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  formik.values.emailSpecials
                    ? "translate-x-5"
                    : "translate-x-0",
                  "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label
              passive
              htmlFor="emailSpecials"
              className="ml-2 mb-0 block text-sm font-normal text-gray-900"
            >
              {t("text_email_specials")}
            </Switch.Label>
          </Switch.Group>
        </div>
        <div>
          <Switch.Group as="div" className="inline-flex items-center">
            <Switch
              checked={formik.values.emailImportantUpdates}
              onChange={() => {
                formik.setFieldValue(
                  "emailImportantUpdates",
                  !formik.values.emailImportantUpdates
                );
              }}
              id="emailImportantUpdates"
              className={classNames(
                formik.values.emailImportantUpdates
                  ? "bg-primary-600"
                  : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  formik.values.emailImportantUpdates
                    ? "translate-x-5"
                    : "translate-x-0",
                  "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label
              passive
              htmlFor="emailImportantUpdates"
              className="ml-2 mb-0 block text-sm font-normal text-gray-900"
            >
              {t("text_email_important_updates")}
            </Switch.Label>
          </Switch.Group>
        </div>

        <div>
          <Field
            title={t("text_delivery_instruction")}
            name="deliveryInstruction"
            type="textarea"
            className="h-42 md:h-44"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.deliveryInstruction}
            touched={formik.touched.deliveryInstruction}
            errors={formik.errors.deliveryInstruction}
          />
        </div>
        <div>
          <div className="max-w-xs">
            <FieldUploader
              title={t("text_photo")}
              name="profileImageUrl"
              directory="customer/profile"
              onChange={(value: any) => {
                console.log("value", value);
                formik.setFieldValue("profileImageUrl", value);
              }}
              value={formik.values.profileImageUrl}
              touched={formik.touched.profileImageUrl}
              errors={formik.errors.profileImageUrl}
            />
          </div>
        </div>
        <div className="col-span-2 flex justify-end space-x-4">
          <Button
            variant="secondary"
            onClick={() => {
              onCancel();
              formik.resetForm();
            }}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              t("text_save")
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}
