import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import {
  Button,
  Field,
  SelectWrapper,
  selectStyles,
  FieldAddress,
  FieldState,
} from "../../components/form";
import { classNames } from "../../utils";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Spinner } from "../../animations";
import { useAuth } from "../auth";
import { useNotifyContext, NotifyType } from "../../contexts/NotifyContext";
import { useCart } from "../cart/core/CartProvider";
import { Address } from "../../graphql/shipping";
import { Head } from "../../components/core";

import logo from "../../assets/logo.svg";
import { Breadcrumb } from "../../components/interface";
const { REACT_APP_NAME } = process.env;

const FETCH_SHIPPING_ADDRESSES = gql`
  query Me {
    me {
      shippingAddresses {
        id
        address
        suburb
        state
        postcode
        primaryAddress
      }
    }
  }
`;

const CREATE_SHIPPING_ADDRESS = gql`
  mutation ShippingAddressCreate(
    $address: String
    $suburb: String
    $state: String
    $postcode: String
    $primaryAddress: Boolean
  ) {
    shippingAddressCreate(
      input: {
        params: {
          address: $address
          suburb: $suburb
          state: $state
          postcode: $postcode
          primaryAddress: $primaryAddress
        }
      }
    ) {
      shippingAddress {
        id
        address
        postcode
        state
        suburb
        primaryAddress
      }
    }
  }
`;

const UPDATE_SHIPPING_ADDRESS = gql`
  mutation ShippingAddressUpdate(
    $id: ID!
    $address: String
    $suburb: String
    $state: String
    $postcode: String
    $primaryAddress: Boolean
  ) {
    shippingAddressUpdate(
      input: {
        id: $id
        params: {
          address: $address
          suburb: $suburb
          state: $state
          postcode: $postcode
          primaryAddress: $primaryAddress
        }
      }
    ) {
      shippingAddress {
        id
        address
        postcode
        state
        suburb
        primaryAddress
      }
    }
  }
`;

export const ShippingPage = () => {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAuth();
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const { addNotify } = useNotifyContext();

  const { data: dataShippingAddresses, refetch: refetchShippingAddresses } =
    useQuery(FETCH_SHIPPING_ADDRESSES);

  const [createShippingAddress] = useMutation(CREATE_SHIPPING_ADDRESS, {
    refetchQueries: [{ query: FETCH_SHIPPING_ADDRESSES }],
  });
  const [updateShippingAddress] = useMutation(UPDATE_SHIPPING_ADDRESS, {
    refetchQueries: [{ query: FETCH_SHIPPING_ADDRESSES }],
  });

  const ShippingAddressSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    address: Yup.string().required("Required"),
    suburb: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
    primaryAddress: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      id: currentUser?.primaryAddress?.id || "",
      address: currentUser?.primaryAddress?.address || "",
      suburb: currentUser?.primaryAddress?.suburb || "",
      state: currentUser?.primaryAddress?.state || "",
      postcode: currentUser?.primaryAddress?.postcode || "",
      primaryAddress: currentUser?.primaryAddress ? true : false,
    },
    enableReinitialize: true,
    validationSchema: ShippingAddressSchema,
    onSubmit: (
      values: Address,
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      if (!currentUser) return;

      if (currentUser.primaryAddress) {
        const { __typename, ...address } =
          currentUser.primaryAddress as Address & { __typename: string };

        if (JSON.stringify(values) === JSON.stringify(address)) {
          return navigate("/checkout/confirmation", { replace: true });
        }
      }

      if (!values.id) {
        createShippingAddress({
          variables: {
            address: values.address,
            suburb: values.suburb,
            state: values.state,
            postcode: values.postcode,
            primaryAddress: values.primaryAddress,
          },
        })
          .then(async ({ data }) => {
            if (data?.shippingAddressCreate) {
              const address = data.shippingAddressCreate;
              await refetchShippingAddresses();
              setCurrentUser({
                ...currentUser,
                shippingAddresses: [
                  ...currentUser.shippingAddresses,
                  address.shippingAddress,
                ],
                primaryAddress: address.shippingAddress,
              });
              return navigate("/checkout/confirmation", { replace: true });
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Shipping address creation failed",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Shipping address creation failed",
              message: error.message,
            });
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
        return;
      }

      const prevAddress = dataShippingAddresses?.me?.shippingAddresses.find(
        (address: WithRequired<Address, "id">) => address.id === values.id
      );

      if (prevAddress) {
        const { __typename, ...address } = prevAddress as Address & {
          __typename: string;
        };

        if (JSON.stringify(values) === JSON.stringify(address)) {
          return navigate("/checkout/confirmation", { replace: true });
        }
      }

      updateShippingAddress({
        variables: {
          id: values.id,
          address: values.address,
          suburb: values.suburb,
          state: values.state,
          postcode: values.postcode,
          primaryAddress: values.primaryAddress,
        },
      })
        .then(async ({ data }) => {
          if (data?.shippingAddressUpdate) {
            const address = data.shippingAddressUpdate;
            await refetchShippingAddresses();
            setCurrentUser({
              ...currentUser,
              shippingAddresses: [
                ...currentUser.shippingAddresses,
                address.shippingAddress,
              ],
              primaryAddress: address.shippingAddress,
            });
            return navigate("/checkout/confirmation", { replace: true });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Shipping address creation failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Shipping address creation failed",
            message: error.message,
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  if (!currentUser) return <Navigate to="/auth/login" />;
  if (!cartItems.length) return <Navigate to="/cart" />;

  return (
    <>
      <Head title={ShippingResource.name} />
      <h1 className="sr-only">{ShippingResource.name}</h1>
      <div className="mx-auto max-w-4xl py-16 px-4 sm:py-24 sm:px-6 lg:px-0">
        <header className="mb-4 md:mb-8">
          <img
            className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
            src={logo}
            alt={REACT_APP_NAME}
          />
        </header>
        <Breadcrumb
          data={[
            {
              name: "Back to Home",
              href: "/",
            },
            { name: "Cart", href: "/cart" },
            {
              name: "Shipping",
              href: "/checkout/shipping",
              active: true,
            },
            {
              name: "Checkout",
              href: "/checkout/confirmation",
            },
            {
              name: "Completed",
              href: null,
            },
          ]}
          className="mb-12 md:mb-16"
        />
        <div className="mx-auto w-full max-w-lg">
          <form onSubmit={formik.handleSubmit}>
            <p className="text-gray-700">
              Select shipping address or Type to create a new address
            </p>

            <div className="mt-6">
              <div className="mb-1 block text-base font-medium text-gray-700">
                Select address
              </div>
              <FieldShippingAddress
                data={dataShippingAddresses?.me?.shippingAddresses}
                onChange={(value: WithRequired<Address, "id"> | null) => {
                  if (value) {
                    formik.setValues({
                      id: value.id,
                      address: value.address,
                      suburb: value.suburb,
                      state: value.state,
                      postcode: value.postcode,
                      primaryAddress: value.primaryAddress,
                    });
                    return;
                  }
                  formik.setValues({
                    id: "",
                    address: "",
                    suburb: "",
                    state: "",
                    postcode: "",
                    primaryAddress: false,
                  });
                }}
                value={formik.values.id ? parseInt(formik.values.id) : null}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                )}
              />
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
              <div>
                <FieldAddress
                  title={t("text_address")}
                  name="address"
                  type="street"
                  onChange={(value) => {
                    const { street, state, suburb, postcode } = value;
                    formik.setValues({
                      ...formik.values,
                      address: street,
                      suburb: suburb || formik.values.suburb,
                      state: state || formik.values.state,
                      postcode: postcode || formik.values.postcode,
                    });
                  }}
                  value={formik.values.address}
                  touched={formik.touched.address}
                  errors={formik.errors.address}
                />
                {formik.touched.address && formik.errors.address ? (
                  <p className="mt-2 text-sm text-red-600" id="address-errors">
                    {formik.errors.address.toString()}
                  </p>
                ) : null}
              </div>

              <div>
                <Field
                  title={t("text_suburb")}
                  name="suburb"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.suburb}
                  touched={formik.touched.suburb}
                  errors={formik.errors.suburb}
                />
              </div>

              <div>
                <FieldState
                  title={t("text_state")}
                  name="state"
                  onChange={(value) => {
                    formik.setFieldValue("state", value);
                  }}
                  value={formik.values.state}
                  touched={formik.touched.state}
                  errors={formik.errors.state}
                />
                {formik.touched.state && formik.errors.state ? (
                  <p className="mt-2 text-sm text-red-600" id="state-errors">
                    {formik.errors.state.toString()}
                  </p>
                ) : null}
              </div>

              <div>
                <FieldAddress
                  title={t("text_postcode")}
                  name="postcode"
                  type="postcode"
                  onChange={(value) => {
                    const { street, state, suburb, postcode } = value;
                    formik.setValues({
                      ...formik.values,
                      address: street || formik.values.address,
                      suburb: suburb || formik.values.suburb,
                      state: state || formik.values.state,
                      postcode: postcode,
                    });
                  }}
                  value={formik.values.postcode}
                  touched={formik.touched.postcode}
                  errors={formik.errors.postcode}
                />
                {formik.touched.postcode && formik.errors.postcode ? (
                  <p className="mt-2 text-sm text-red-600" id="postcode-errors">
                    {formik.errors.postcode.toString()}
                  </p>
                ) : null}
              </div>

              <div>
                <Field
                  title={t("text_primary_address")}
                  id="primaryAddress"
                  name="primaryAddress"
                  type="checkbox"
                  aria-describedby="primaryAddress-description"
                  checked={formik.values.primaryAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className="mt-6">
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                className="w-full justify-center"
              >
                {formik.isSubmitting ? (
                  <>
                    <Spinner />
                    {t("text_processing")}
                  </>
                ) : formik.values.id ? (
                  t("text_continue_checkout")
                ) : (
                  t("text_create_continue_checkout")
                )}
              </Button>

              <Button
                variant="text"
                onClick={() => {
                  formik.resetForm();
                  navigate("/", { replace: true });
                }}
                className="mt-2 w-full justify-center text-primary-700"
              >
                Continue Shopping
                <ArrowRightIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const ShippingResource: ResourceProps = {
  name: "Shipping Address",
  path: "/checkout/shipping",
};

function FieldShippingAddress({
  data,
  value,
  onChange,
  className,
}: {
  data: WithRequired<Address, "id">[];
  value: number | null;
  onChange: (newValue: WithRequired<Address, "id"> | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);

  useEffect(() => {
    if (data) {
      const options = data.map((v) => ({
        value: v.id,
        label: v.address + " " + v.suburb + " " + v.state + " " + v.postcode,
      }));
      setOptions(options);
    }
  }, [data]);

  useEffect(() => {
    if (value) {
      const selected = options.find((v) => parseInt(v.value) === value);
      if (selected) {
        setValues(selected);
      }
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue) => {
          setValues(newValue);
          const updateValue = newValue
            ? data.find((v) => v.id === newValue.value)
            : null;
          onChange(updateValue ? updateValue : null);
        }}
        isClearable={true}
      />
    </SelectWrapper>
  );
}
