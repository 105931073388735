import { Fragment, useCallback, useRef, useState } from "react";
import { DateTime, Settings } from "luxon";
import { Transition, Dialog } from "@headlessui/react";
import { useReactToPrint } from "react-to-print";

import { Order } from "../../graphql/order";
import { PrintTemplate } from "./PrintTemplate";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../form";
import { Spinner } from "../../animations";

import placeholder from "../../assets/placeholder.svg";

Settings.defaultLocale = "en-AU";

export function Modal({
  order,
  setOrder,
}: {
  order: Order | undefined;
  setOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
}) {
  const { t, i18n } = useTranslation();
  const orderComponentRef = useRef(null);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const orderPrintContent = useCallback(() => {
    return orderComponentRef.current;
  }, [orderComponentRef.current]);

  const handlePrint = useReactToPrint({
    content: orderPrintContent,
    documentTitle: `Alpha Fresh - Order #${order?.orderNumber}`,
    onBeforeGetContent: () => {
      setLoadingPrint(true);
    },
    onAfterPrint: () => {
      setLoadingPrint(false);
    },
    removeAfterPrint: true,
  });

  if (!order) return null;

  return (
    <>
      <Transition.Root show={order ? true : false} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOrder(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-4xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1" ref={orderComponentRef}>
                    <div className="py-8 px-4 sm:px-6">
                      <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                          <Dialog.Title className="text-xl font-semibold text-gray-900">
                            Order No. #{order?.orderNumber}
                          </Dialog.Title>
                          <p className="mt-2 text-sm text-gray-700">
                            Order created on{" "}
                            <time dateTime={order.createdAt}>
                              {DateTime.fromISO(order.createdAt, {
                                locale: i18n.language,
                              }).toLocaleString(DateTime.DATE_MED)}
                            </time>
                            .
                          </p>
                        </div>
                        <div className="mt-4 flex space-x-4 sm:mt-0 sm:ml-16">
                          <Button
                            variant="secondary"
                            onClick={handlePrint}
                            className="print:hidden"
                            disabled={loadingPrint}
                          >
                            {loadingPrint ? (
                              <>
                                <Spinner /> {t("text_processing")}
                              </>
                            ) : (
                              t("text_print")
                            )}
                          </Button>
                          <Button
                            variant="tertiary"
                            onClick={() => setOrder(undefined)}
                            className="print:hidden"
                          >
                            {t("text_close")}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="px-4 sm:px-6">
                        <div key={order.orderNumber}>
                          <h3 className="sr-only">
                            Order placed on{" "}
                            <time dateTime={order.createdAt}>
                              {DateTime.fromISO(order.createdAt, {
                                locale: i18n.language,
                              }).toLocaleString(DateTime.DATE_MED)}
                            </time>
                          </h3>

                          <div className="bg-greyish px-4 py-6 sm:rounded-lg sm:p-6 md:flex md:items-center md:justify-between md:space-x-6 lg:space-x-8">
                            <dl className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-3 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:w-1/2 lg:flex-none lg:gap-x-8">
                              <div className="flex justify-between md:block">
                                <dt className="font-medium text-gray-900">
                                  Order number
                                </dt>
                                <dd className="md:mt-1">
                                  #{order.orderNumber}
                                </dd>
                              </div>
                              <div className="flex justify-between pt-4 md:block md:pt-0">
                                <dt className="font-medium text-gray-900">
                                  Date placed
                                </dt>
                                <dd className="md:mt-1">
                                  <time dateTime={order.createdAt}>
                                    {DateTime.fromISO(order.createdAt, {
                                      locale: i18n.language,
                                    }).toLocaleString(DateTime.DATE_MED)}
                                  </time>
                                </dd>
                              </div>
                              <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                <dt>Total amount</dt>
                                <dd className="md:mt-1">${order.total}</dd>
                              </div>
                            </dl>
                            {/* <div className="mt-6 space-y-4 sm:flex sm:space-x-4 sm:space-y-0 md:mt-0">
                        <button
                          className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 md:w-auto"
                          onClick={() => {
                            handlePrint();
                          }}
                        >
                          View Invoice
                          <span className="sr-only">
                            for order {order.orderNumber}
                          </span>
                        </button>
                      </div> */}
                          </div>

                          <table className="mt-4 w-full text-gray-500 sm:mt-6">
                            <caption className="sr-only">Products</caption>
                            <thead className="sr-only text-left text-sm text-gray-500 sm:not-sr-only">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3 pr-8 font-normal sm:w-2/5 lg:w-2/4"
                                >
                                  Product
                                </th>
                                <th
                                  scope="col"
                                  className="hidden py-3 pr-8 font-normal sm:table-cell"
                                >
                                  Quantity
                                </th>
                                <th
                                  scope="col"
                                  className="hidden py-3 pr-8 font-normal sm:table-cell"
                                >
                                  Price
                                </th>
                                <th
                                  scope="col"
                                  className="hidden py-3 pr-8 font-normal sm:table-cell"
                                >
                                  Comments
                                </th>
                                <th
                                  scope="col"
                                  className="w-0 py-3 text-right font-normal"
                                >
                                  Info
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                              {order.items.map((item) => (
                                <tr key={item.id}>
                                  <td className="py-6 pr-8">
                                    <div className="flex items-center">
                                      <img
                                        src={
                                          item.variant.product.featureImageUrl
                                            ? item.variant.product
                                                .featureImageUrl
                                            : placeholder
                                        }
                                        alt={item.variant.product.name}
                                        className="mr-6 h-16 w-16 rounded object-cover object-center"
                                      />
                                      <div>
                                        <div className="font-medium text-gray-900">
                                          {item.variant.product.name}
                                        </div>
                                        <div className="mt-1 sm:hidden">
                                          ${item.price}
                                        </div>
                                        <div className="mt-1 sm:hidden">
                                          {item.orderComments}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="hidden py-6 pr-8 sm:table-cell">
                                    {item.quantity}
                                  </td>
                                  <td className="hidden py-6 pr-8 sm:table-cell">
                                    ${item.price}
                                  </td>
                                  <td className="hidden py-6 pr-8 sm:table-cell">
                                    {item.orderComments}
                                  </td>
                                  <td className="whitespace-nowrap py-6 text-right font-medium">
                                    <Link
                                      to={`/shop/${item.variant.product.handle}`}
                                      target="_blank"
                                      className="text-primary-600"
                                    >
                                      View{" "}
                                      <span className="hidden lg:inline">
                                        Product
                                      </span>
                                      <span className="sr-only">
                                        , {item.variant.product.name}
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="my-4 grid gap-4 sm:my-6 sm:grid-cols-3">
                            <div>
                              <h3 className="text-base font-medium text-gray-900">
                                Delivery Address
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {`${order.shippingAddress.address} ${order.shippingAddress.suburb} ${order.shippingAddress.state} ${order.shippingAddress.postcode}`}
                              </p>
                            </div>
                            <div>
                              <h3 className="text-base font-medium text-gray-900">
                                Delivery Date
                              </h3>
                              <time
                                dateTime={order.deliveryDate}
                                className="mt-1 text-sm text-gray-500"
                              >
                                {DateTime.fromISO(order.createdAt, {
                                  locale: i18n.language,
                                }).toLocaleString(DateTime.DATE_MED)}
                              </time>
                            </div>
                            <div>
                              <h3 className="text-base font-medium text-gray-900">
                                Delivery Note
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {order.notes}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden">
        <div ref={orderComponentRef}>
          <PrintTemplate order={order} />
        </div>
      </div>
    </>
  );
}
