import { Dispatch, SetStateAction } from "react";

export interface UserDetailsModel {
  emailImportantUpdates: boolean;
  emailSpecials: boolean;
  mobileNumber: string;
  phoneNumber: string;
  postalAddress: string;
  postcode: string;
  state: string;
  suburb: string;
  venueFoodSpend: {
    id: string;
    spendRange: string;
  };
  venueType: {
    id: string;
    name: string;
  };
}

export interface UserShippingAddressModel {
  id: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
}

export enum UserAccountModelKey {
  VENUE_TYPE = "venue_type",
  AVERAGE_SPEND = "venue_food_spend",
  CONTACT_DETAILS = "delivery_details",
  ABN_NUMBER = "abn_no",
}
export enum UserAccountModelStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  ACTIVE = "active",
}
export type UserAccountModelDetails = {
  title: string;
  description: string;
  status: UserAccountModelStatus;
};
export interface UserAccountModel {
  steps: {
    [key in UserAccountModelKey]: UserAccountModelStatus;
  };
  activeStep: string | undefined;
  percentage: number;
  completed: boolean;
  open: boolean;
}

export interface UserCommunicationModel {
  email: boolean;
  sms: boolean;
  phoneNumber: boolean;
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean;
  sendCopyToPersonalEmail?: boolean;
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean;
    youAreSentADirectMessage?: boolean;
    someoneAddsYouAsAsAConnection?: boolean;
    uponNewOrder?: boolean;
    newMembershipApproval?: boolean;
    memberRegistration?: boolean;
  };
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean;
    tipsOnGettingMoreOutOfKeen?: boolean;
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean;
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean;
    tipsOnStartBusinessProducts?: boolean;
  };
}

export interface UserSocialNetworksModel {
  linkedIn: string;
  facebook: string;
  twitter: string;
  instagram: string;
}
export type Permission = {
  id: string;
  permissionName: string;
  permissionDisplayName: string;
  permissionDescription: string;
};
export type Role = {
  id: string;
  roleName: string;
  permissions: Permission[];
  status: boolean;
};

export enum CustomerType {
  INDIVIDUAL,
  COMPANY,
}
export interface UserModel {
  id: string;
  customerType: CustomerType;
  companyName: string;
  customerCode: string;
  userName: string;
  password: string | undefined;
  email: string;
  firstName: string;
  lastName: string;
  customerName: string;
  phoneNumber?: string;
  enableMfa: boolean;
  roles: Role[];
  profileImageUrl?: string;
  language?: "en" | "fr";
  timeZone?: string;
  emailSettings?: UserEmailSettingsModel;
  communication?: UserCommunicationModel;
  details: UserDetailsModel;
  shippingAddresses: UserShippingAddressModel[];
  primaryAddress: UserShippingAddressModel | null;
  abnNo: string;
  storeEmail: string;
  storePhoneNumber: string;
  storeContact: string;
  storeFax: string;
  deliveryInstruction: string;
}

export type AuthContextProps = {
  access: string | undefined;
  saveAccess: (access: string | undefined) => void;
  refresh: string | undefined;
  saveRefresh: (refresh: string | undefined) => void;
  currentIdentifier: string | undefined;
  setCurrentIdentifier: Dispatch<SetStateAction<string | undefined>>;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  currentType: number | undefined;
  setCurrentType: Dispatch<SetStateAction<number | undefined>>;
  isMfaEnabled: boolean;
  setMfaEnabled: Dispatch<SetStateAction<boolean>>;
  isLocked: boolean;
  setLocked: Dispatch<SetStateAction<boolean>>;
  lockedExpiry: Date | undefined;
  setLockedExpiry: Dispatch<SetStateAction<Date | undefined>>;
  getAddress: () => string | null;
  userIdentifier: string | undefined;
  setUserIdentifier: Dispatch<SetStateAction<string | undefined>>;
  logout: () => void;
};

export interface RegisterTypes {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  roleId: string;
}

export interface RegisterVariables {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  roleId: string;
}

export interface LoginTypes {
  identifier: string;
  password: string;
}

export interface LoginVariables {
  identifier: string;
  password: string;
}
