import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeyIcon } from "@heroicons/react/24/solid";

// import {
//   NotifyAction,
//   NotifyType,
//   NotifyModal,
// } from "../../../contexts/@types/notify.d";
// import { useNotifyContext } from "../../../contexts/NotifyContext";
import { Field, Response } from "../../../components/form";
import logo from "../../../assets/logo.svg";
import { useAuth } from "..";
import { AlertType, useAlert } from "../../../hooks/useAlert";
import { Head } from "../../../components/core";

const { REACT_APP_NAME } = process.env;

export function Forgot() {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const { currentIdentifier } = useAuth();
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    status: false,
    title: "",
    message: "",
  });

  const ResponseAlert = useAlert({
    open: alert.status,
    title: alert.title,
    message: alert.message,
    type: AlertType.SUCCESS,
    delay: 10000,
    onDismiss: () => {
      setAlert({
        ...alert,
        status: false,
      });
      navigate("/auth/login");
    },
  });

  const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!) {
      forgotPassword(input: { params: { email: $email } }) {
        message
        status
      }
    }
  `;
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const ForgotSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: currentIdentifier ?? "",
    },
    enableReinitialize: true,
    validationSchema: ForgotSchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      forgotPassword({
        variables: {
          email: values.email,
        },
      })
        .then(({ data }) => {
          if (data?.forgotPassword) {
            const { message } = data.forgotPassword;
            setAlert({
              status: true,
              title: "Reset request sent",
              message: message,
            });
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <Head title="Forgot Password" />
      <header>
        <img className="mx-auto h-9 sm:h-10 md:h-11 xl:h-12 w-auto" src={logo} alt={REACT_APP_NAME} />
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          Forgot your password
        </h2>
      </header>

      <div className="mt-5">
        <p className="text-sm">
          Enter your email address and we'll send you a link to reset your password.
        </p>

        <form className="mt-5 mb-5 space-y-6" onSubmit={formik.handleSubmit}>
          <div className="-space-y-px">
            <div>
              <Field
                title={t("Email address")}
                type="email"
                name="email"
                autoComplete="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={touched.email}
                errors={errors.email}
                placeholder="Please enter your email address"
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="text-sm">
              <Link
                to="/auth/login"
                className="font-medium text-primary-700 hover:text-primary-700"
              >
                Back to login page
              </Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              disabled={isSubmitting}
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <KeyIcon
                  className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
                  aria-hidden="true"
                />
              </span>
              Reset Password
            </button>
          </div>
        </form>
        <ResponseAlert />

        <Response
          response={response}
          onDismiss={() => {
            setResponse(null);
          }}
        />
      </div>

      {/* <form className="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="-space-y-px rounded-md shadow-sm">
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
              placeholder="Email address"
            />
          </div>
        </div>

        <div className="flex items-center justify-end">
          <div className="text-sm">
            <Link
              to="/auth/login"
              className="font-medium text-primary-700 hover:text-primary-700"
            >
              Back to login page
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <KeyIcon
                className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
                aria-hidden="true"
              />
            </span>
            Reset Password
          </button>
        </div>
      </form> */}
    </>
  );
}
