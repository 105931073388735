import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../animations";

type ButtonProps = Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "disabled" | "className" | "children"
> &
  Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, "rel" | "href"> & {
    variant?:
      | "primary"
      | "secondary"
      | "tertiary"
      | "danger"
      | "warning"
      | "info"
      | "text"
      | "icon";
    type?: "button" | "submit" | "reset" | "link";
    loading?: boolean;
  };

export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const {
    className,
    children,
    type = "button",
    variant,
    loading,
    rel = "",
    href = "",
    disabled = false,
    onClick,
    ...rest
  } = props;

  const render = loading ? (
    <Spinner className="mx-5 mt-0.5 mb-0.5" />
  ) : (
    children
  );
  let classes =
    "inline-flex items-center whitespace-nowrap transition focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40";
  classes += className ? " " + className : " justify-center";

  const buttonClasses =
    " rounded-lg border text-base font-normal px-4 md:px-6 py-2.5";

  switch (variant) {
    case "secondary":
      classes +=
        buttonClasses + " border-gray-300 bg-white text-black hover:bg-gray-50";
      break;
    case "tertiary":
      classes +=
        buttonClasses +
        " border-gray-900 bg-gray-900 text-white hover:bg-gray-700";
      break;
    case "text":
      classes += " rounded-lg border-0 bg-transparent px-0 py-2";
      break;
    case "icon":
      break;
    case "danger":
      classes +=
        buttonClasses +
        " border-transparent bg-red-600 text-white hover:bg-red-700";
      break;
    case "warning":
      classes +=
        buttonClasses +
        " border-transparent bg-yellow-400 text-black hover:bg-yellow-500";
      break;
    case "info":
      classes +=
        buttonClasses +
        " border-transparent bg-blue-400 text-white hover:bg-blue-500";
      break;
    default:
      classes +=
        buttonClasses +
        " border-transparent bg-primary-600 text-white hover:bg-primary-700";
      break;
  }

  switch (type) {
    case "link":
      return (
        <Link to={href} rel={rel} className={classes} {...rest}>
          {render}
        </Link>
      );
    default:
      return (
        <button
          ref={ref}
          type={type}
          onClick={onClick}
          disabled={disabled}
          className={classes}
          {...rest}
        >
          {render}
        </button>
      );
  }
});
