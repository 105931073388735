import { createContext, useContext, useReducer } from "react";
import {
  UserAccountModelKey,
  UserAccountModelStatus,
  UserModel,
} from "../modules/auth/core";
import { GenReducer } from "./GenReducer";
import {
  GenContextAction,
  GenContextProps,
  InitGenContextPropsState,
  IPage,
} from "./GenType";

const initGenContextPropsState: InitGenContextPropsState = {
  title: "Alpha Fresh",
  description: "",
  sidebarOpen: false,
  account: {
    steps: {
      [UserAccountModelKey.VENUE_TYPE]: UserAccountModelStatus.PENDING,
      [UserAccountModelKey.AVERAGE_SPEND]: UserAccountModelStatus.PENDING,
      [UserAccountModelKey.ABN_NUMBER]: UserAccountModelStatus.PENDING,
      [UserAccountModelKey.CONTACT_DETAILS]: UserAccountModelStatus.PENDING,
    },
    activeStep: undefined,
    completed: false,
    percentage: 0,
    open: false,
  },
  saveAccount: () => {},
  toggleAccount: () => {},
  isVerified: () => false,
  isAbnCompleted: () => false,
  updatePage: () => {},
  toggleMenu: () => {},
};

const GenContext = createContext<GenContextProps>(initGenContextPropsState);

const useAccount = () => {
  const { account, saveAccount, toggleAccount, isVerified, isAbnCompleted } =
    useContext(GenContext);
  return {
    ...account,
    saveAccount,
    toggleAccount,
    isVerified,
    isAbnCompleted,
  };
};

export default function GenContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(GenReducer, initGenContextPropsState);

  const updatePage = (page: IPage) => {
    dispatch({ type: GenContextAction.UPDATE_PAGE, payload: page });
  };
  const toggleMenu = (sidebarOpen: boolean | undefined) => {
    dispatch({ type: GenContextAction.TOGGLE_MENU, payload: sidebarOpen });
  };

  const saveAccount = (user: UserModel | undefined) => {
    dispatch({ type: GenContextAction.SAVE_ACCOUNT, payload: user });
  };

  const toggleAccount = (open: boolean | undefined) => {
    dispatch({ type: GenContextAction.TOGGLE_ACCOUNT, payload: open });
  };

  const isAbnCompleted = () =>
    state.account.completed ||
    state.account.steps.abn_no === UserAccountModelStatus.COMPLETED;

  const isVerified = () => {
    return state.account.steps.abn_no === UserAccountModelStatus.COMPLETED;
  };

  const contextValues = {
    ...state,
    updatePage,
    toggleMenu,
    saveAccount,
    toggleAccount,
    isVerified,
    isAbnCompleted,
  };
  return (
    <GenContext.Provider value={contextValues}>{children}</GenContext.Provider>
  );
}

export { GenContext, useAccount };
