import { getLatLng } from "use-places-autocomplete";
import { Buffer } from "buffer";
import { DateTime } from "luxon";
import i18n from "../i18n";
// dynamically load JavaScript files in our html with callback when finished
export const loadScript = (
  url: string,
  position: HTMLElement | null,
  id: string,
  callback: () => void
) => {
  if (!position) return;

  let script = document.createElement("script"); // create script tag
  script.setAttribute("async", ""); // set async attribute
  script.setAttribute("id", id);
  script.setAttribute("type", "text/javascript");

  script.onload = () => {
    callback();
  };
  script.src = url; // load by url
  position.appendChild(script);
};

export const toggleBoolean = (prev: boolean) => !prev;

const isValidArrayIndex = (arr: any[], idx: number) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr: any[], idx: number, value: any) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr: any[], idx: number, newValue: any) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(
  arr: any[],
  idx: number,
  updater: (arg0: any) => any
) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr: any[], idx: number) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export function latency(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toCapitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toSlug(string: string) {
  return string
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export function toCamelCase(string: string) {
  return string
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
}

export function toVariableName(string: string) {
  let result = string;
  result = string
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    })
    .replace(/[^a-zA-Z0-9]/g, "");
  return `$${result}`;
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function toNestedTable<
  T extends {
    id: string;
    parent: {
      id: string;
    } | null;
    subRows?: T[];
    depth?: number;
  }
>(data: T[], pid: string | null = null, depth = 0): T[] {
  return data.reduce((r, e) => {
    if ((pid === null && e.parent === null) || pid === e.parent?.id) {
      const object = { ...e, depth };
      const subRows = toNestedTable(data, e.id, depth + 1);
      if (subRows.length) {
        object.subRows = subRows.map((v) => ({ ...v, depth: v.depth }));
      }
      r.push(object);
    }
    return r;
  }, [] as T[]);
}

export function toNestedOptions<
  T extends {
    id: string;
    parent: {
      id: string;
    } | null;
    subRows?: T[];
    depth?: number;
  }
>(
  data: T[],
  cid: string | null = null,
  pid: string | null = null,
  depth = 0
): T[] {
  return data.reduce((r, e) => {
    if (
      ((pid === null && e.parent === null) || pid === e.parent?.id) &&
      e.id !== cid
    ) {
      const object = { ...e, depth };
      r.push(object);
      const subRows = toNestedOptions(data, cid, e.id, depth + 1);
      if (subRows.length) {
        r.push(...subRows.map((v) => ({ ...v, depth: v.depth })));
      }
    }
    return r;
  }, [] as T[]);
}

export function findById<
  T extends {
    id: string;
    subRows?: T[];
  }
>(arr: T[], id: string): T | undefined {
  return arr.find((a) => {
    if (a.subRows && a.subRows.length > 0) {
      return a.id === id ? true : findById(a.subRows, id);
    }
    return a.id === id;
  });
}

export function orderOptions<
  T extends {
    isFixed?: boolean;
  }
>(values: readonly T[]) {
  return values
    .filter((v) => v.isFixed)
    .concat(values.filter((v) => !v.isFixed));
}

export function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

interface Address {
  // [key: string]: string;
  street: string;
  state: string;
  suburb: string;
  postcode: string;
  country: string;
  latitude: number;
  longitude: number;
}

export function getAddressFromGeocoder(
  results: google.maps.GeocoderResult[]
): Address {
  const response: Address = {
    street: "",
    state: "",
    suburb: "",
    postcode: "",
    country: "",
    latitude: 0,
    longitude: 0,
  };
  let street = "";
  let postcode = "";

  if (results.length > 0) {
    const { address_components } = results[0];
    const { lat, lng } = getLatLng(results[0]);
    response["latitude"] = lat;
    response["longitude"] = lng;

    for (const component of address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          street = `${component.long_name} ${street}`;
          break;

        case "route":
          street += component.short_name;
          break;

        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          break;

        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;

        case "locality":
          response["suburb"] = component.long_name;
          break;

        case "administrative_area_level_1":
          response["state"] = component.short_name;
          break;

        case "country":
          response["country"] = component.long_name;
          break;
      }
    }
  }

  response["street"] = street;
  response["postcode"] = postcode;
  return response;
}

/**
 * Use this to make a Base64 encoded string
 * @param {String} str the string to encode
 * @returns {String} the encoded String
 */
export function encodeBase64(str: string): string {
  return Buffer.from(str).toString("base64");
}

/**
 * Use this to decode a Base64 encoded string
 * @param {String} str the encoded string
 * @returns {String} the decoded String
 * @throws {Error} if the string is not a valid Base64 encoded string
 */
export function decodeBase64(str: string): string {
  return Buffer.from(str, "base64").toString("ascii");
}

/**
 * use this to make a Base64 encoded string URL friendly,
 * i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
 * characters are removed
 *
 * @param {String} str the encoded string
 * @returns {String} the URL friendly encoded String
 */
export function Base64EncodeUrl(str: string): string {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

/**
 * Use this to recreate a Base64 encoded string that was made URL friendly
 * using Base64EncodeurlFriendly.
 * '-' and '_' are replaced with '+' and '/' and also it is padded with '+'
 *
 * @param {String} str the encoded string
 * @returns {String} the URL friendly encoded String
 */
export function Base64DecodeUrl(str: string): string {
  if (str.length % 4 !== 0) str += "===".slice(0, 4 - (str.length % 4));
  return str.replace(/-/g, "+").replace(/_/g, "/");
}

/**
 * Use this to create a Base64 encoded string and make it ID friendly,
 * i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
 *
 * @param {String} str the encoded string
 * @returns {String} the encoded String
 */
export function toBase64ID(str: string): string {
  return Base64EncodeUrl(encodeBase64(str));
}

/**
 * Use this to recreate a Base64 encoded string that was made ID friendly
 * using Base64EncodeurlFriendly.
 * '-' and '_' are replaced with '+' and '/' and also it is padded with '+'
 * and then decoded
 * @param {String} str the encoded string
 * @returns {String} the encoded String
 */
export function fromBase64ID(str: string): string {
  return decodeBase64(Base64DecodeUrl(str));
}
/**
 * Use this to validate an ABN number
 * @param {String} value the ABN number to validate
 * @returns {Boolean} true if the ABN is valid
 * @throws {Error} if the ABN is not a valid ABN
 * @example
 * ABNValidation("12345678901") // true
 */
export function ABNValidation(value: string | undefined): boolean {
  if (!value) return false;
  value = value.replace(/[^0-9]/g, "");
  let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  if (value.length === 11) {
    let sum = 0;
    weights.forEach(function (weight, position) {
      if (!value) return 0;
      let digit = Number(value[position]) - (position ? 0 : 1);
      sum += weight * digit;
    });
    return sum % 89 === 0;
  }
  return false;
}

/**
 * Use this to truncate a string to a given length
 * @param {String} str the string to truncate
 * @param {Number} length the length to truncate to
 * @returns {String} the truncated string
 * @example toTruncate("Hello World", 5) // "Hello..."
 * @example toTruncate("Hello World", 20) // "Hello World"
 * @example toTruncate("Hello World", 0) // "..."
 * @example toTruncate("Hello World", -1) // "..."
 * @example toTruncate("Hello World", 1) // "..."
 * @example toTruncate("Hello World", 2) // "H..."
 * @example toTruncate("Hello World", 3) // "He..."
 */
export function toTruncate(str: string, length: number): string {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

/**
 * @param {string} string - ISO string
 * @returns {string} - formatted date string 'Oct 14, 1983'
 * @example formatDate('2021-10-14T00:00:00.000Z')
 * @example formatDate('2021-10-14T00:00:00.000Z', 'en')
 *
 * @see https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 * @see https://moment.github.io/luxon/docs/manual/i18n.html#table-of-tokens
 */

export function formatDate(string: string | null, locale?: string): string {
  if (!string) return "";
  return DateTime.fromISO(string, {
    locale: locale ?? i18n.language,
  }).toLocaleString(DateTime.DATE_MED);
}
