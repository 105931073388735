import { Navigate, useNavigate } from "react-router-dom";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";

import { Button, Field, FieldDatepicker } from "../../components/form";
import { Spinner } from "../../animations";
import { useAuth } from "../auth";
import { useNotifyContext, NotifyType } from "../../contexts/NotifyContext";
import { CartInit, useCart } from "../cart/core/CartProvider";
import { CartItem } from "../../graphql/cart";
import { Head } from "../../components/core";

import logo from "../../assets/logo.svg";
import { Breadcrumb } from "../../components/interface";
import { renderAddress } from "../../components/order";
import { Product } from "./components";
const { REACT_APP_NAME } = process.env;

const CREATE_ORDER = gql`
  mutation OrderCreate(
    $customerTradingAddressId: Int
    $subTotal: Float
    $total: Float
    $deliveryDate: ISO8601DateTime
    $notes: String
    $products: [OrderproductInput!]
  ) {
    orderCreate(
      input: {
        params: {
          customerTradingAddressId: $customerTradingAddressId
          subTotal: $subTotal
          total: $total
          deliveryDate: $deliveryDate
          notes: $notes
          products: $products
        }
      }
    ) {
      order {
        id
        orderNumber
        items {
          id
          price
          quantity
          orderComments
          variant {
            product {
              name
              handle
              description
              featureImageUrl
            }
            stockCode
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
          }
        }
        customer {
          customerName
          email
        }
        notes
        shippingAddress {
          address
          suburb
          state
          postcode
        }
        status
        subTotal
        total
        createdAt
      }
    }
  }
`;

const CheckoutSchema = Yup.object().shape({
  notes: Yup.string(),
  customerTradingAddressId: Yup.number().required("Required"),
});

export const CheckoutPage = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const { cartItems, cartTotal, clearCart } = useCart();
  const navigate = useNavigate();
  const { addNotify } = useNotifyContext();

  const [createOrder] = useMutation(CREATE_ORDER);

  const formik = useFormik({
    initialValues: {
      customerTradingAddressId: currentUser?.primaryAddress?.id
        ? parseInt(currentUser?.primaryAddress?.id)
        : null,
      deliveryDate: null,
      notes: currentUser?.deliveryInstruction || "",
      products: cartItems.map((item: CartItem) => ({
        productId: parseInt(item.id),
        price: item.customerPrice,
        quantity: item.quantity,
        orderComments: "",
      })),
    },
    enableReinitialize: true,
    validationSchema: CheckoutSchema,
    onSubmit: (
      values: {
        customerTradingAddressId: number | null;
        deliveryDate: string | null;
        notes: string;
        products: {
          productId: number;
          price: number;
          quantity: number;
          orderComments: string;
        }[];
      },
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      createOrder({
        variables: {
          customerTradingAddressId: values.customerTradingAddressId,
          subTotal: cartTotal,
          total: cartTotal,
          deliveryDate: values.deliveryDate,
          notes: values.notes,
          products: values.products,
        },
      })
        .then(({ data }) => {
          if (data?.orderCreate) {
            const order = data.orderCreate.order;
            clearCart()
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
                return navigate(`/checkout/completed/${order.orderNumber}`, {
                  replace: true,
                });
              });
          } else {
            actions.setSubmitting(false);
            addNotify({
              type: NotifyType.ERROR,
              title: "Order creation failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          addNotify({
            type: NotifyType.ERROR,
            title: "Order creation failed",
            message: error.message,
          });
        });
    },
  });

  if (!currentUser) return <Navigate to="/auth/login" />;
  if (!cartItems.length) return <Navigate to="/cart" />;
  if (!currentUser.primaryAddress) return <Navigate to="/checkout/shipping" />;

  return (
    <>
      <Head title={CheckoutResource.name} />
      <h1 className="sr-only">{CheckoutResource.name}</h1>
      <div className="mx-auto max-w-4xl py-16 px-4 sm:py-24 sm:px-6 lg:px-0">
        <header className="mb-4 md:mb-8">
          <img
            className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
            src={logo}
            alt={REACT_APP_NAME}
          />
        </header>
        <Breadcrumb
          data={[
            {
              name: "Back to Home",
              href: "/",
            },
            { name: "Cart", href: "/cart" },
            {
              name: "Shipping",
              href: "/checkout/shipping",
            },
            {
              name: "Checkout",
              href: "/checkout/confirmation",
              active: true,
            },
            {
              name: "Completed",
              href: null,
            },
          ]}
          className="mb-12 md:mb-16"
        />
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="mx-auto w-full max-w-lg lg:order-last">
            <h2 className="text-lg font-medium text-gray-900">
              <span className="mr-3">Cart Summary</span>
              <Button
                type="link"
                href="/cart"
                variant="text"
                className="text-xs uppercase text-primary-900 hover:text-primary-800"
              >
                Edit Cart
              </Button>
            </h2>

            <div className="mt-6 flow-root">
              <CartInit>
                <div className="-my-6 divide-y divide-gray-200">
                  {cartItems.map((cartItem: CartItem, index) => (
                    <Product
                      key={cartItem.id}
                      cartItem={cartItem}
                      orderComments={getIn(
                        formik.values.products[index],
                        "orderComments"
                      )}
                      updateComments={(value: string) => {
                        formik.setFieldValue(
                          `products[${index}].orderComments`,
                          value
                        );
                      }}
                    />
                  ))}
                </div>
              </CartInit>
            </div>

            <dl className="mt-10 space-y-2 text-sm font-light text-gray-700">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-lg font-medium text-primary-700">
                  $ {cartTotal}
                </dd>
              </div>
              <div className="flex items-center justify-between">
                <dt>Taxes</dt>
                <dd className="text-lg font-medium text-primary-800">-</dd>
              </div>
              <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt className="text-base">Total</dt>
                <dd className="text-xl font-medium text-primary-800">
                  $ {cartTotal}
                </dd>
              </div>
            </dl>
          </div>
          <div className="mx-auto w-full max-w-lg">
            <form onSubmit={formik.handleSubmit}>
              <h2 className="text-lg font-medium text-gray-900">
                Delivery Information
              </h2>

              <div className="mt-5">
                <h3 className="text-base font-medium text-gray-700">
                  Contact Email
                </h3>
                <p>{currentUser.email}</p>
              </div>

              <div className="mt-5">
                <h3 className="text-base font-medium text-gray-700">
                  <span className="mr-3">{t("text_delivery_address")}</span>
                  <Button
                    type="link"
                    href="/checkout/shipping"
                    variant="text"
                    className="text-xs uppercase text-primary-900 hover:text-primary-800"
                  >
                    Edit Address
                  </Button>
                </h3>
                <p>{renderAddress(currentUser.primaryAddress)}</p>
              </div>

              <div className="mt-5">
                <FieldDatepicker
                  title={t("text_select_delivery_date")}
                  name="deliveryDate"
                  onChange={(value) => {
                    if (!Array.isArray(value)) {
                      formik.setFieldValue(
                        "deliveryDate",
                        value ? new Date(value).toISOString() : null
                      );
                    }
                  }}
                  minDate={new Date()}
                  selected={
                    formik.values.deliveryDate
                      ? new Date(formik.values.deliveryDate)
                      : null
                  }
                  touched={formik.touched.deliveryDate}
                  errors={formik.errors.deliveryDate}
                />
              </div>

              <div className="mt-5">
                <Field
                  title={t("text_delivery_instruction")}
                  type="textarea"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  touched={formik.touched.notes}
                  errors={formik.errors.notes}
                />
              </div>

              <div className="mt-6">
                <Button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="w-full justify-center"
                >
                  {formik.isSubmitting ? (
                    <>
                      <Spinner />
                      {t("text_processing")}
                    </>
                  ) : (
                    t("text_confirm_order")
                  )}
                </Button>

                <Button
                  variant="text"
                  onClick={() => {
                    formik.resetForm();
                    navigate("/", { replace: true });
                  }}
                  className="mt-2 w-full justify-center text-primary-700"
                >
                  Continue Shopping
                  <ArrowRightIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export const CheckoutResource: ResourceProps = {
  name: "Checkout",
  path: "/checkout/confirmation",
};
