import { SetStateAction, useCallback, useMemo, useState } from "react";
import { Variant } from "../../graphql/shop";
import { useCart } from "../../modules/cart/core/CartProvider";
import { classNames } from "../../utils";

interface SwatchVariant extends Variant {
  quantity: number;
}
type SwatchVariantCombo = {
  id: string;
  selected: boolean;
  [key: string]: string | boolean;
};
type SwatchProps = {
  [key: string]: string;
};
export function Swatch({
  variants,
  currentVariant,
  setCurrentVariant,
  className,
  itemClass,
  labelClass,
  valueClass,
}: {
  variants: Variant[];
  currentVariant: Variant;
  setCurrentVariant: (value: SetStateAction<SwatchVariant>) => void;
  className?: string;
  valueClass?: string;
  labelClass?: string;
  itemClass?: string;
}) {
  const [activeVars, setActiveVars] = useState<SwatchProps>({});
  const [isMixedCombo, setIsMixedCombo] = useState(false);
  const { findCart } = useCart();

  const variantCombo: SwatchVariantCombo[] = useMemo(() => {
    if (!variants.length) return [];
    const varCombo: SwatchVariantCombo[] = [];
    variants.forEach((variant) => {
      const { variantTitle } = variant;
      const combo: SwatchVariantCombo = {
        id: variant.id,
        selected: variant.id === currentVariant.id,
      };
      variantTitle.forEach((title) => {
        combo[title.option.name] = title.name;
      });
      varCombo.push(combo);
    });

    const initialVars = varCombo[0];
    Object.keys(initialVars).forEach((key) => {
      if (key !== "id" && key !== "selected") {
        setActiveVars((prev) => ({ ...prev, [key]: String(initialVars[key]) }));
      }
    });
    return varCombo;
  }, [currentVariant.id, variants]);

  const options: {
    label: string;
    value: string[];
  }[] = useMemo(() => {
    const opt: {
      label: string;
      value: string[];
    }[] = [];

    variantCombo.forEach((combo) => {
      Object.keys(combo).forEach((key) => {
        if (key !== "id" && key !== "selected") {
          const option = opt.find((o) => o.label === key);
          if (option) {
            if (!option.value.includes(combo[key] as string)) {
              option.value.push(combo[key] as string);
            }
          } else {
            opt.push({
              label: key,
              value: [combo[key] as string],
            });
          }
        }
      });
    });

    // check all variant combo has the same options
    const minCombo = Math.min.apply(
      Math,
      variantCombo.flatMap((c) => Object.keys(c).length)
    );
    const maxCombo = Math.max.apply(
      Math,
      variantCombo.flatMap((c) => Object.keys(c).length)
    );

    setIsMixedCombo(minCombo !== maxCombo);

    return opt;
  }, [variantCombo]);

  const handleOptionChange = useCallback(
    (name: string, value: string) => {
      const newActiveVars = { ...activeVars, [name]: value };
      const combo = variantCombo.find((c) => {
        let match = true;
        Object.keys(newActiveVars).forEach((key) => {
          if (key !== "id" && key !== "selected") {
            if (
              (newActiveVars[key] !== c[key] && !isMixedCombo) ||
              (newActiveVars[key] !== c[key] &&
                isMixedCombo &&
                newActiveVars[key] !== "")
            ) {
              match = false;
            }
          }
        });
        return match;
      });

      if (combo) {
        const variant = variants.find((v) => v.id === combo.id);
        if (variant) {
          const cartVariant = findCart(combo.id);
          const autoCombo = { ...newActiveVars };
          Object.keys(combo).forEach((key) => {
            if (key !== "id" && key !== "selected") {
              autoCombo[key] = combo[key] as string;
            }
          });
          setTimeout(() => {
            setActiveVars(autoCombo);
          }, 10);
          setCurrentVariant({
            ...variant,
            quantity: cartVariant ? cartVariant.quantity : 0,
          });
        }
      }
    },
    [
      activeVars,
      findCart,
      isMixedCombo,
      setCurrentVariant,
      variantCombo,
      variants,
    ]
  );

  if (variants.length <= 1) return null;

  return (
    <div className={classNames(className ?? "space-y-4")}>
      {options.map((option, index) => {
        return (
          <div
            key={`vs-${option.label}`}
            className={classNames(itemClass ?? "")}
          >
            <label
              htmlFor={`vs-${option.label}`}
              className={classNames(labelClass ?? "label mb-1 capitalize")}
            >
              {option.label}
            </label>
            <select
              id={`vs-${option.label}`}
              name={`${option.label}`}
              className={classNames(
                valueClass ??
                  "block w-full rounded-lg border-gray-200 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
              )}
              onChange={(e) => handleOptionChange(option.label, e.target.value)}
              value={activeVars[option.label] ?? ""}
            >
              {index !== 0 && isMixedCombo && <option value=""></option>}
              {option.value.map((value) => (
                <option key={`vs-${option.label}-${value}`} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      })}
    </div>
  );
}
