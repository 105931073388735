import { useEffect, useState } from "react";
import { classNames } from "../utils";

type IProgressBarProps = {
  progress?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: "round" | "inherit" | "butt" | "square";
  spinnerMode?: boolean;
  spinnerSpeed?: number;
  className?: string;
};

const VIEWSIZE = 150;
export function ProgressRadial(props: IProgressBarProps) {
  let {
    progress: initialProgress = 0,
    trackWidth = 10,
    trackColor = `#e1f6e2`,
    indicatorWidth = 11,
    indicatorColor = `#4ca84e`,
    indicatorCap = `square`,
    spinnerMode = false,
    spinnerSpeed = 1,
    className,
  } = props;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let loadingTimeout = setTimeout(() => {
      setProgress((prev) => (prev < initialProgress ? prev + 1 : prev));
    }, initialProgress / 100 * 5);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [initialProgress, progress]);

  const center = VIEWSIZE / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox={`0 0 ${VIEWSIZE} ${VIEWSIZE}`}
      enableBackground={`new 0 0 ${VIEWSIZE} ${VIEWSIZE}`}
      className={classNames("-rotate-90 w-full", className ? className : "")}
    >
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke={trackColor}
        strokeWidth={trackWidth}
      />
      <circle
        className={`origin-center ${spinnerMode ? "animate-spin-slow" : ""}`}
        style={{ animationDuration: `${spinnerSpeed * 1000}` }}
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke={indicatorColor}
        strokeWidth={indicatorWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        strokeLinecap={indicatorCap}
      />
    </svg>
  );
}
