import { DateTime, Settings } from "luxon";
import { Fragment } from "react";
import Countdown from "react-countdown";
import { Variant } from "../../graphql/shop";
import { classNames } from "../../utils";

Settings.defaultLocale = "en-AU";

function isExpired(date: string) {
  if (!date) return false;
  const now = DateTime.now().toISO();
  return date < now;
}

// function isExpireToday(date: string) {
//   if (!date) return false;
//   const now = DateTime.now().toISO();
//   const endDate = DateTime.fromISO(date).toISO();
//   return now.slice(0, 10) === endDate.slice(0, 10);
// }

export function Timer({
  price,
  className,
}: {
  price: Variant["customerSpecialPrice"];
  className?: string;
}) {
  if (!price || isExpired(price.endDate)) return null;

  const Completionist = () => <span>Offer Ended!</span>;
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <Fragment>
          <div className="inline-flex items-center">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 14 14"
              enableBackground="new 0 0 14 14"
              className="mr-2 inline-block h-4 w-4"
              aria-hidden="true"
            >
              <path
                fill="currentColor"
                d="M7,0C3.1,0,0,3.1,0,7c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7C14,3.1,10.9,0,7,0z M7,0.9c3.4,0,6.1,2.7,6.1,6.1s-2.7,6.1-6.1,6.1
c-3.4,0-6.1-2.7-6.1-6.1C0.9,3.6,3.6,0.9,7,0.9z M7,2.6C6.7,2.6,6.5,2.8,6.5,3v4c0,0.2,0.1,0.3,0.2,0.4c1,0.6,2,1.2,3,1.8
C10,9.3,10.3,9.2,10.4,9c0.1-0.2,0.1-0.5-0.2-0.6L7.5,6.7V3C7.5,2.8,7.3,2.6,7,2.6L7,2.6z"
              />
            </svg>
            <span className="block text-sm">Hurry up! Sales Ends in</span>
          </div>
          <div className="pl-6">
            {days > 0 ? `${days} days, ` : null}
            {`${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
              "0" + seconds
            ).slice(-2)}`}
          </div>
        </Fragment>
      );
    }
  };

  return (
    <div className={classNames(className ?? "mt-2 text-red-500")}>
      <Countdown
        date={price.endDate}
        now={() => {
          const now = DateTime.local();
          return now.toMillis();
        }}
        renderer={renderer}
      />
    </div>
  );
}
