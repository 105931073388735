import { useContext, useEffect } from "react";
import { GenContext } from "../../contexts/GenContext";
import { useTitle } from "../../hooks/useTitle";

export function Head({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  const { updatePage } = useContext(GenContext);
  useTitle(title || "Alpha Fresh");
  useEffect(() => {
    updatePage({ title, description });
  }, []);
  return <></>;
}
