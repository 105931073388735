import { DateTime, Settings } from "luxon";
import { type Order } from "../../graphql/order";
import { useTranslation } from "react-i18next";
import { renderAddress, renderStatus } from ".";
import { Button } from "../form";

Settings.defaultLocale = "en-AU";

export function Row({
  order,
  index,
  setOrder,
}: {
  order: Order;
  index: number;
  setOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
}) {
  const { i18n } = useTranslation();

  return (
    <tr className={index % 2 === 0 ? undefined : "bg-gray-50"}>
      <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Button
          variant="text"
          onClick={() => {
            setOrder(order);
          }}
          className="text-primary-900 hover:text-primary-800"
        >
          {order.orderNumber}
        </Button>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {DateTime.fromISO(order.createdAt, {
          locale: i18n.language,
        }).toLocaleString(DateTime.DATE_MED)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        ${order.total}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {renderStatus(order.status)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {renderAddress(order.shippingAddress)}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Button
          variant="secondary"
          onClick={() => {
            setOrder(order);
          }}
        >
          View
          <span className="sr-only">, order number {order.orderNumber}</span>
        </Button>
      </td>
    </tr>
  );
}
