import { ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../utils";

type BreadcumbProps = {
  data: {
    name: string;
    href: string | null;
    active?: boolean;
  }[];
  className?: string;
};
export function Breadcrumb(props: BreadcumbProps) {
  const { data, className } = props;
  return (
    <div
      className={classNames(
        "flex flex-wrap items-center justify-center space-x-2",
        className ?? ""
      )}
    >
      {data.map((item, index) => {
        const isLast = index === data.length - 1;
        const activeClass = classNames(
          "block text-xs font-normal uppercase tracking-wider",
          item.active
            ? "text-primary hover:text-primary-700 hover:underline"
            : "text-gray-500 hover:text-gray-700"
        );

        if (!item.href) {
          return (
            <React.Fragment key={item.href}>
              <span className={activeClass}>{item.name}</span>
              {!isLast && (
                <ChevronRightIcon className="h-3 w-3 text-gray-500" />
              )}
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={item.href}>
            <Link key={item.href} to={item.href} className={activeClass}>
              {item.name}
            </Link>
            {!isLast && <ChevronRightIcon className="h-3 w-3 text-gray-500" />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
