import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { useAuth } from "../../auth";
import { Button } from "../../../components/form/";

import { useTranslation } from "react-i18next";
import { Spinner } from "../../../animations";
import FormVenue from "./components/FormVenue";
import { useAccount } from "../../../contexts/GenContext";
import { Head } from "../../../components/core";
import { PageTitle } from "../../../components/page";
import { UserModel } from "../../auth/core";

const UPDATE_CUSTOMER_VENUE = gql`
  mutation CustomerVenueUpdate(
    $phoneNumber: String
    $mobileNumber: String
    $postalAddress: String
    $suburb: String
    $state: String
    $postcode: String
    $customerVenueTypeId: Int
    $customerVenueFoodSpendId: Int
  ) {
    customerVenueUpdate(
      input: {
        params: {
          phoneNumber: $phoneNumber
          mobileNumber: $mobileNumber
          postalAddress: $postalAddress
          suburb: $suburb
          state: $state
          postcode: $postcode
          customerVenueTypeId: $customerVenueTypeId
          customerVenueFoodSpendId: $customerVenueFoodSpendId
        }
      }
    ) {
      customer {
        details {
          emailImportantUpdates
          emailSpecials
          mobileNumber
          phoneNumber
          postalAddress
          postcode
          state
          suburb
          venueFoodSpend {
            id
            spendRange
          }
          venueType {
            id
            name
          }
        }
      }
    }
  }
`;

type IAccountVenue = {
  phoneNumber: string;
  mobileNumber: string;
  postalAddress: string;
  suburb: string;
  state: string;
  postcode: string;
  customerVenueTypeId: number | null;
  customerVenueFoodSpendId: number | null;
};

export default function VenuePage() {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const { currentUser, setCurrentUser } = useAuth();
  const { saveAccount } = useAccount();
  const { addNotify } = useNotifyContext();
  const [updateCustomerVenue] = useMutation(UPDATE_CUSTOMER_VENUE);

  const handleSubmitVenue = (
    values: IAccountVenue,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    console.log(values);

    updateCustomerVenue({
      variables: {
        phoneNumber: values.phoneNumber,
        mobileNumber: values.mobileNumber,
        postalAddress: values.postalAddress,
        suburb: values.suburb,
        state: values.state,
        postcode: values.postcode,
        customerVenueTypeId: values.customerVenueTypeId,
        customerVenueFoodSpendId: values.customerVenueFoodSpendId,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerVenueUpdate) {
          const { customer } = data.customerVenueUpdate;
          if (!currentUser) return;
          const updatedUser: UserModel = {
            ...currentUser,
            details: customer?.details,
          };
          setCurrentUser(updatedUser);
          saveAccount(updatedUser);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Venue updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Venue update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Venue update failed",
          message: error.message,
        });
      });
  };

  const handleCancelVenue = () => {
    setEdit(false);
  };

  const renderAddress = useCallback(() => {
    let address = "";
    if (!currentUser) return address;
    if (!currentUser.details) return address;

    const { postalAddress, suburb, state, postcode } = currentUser.details;

    if (postalAddress) address += postalAddress;
    if (suburb) address += `, ${suburb}`;
    if (state) address += `, ${state}`;
    if (postcode) address += `, ${postcode}`;

    return address;
  }, [currentUser]);

  if (!currentUser) return <Spinner />;

  return (
    <>
      <Head title={VenueResource.name} />
      <div className="w-full w-prev-container pb-10">
        <header className="mb-4 flex items-end justify-between md:mb-8">
          <div>
            <PageTitle className="text-primary-900">
              {VenueResource.name}
            </PageTitle>
            <p className="text-sm text-gray-500">
              View and update your venue details
            </p>
          </div>
          {!edit && <Button onClick={() => setEdit(true)}>Edit</Button>}
        </header>

        {edit ? (
          <FormVenue
            initialValues={{
              phoneNumber: currentUser.details.phoneNumber || "",
              mobileNumber: currentUser.details.mobileNumber || "",
              postalAddress: currentUser.details.postalAddress || "",
              suburb: currentUser.details.suburb || "",
              state: currentUser.details.state || "",
              postcode: currentUser.details.postcode || "",
              customerVenueTypeId: currentUser.details.venueType?.id
                ? parseInt(currentUser.details.venueType.id)
                : null,
              customerVenueFoodSpendId: currentUser.details.venueFoodSpend?.id
                ? parseInt(currentUser.details.venueFoodSpend.id)
                : null,
            }}
            onSubmit={handleSubmitVenue}
            onCancel={handleCancelVenue}
          />
        ) : (
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div className="md:col-span-2">
              <span className="block text-sm font-medium text-gray-700">
                {t("text_venue_address")}
              </span>
              <p className="font-light">{renderAddress()}</p>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">
                {t("text_venue_phone")}
              </span>
              <p className="font-light">
                {currentUser.details.phoneNumber ?? "--"}
              </p>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">
                {t("text_venue_mobile")}
              </span>
              <p className="font-light">
                {currentUser.details.mobileNumber ?? "--"}
              </p>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">
                {t("text_venue_type")}
              </span>
              <p className="font-light">
                {currentUser.details.venueType?.name ?? "--"}
              </p>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">
                {t("text_venue_food_spend")}
              </span>
              <p className="font-light">
                {currentUser.details.venueFoodSpend?.spendRange ?? "--"}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export const VenueResource: ResourceProps = {
  name: "Venue Details",
  path: "venue",
};
