import React from "react";
import { classNames } from "../../utils";

type PageTitleProps = {
  className?: string;
  children: React.ReactNode;
};
export function PageTitle(props: PageTitleProps) {
  const { className, children } = props;
  return (
    <h1
      className={classNames(
        "text-lg font-medium capitalize leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight md:text-2xl lg:text-3xl",
        className ?? ""
      )}
    >
      {children}
    </h1>
  );
}
