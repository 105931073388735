import { gql } from "@apollo/client/core";

export const FETCH_PRODUCTS = gql`
  query FetchProducts(
    $first: Int!
    $cursor: String
    $searchFilter: [String!]
    $departmentId: [ID!]
    $categoryIds: [ID!]
    $sortBy: String
    $searchQuery: String!
  ) {
    fetchProducts(
      first: $first
      after: $cursor
      sortBy: $sortBy
      searchQuery: $searchQuery
      searchFilter: $searchFilter
      departmentId: $departmentId
      categoryIds: $categoryIds
    ) {
      edges {
        node {
          id
          name
          handle
          description
          hasVariants
          featureImageUrl
          galleryImages {
            imageUrl
          }
          variants {
            id
            stockCode
            comparePrice
            customerBasePrice
            customerSpecialPrice {
              endDate
              price
            }
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
          department {
            name
          }
          categories {
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export type Variant = {
  id: string;
  stockCode: string;
  minimumQuantity: number;
  inventory: number;
  customerBasePrice: number;
  comparePrice: number;
  customerSpecialPrice: {
    endDate: string;
    price: string;
  } | null;
  variantImageUrl: string;
  variantTitle: {
    id: string;
    name: string;
    option: {
      id: string;
      name: string;
    };
  }[];
};

export type Product = {
  id: string;
  name: string;
  handle: string;
  featureImageUrl: string;
  galleryImages: {
    imageUrl: string;
  }[];
  description: string;
  hasVariants: boolean;
  variants: Variant[];
  department: Pick<Department, "name">;
  categories: Pick<Category, "name">[];
};

export enum ProductAction {
  PLUS,
  MINUS,
}

export interface ProductVariantQty extends Variant {
  quantity: number;
}

export enum SortBy {
  ASC = "title-ascending",
  DSC = "title-descending",
}

export const FETCH_DEPARTMENTS = gql`
  fragment Department on Department {
    id
    name
    imageUrl
    description
    hasSubdepartment
  }
  fragment SubDepartmentRecursive on Department {
    subSubdepartments {
      ...Department
      subSubdepartments {
        ...Department
        subSubdepartments {
          ...Department
        }
      }
    }
  }
  query FetchDepartments {
    fetchDepartments {
      ...Department
      ...SubDepartmentRecursive
    }
  }
`;

export type Department = {
  id: string;
  name: string;
  imageUrl: string;
  description: string;
  hasSubdepartment: boolean;
  subSubdepartments: Department[];
};

export const FETCH_CATEGORIES = gql`
  fragment Category on Category {
    id
    name
    handle
    imageUrl
    description
    hasSubcategory
  }
  fragment SubCategoryRecursive on Category {
    subCategories {
      ...Category
      subCategories {
        ...Category
        subCategories {
          ...Category
        }
      }
    }
  }
  query FetchDepartments {
    fetchCategories {
      ...Category
      ...SubCategoryRecursive
    }
  }
`;

export type Category = {
  id: string;
  name: string;
  handle: string;
  imageUrl: string;
  description: string;
  hasSubcategory: boolean;
  subCategories: Category[];
};

export const FETCH_PRODUCT = gql`
  query FetchProduct($handle: String!) {
    fetchProduct(handle: $handle) {
      id
      name
      handle
      description
      hasVariants
      featureImageUrl
      galleryImages {
        imageUrl
      }
      variants {
        id
        stockCode
        comparePrice
        customerBasePrice
        customerSpecialPrice {
          endDate
          price
        }
        variantImageUrl
        variantTitle {
          name
          option {
            name
          }
        }
        minimumQuantity
        inventory
      }
      department {
        name
      }
      categories {
        name
      }
    }
  }
`;

export const FETCH_SPECIAL_PRODUCTS = gql`
  query FetchSpecialProducts($cursor: String) {
    fetchSpecialProducts(first: 10, after: $cursor) {
      edges {
        node {
          id
          name
          handle
          description
          hasVariants
          featureImageUrl
          galleryImages {
            imageUrl
          }
          variants {
            id
            stockCode
            comparePrice
            customerBasePrice
            customerSpecialPrice {
              endDate
              price
            }
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
          department {
            name
          }
          categories {
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
