import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Button } from "../../components/form";
import { useAccount } from "../../contexts/GenContext";
import { NotifyType, useNotifyContext } from "../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../hooks/useAlert";
import { useAuth } from "../auth";
import { Product } from "./components";
import { CartInit, useCart } from "./core/CartProvider";
import { Breadcrumb } from "../../components/interface";
import { Head } from "../../components/core";

import logo from "../../assets/logo.svg";
const { REACT_APP_NAME } = process.env;

export function CartPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { addNotify } = useNotifyContext();
  const { cartItems, cartCount, cartTotal, clearCart } = useCart();
  const { currentUser } = useAuth();
  const { toggleAccount, isAbnCompleted } = useAccount();

  const handleContinue = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );

  const handleCheckout = useCallback(async () => {
    if (cartCount === 0) return;
    if (currentUser?.customerType === 1 && !isAbnCompleted())
      return setOrderWarning(true);
    navigate("/checkout/shipping", { replace: true });
  }, [cartCount, currentUser?.customerType, isAbnCompleted, navigate]);

  const handleClearCart = useCallback(() => {
    setLoading(true);
    clearCart()
      .catch((error) => {
        console.error(error);
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to clear cart!",
          message: error.message,
        });
      })
      .finally(() => setLoading(false));
  }, [addNotify, clearCart]);

  const [isOrderWarning, setOrderWarning] = useState(false);

  const OrderWarning = useAlert({
    open: isOrderWarning,
    title: "Profile Incomplete!",
    message: "ABN number is required to place an order.",
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    type: AlertType.WARNING,
    cancelText: "Cancel",
    onCancel: () => {
      setOrderWarning(false);
    },
    confirmText: "Complete Profile",
    onConfirm() {
      setOrderWarning(false);
      toggleAccount();
    },
  });

  return (
    <>
      <OrderWarning />
      <Head title={CartResource.name} />
      <h1 className="sr-only">{CartResource.name}</h1>
      <div className="mx-auto max-w-xl py-16 px-4 sm:py-24 sm:px-6 lg:px-0">
        <header className="mb-4 md:mb-8">
          <img
            className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
            src={logo}
            alt={REACT_APP_NAME}
          />
        </header>
        <Breadcrumb
          data={[
            {
              name: "Back to Home",
              href: "/",
            },
            { name: "Cart", href: "/cart", active: true },
            {
              name: "Shipping",
              href: "/checkout/shipping",
            },
            {
              name: "Checkout",
              href: "/checkout/confirmation",
            },
            {
              name: "Completed",
              href: null,
            },
          ]}
          className="mb-12 md:mb-16"
        />

        <div>
          <CartInit>
            {cartItems.length === 0 ? (
              <div className="py-10 text-center md:py-16">
                <p className="mb-4">There are no items in your cart yet.</p>
                <Button variant="tertiary" onClick={handleContinue}>
                  Continue shopping
                </Button>
              </div>
            ) : (
              <>
                <section aria-labelledby="cart-heading">
                  <h2 id="cart-heading" className="sr-only">
                    Items in your shopping cart
                  </h2>
                  <div className="divide-y divide-gray-200 border-t border-b border-gray-200">
                    {cartItems.map((cartItem) => (
                      <Product key={cartItem.id} cartItem={cartItem} />
                    ))}
                  </div>
                  <div className="mt-2 flex justify-end">
                    <Button
                      variant="text"
                      className="text-xs font-medium uppercase tracking-wider hover:underline"
                      onClick={handleClearCart}
                    >
                      Clear All
                    </Button>
                  </div>
                </section>

                <section aria-labelledby="summary-heading" className="mt-10">
                  <h2 id="summary-heading" className="sr-only">
                    Order summary
                  </h2>

                  <div>
                    <dl className="space-y-4">
                      <div className="flex items-center justify-between">
                        <dt className="text-base font-medium text-gray-900">
                          Subtotal
                        </dt>
                        <dd className="ml-4 text-base font-medium text-gray-900">
                          ${cartTotal}
                        </dd>
                      </div>
                    </dl>
                    <p className="mt-1 text-sm text-gray-500">
                      Shipping and taxes will be calculated at checkout.
                    </p>
                  </div>

                  <div className="mt-3">
                    <dl className="space-y-4">
                      <div className="flex items-center justify-between">
                        <dt className="text-base font-medium text-gray-900">
                          Total
                        </dt>
                        <dd className="ml-4 text-xl font-medium text-gray-900">
                          ${cartTotal}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div className="mt-10">
                    <Button
                      className="w-full justify-center"
                      disabled={cartCount === 0 || loading}
                      onClick={handleCheckout}
                    >
                      Continue to Shipping
                    </Button>
                  </div>

                  <div className="mt-6 text-center text-sm">
                    <p>
                      or{" "}
                      <Button
                        variant="text"
                        className="font-medium text-primary-600 transition hover:text-primary-500"
                        onClick={handleContinue}
                      >
                        Continue Shopping
                        <ArrowRightIcon
                          className="ml-2 h-4 w-4"
                          aria-hidden="true"
                        />
                      </Button>
                    </p>
                  </div>
                </section>
              </>
            )}
          </CartInit>
        </div>
      </div>
    </>
  );
}

export const CartResource: ResourceProps = {
  name: "Cart",
  path: "/cart",
};
