import { gql } from "@apollo/client";
import { formatDate } from "../../utils";

export const FETCH_ABN_DETAILS = gql`
  query FetchAbnDetails($abnNo: String!) {
    fetchAbnDetails(abnNo: $abnNo) {
      details
    }
  }
`;

export type IAbnDetails = {
  name: string;
  activeFrom: string;
  registeredFrom: string;
  type: string;
  address: string;
  business: string[];
};

export type IFetchAbnDetails = {
  details: {
    businessEntity201205: {
      ASICNumber: string;
      mainName: {
        effectiveFrom: string;
        organisationName: string;
      };
      entityStatus: {
        effectiveFrom: string;
        effectiveTo: string;
        entityStatusCode: string;
      };
      entityType: {
        entityDescription: string;
        entityTypeCode: string;
      };
      goodsAndServicesTax: {
        effectiveFrom: string;
        effectiveTo: string;
      };
      businessName:
        | {
            effectiveFrom: string;
            organisationName: string;
          }
        | {
            effectiveFrom: string;
            organisationName: string;
          }[];
      mainBusinessPhysicalAddress: {
        effectiveFrom: string;
        effectiveTo: string;
        postcode: string;
        stateCode: string;
      };
    };
  };
};

export const formatAbnDetails = (
  data: IFetchAbnDetails
): IAbnDetails | null => {
  const { businessEntity201205 } = data?.details;
  if (!businessEntity201205) return null;

  const {
    entityStatus,
    goodsAndServicesTax,
    mainName,
    mainBusinessPhysicalAddress,
  } = businessEntity201205;

  const business = [];
  if (Array.isArray(mainName)) {
    business.push(...mainName.flatMap((item) => item.organisationName));
  } else {
    business.push(mainName.organisationName);
  }

  const formattedAbnDetails = {
    name: mainName?.organisationName,
    activeFrom: formatDate(entityStatus.effectiveFrom),
    registeredFrom: formatDate(goodsAndServicesTax.effectiveFrom),
    type: businessEntity201205.entityType.entityDescription,
    address:
      mainBusinessPhysicalAddress?.stateCode +
      " " +
      mainBusinessPhysicalAddress?.postcode,
    business,
  };
  return formattedAbnDetails;
};
