import { useRef, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import {
  AtSymbolIcon,
  QrCodeIcon,
  UserGroupIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import { client, useAuth } from "..";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Loader } from "../../../animations";

import Totp from "../components/challenge/Totp";
import Backup from "../components/challenge/Backup";
import Email from "../components/challenge/Email";

import logo from "../../../assets/logo.svg";
const { REACT_APP_NAME } = process.env;

type ChallengeOptions = {
  title: string;
  description: string;
  href: string;
  path: string;
  type: "otp" | "emailotp" | "recoverycode";
  component: React.FC<any>;
  icon: React.FC<any>;
};

const challenge_options: ChallengeOptions[] = [
  {
    title: "OTP from Authenticator App",
    description: "Use your MFA app to generate a code",
    href: "/auth/challenge/totp",
    path: "totp",
    type: "otp",
    component: Totp,
    icon: ClockIcon,
  },
  {
    title: "Backup Code",
    description: "Use one of your 10-digit code",
    href: "/auth/challenge/backup",
    path: "backup",
    type: "recoverycode",
    component: Backup,
    icon: QrCodeIcon,
  },
  {
    title: "Get a code via email",
    description: "Get a one-time security code via email",
    href: "/auth/challenge/email",
    path: "email",
    type: "emailotp",
    component: Email,
    icon: AtSymbolIcon,
  },
];

function Challenge() {
  return (
    <>
      <div className="relative grid gap-8">
        {challenge_options.map((item) => (
          <Link
            key={item.title}
            to={item.href}
            className="-m-3 flex items-center rounded-lg bg-white p-4 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          >
            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
              <div className="h-full w-full rounded-full bg-secondary-100 p-2">
                <item.icon aria-hidden="true" className="text-secondary" />
              </div>
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">{item.title}</p>
              <p className="text-sm text-gray-500">{item.description}</p>
            </div>
          </Link>
        ))}
        <Link
          to="/logout"
          className="-m-3 flex items-center rounded-lg bg-white p-4 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
        >
          <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
            <div className="h-full w-full rounded-full bg-secondary-100 p-2">
              <UserGroupIcon aria-hidden="true" className="text-secondary" />
            </div>
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-900">
              Try different account
            </p>
            <p className="text-sm text-gray-500">
              Try signing in with different account.
            </p>
          </div>
        </Link>
      </div>
    </>
  );
}

export function ChallengePage() {
  const { isLocked, lockedExpiry, logout, currentIdentifier, saveRefresh } =
    useAuth();
  const { addNotify } = useNotifyContext();

  let navigate = useNavigate();
  const attempt = useRef(0);
  const challenge = useRef(0);

  const [response, setResponse] = useState<FormResponse | null>(null);

  const attemptHandler = () => {
    attempt.current++;
    // if (attempt.current > 3) {
    //   challenge.current++;
    //   attempt.current = 0;
    // }

    // if (attempt.current > 4) {
    //   const { data } = await client.mutate({
    //     mutation: gql`
    //       mutation updateLocked {
    //         updateLocked {
    //           message
    //         }
    //       }
    //     `,
    //   });

    //   try {
    //     console.log(data.updateLocked);
    //     if (data?.updateLocked?.message === "OK") {
    //       window.location.reload();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // console.log(attempt.current, challenge.current);
  };

  const challengeHandler = () => {
    challenge.current++;
    const next =
      challenge_options[challenge.current % challenge_options.length];
    return navigate(next.href);
  };

  const MFA_VERIFY = gql`
    mutation VerifyMfa($code: String!, $type: String!, $platform: String!) {
      verifyMfa(
        input: { params: { code: $code, type: $type, platform: $platform } }
      ) {
        refreshToken
      }
    }
  `;

  const [verifyMfa, { loading, error }] = useMutation(MFA_VERIFY);

  const verifyHandler = ({
    code,
    type,
    platform = "web",
  }: {
    code: string;
    type: "otp" | "emailotp" | "recoverycode";
    platform: "web";
  }) => {
    verifyMfa({
      variables: { code, type, platform },
    })
      .then(({ data }) => {
        if (data?.verifyMfa) {
          const { refreshToken } = data.verifyMfa;
          saveRefresh(refreshToken);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Welcome to the Dashboard",
            message: "You are now logged in",
          });
          return navigate("/");
        } else {
          attemptHandler();
          setResponse({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        attemptHandler();
        setResponse({
          type: "error",
          message: error.message,
        });
      });
  };
  // console.log(isLocked, lockedExpiry);

  const updateIdentity = () => {
    logout();
    client.resetStore();
    return navigate("/auth/login");
  };

  const ChallengeLayout = () => {
    return (
      <>
        <Loader loading={loading} />
        <div className="pb-3 text-center">
          <img
            className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
            src={logo}
            alt={REACT_APP_NAME}
          />
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            Multi-Factor Authentication
          </h2>
          <p className="text-sm">
            To help keep your account safe, We wants to make sure it’s really
            you trying to sign in
          </p>

          <span className="mt-4 inline-flex  items-center rounded-full bg-primary-100 py-2.5 pl-4 pr-2.5 text-sm font-medium text-primary-700">
            <span className="truncate">{currentIdentifier}</span>
            <button
              type="button"
              className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:bg-primary-500 focus:text-white focus:outline-none"
              onClick={updateIdentity}
            >
              <span className="sr-only">Change user</span>
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        </div>

        <Outlet />

        {(error || response) && (
          <div className="mt-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  {error?.message || response?.message}
                </h3>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  let elements = !isLocked
    ? [
        {
          index: true,
          element: <Challenge />,
        },
        ...challenge_options.map((item) => {
          return {
            path: item.path,
            element: (
              <item.component
                title={item.title}
                description={item.description}
                challengeHandler={challengeHandler}
                verifyHandler={verifyHandler}
              />
            ),
          };
        }),
      ]
    : [
        {
          index: true,
          element: <Navigate to="/auth/locked" />,
        },
      ];

  let routes: RouteObject[] = [
    {
      element: <ChallengeLayout />,
      children: [...elements],
    },
    {
      path: "*",
      element: <Navigate to="/error/404" />,
    },
  ];

  return useRoutes(routes);
}
