import { UserAccountModel, UserModel } from "../modules/auth/core";

export type IPage = {
  title: string;
  description: string | undefined;
};
export type InitGenContextPropsState = {
  title: string;
  description: string | undefined;
  sidebarOpen: boolean;
  account: UserAccountModel;
  saveAccount: (user: UserModel | undefined) => void;
  toggleAccount: (open?: boolean) => void;
  isVerified: () => boolean;
  isAbnCompleted: () => boolean;
  updatePage: (page: IPage) => void;
  toggleMenu: (sidebarOpen?: boolean| undefined) => void;
};

export type GenContextProps = {
  updatePage: (page: IPage) => void;
  toggleMenu: (sidebarOpen?: boolean| undefined) => void;
} & InitGenContextPropsState;

export enum GenContextAction {
  UPDATE_PAGE,
  TOGGLE_MENU,
  SAVE_ACCOUNT,
  TOGGLE_ACCOUNT,
}
