import { useCallback, useEffect, useState } from "react";
import { Button } from "../../../components/form";
import { Price } from "../../../components/product";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { ProductVariantQty, Product, Variant } from "../../../graphql/shop";
import placeholder from "../../../assets/placeholder.svg";
import { useCart } from "../../cart/core/CartProvider";
import { Link } from "react-router-dom";
import { Addtocart } from "../../../animations";

export default function ProductFeatured({
  product,
  variant,
}: {
  product: Product;
  variant: Variant;
}) {
  const { addNotify } = useNotifyContext();
  const { cartItems, findCart, addCart, updateCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [currentVariant, setCurrentVariant] = useState<ProductVariantQty>({
    ...variant,
    quantity: 0,
  });

  useEffect(() => {
    const cartVariant = findCart(currentVariant.id);
    if (cartVariant) {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: cartVariant.quantity,
      }));
    } else {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: 0,
      }));
    }
  }, [cartItems, currentVariant.id, findCart]);

  // const handleAddToCart = useCallback(() => {
  //   setLoading(true);
  //   addCart(product, currentVariant, currentVariant.minimumQuantity || 1, true)
  //     .then(() => {
  //       addNotify({
  //         type: NotifyType.SUCCESS,
  //         title: "Added to cart",
  //       });
  //     })
  //     .catch((error: { message: string }) => {
  //       console.log(error);
  //       addNotify({
  //         type: NotifyType.ERROR,
  //         title: "Failed to add to cart",
  //         message: error.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [addCart, addNotify, currentVariant, product]);

  const handleAddOrIncrease = useCallback(() => {
    setLoading(true);
    const cartVariant = findCart(currentVariant.id);
    if (!cartVariant) {
      addCart(
        product,
        currentVariant,
        currentVariant.minimumQuantity || 1,
        true
      )
        .then(() => {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Added to cart",
          });
        })
        .catch((error: { message: string }) => {
          console.log(error);
          addNotify({
            type: NotifyType.ERROR,
            title: "Failed to add to cart",
            message: error.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    if (currentVariant.quantity >= currentVariant.inventory) {
      addNotify({
        type: NotifyType.ERROR,
        title: "Maximum quantity reached",
      });
      setLoading(false);
      return;
    }
    updateCart(currentVariant.id, currentVariant.quantity + 1)
      .catch((error: { message: string }) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to update cart",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addCart, addNotify, currentVariant, findCart, product, updateCart]);

  // const handleRemoveFromCart = useCallback(() => {
  //   setLoading(true);
  //   removeCart(currentVariant.id)
  //     .catch((error: { message: string }) => {
  //       addNotify({
  //         type: NotifyType.ERROR,
  //         title: "Failed to update cart",
  //         message: error.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [currentVariant.id, removeCart, addNotify]);

  return (
    <article className="relative flex h-full w-full flex-col items-start p-5 md:p-8">
      <div className="mt-5 flex w-full flex-wrap sm:flex-row-reverse md:mt-8">
        <figure className="relative w-full overflow-hidden rounded-xl pb-[100%] sm:w-1/3 sm:pb-[30%]">
          {currentVariant.variantImageUrl ? (
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={currentVariant.variantImageUrl}
              alt={product.name}
            />
          ) : (
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={
                product.featureImageUrl ? product.featureImageUrl : placeholder
              }
              alt={product.name}
            />
          )}
        </figure>

        <div className="w-full sm:w-2/3">
          <h4 className="text-xl font-bold">
            <Link to={`/shop/${product.handle}`} className="text-primary-900">
              {product.name}
            </Link>
          </h4>
          <p className="text-sm font-medium uppercase">
            {currentVariant.variantTitle?.map((v) => v.name).join(" / ")}
          </p>
          <Price
            currentVariant={currentVariant}
            className="mt-2 mb-4 text-2xl font-semibold text-gray-200 xl:mb-0"
          />
        </div>
      </div>

      {currentVariant.inventory === 0 ? (
        <Button
          variant="warning"
          className="mt-auto w-auto min-w-[10rem] justify-center md:min-w-[11rem] xl:min-w-[13rem]"
          disabled
        >
          Out of Stock
        </Button>
      ) : (
        <Addtocart
          onClick={handleAddOrIncrease}
          disabled={loading}
          loading={loading}
          count={currentVariant.quantity}
          className="mt-auto w-auto min-w-[10rem] justify-center md:min-w-[11rem] xl:min-w-[13rem]"
        />
      )}
    </article>
  );
}
