import "react-datepicker/dist/react-datepicker.css";

import au from "date-fns/locale/en-AU";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import moment from "moment";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";

import { classNames } from "../../utils";

registerLocale("en-AU", au);

type CustomDatePickerProps = ReactDatePickerProps<any, any>;
interface FieldProps extends CustomDatePickerProps {
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  errors?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: boolean;
  disableIcon?: boolean;
}

export function FieldDatepicker(props: FieldProps) {
  const {
    title,
    name,
    touched,
    errors,
    label = true,
    id,
    className,
    disableIcon = false,
    autoComplete = "off",
    selected: isoDateStr = null,
    ...rest
  } = props;

  const selected = isoDateStr ? moment(isoDateStr).toDate() : null;

  return (
    <>
      <label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      <DatePicker
        id={id ? id : name}
        locale="en-AU"
        className={classNames(
          "relative block w-full appearance-none rounded-md border border-gray-300 py-3 pr-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
          "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
          "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
          touched && errors ? "border-red-600 text-red-900" : "",
          disableIcon ? "pl-3" : "icon-calendar pl-10",
          className ? className : ""
        )}
        name={name}
        autoComplete={autoComplete}
        selected={selected}
        {...rest}
      />
      {touched && errors ? (
        <p
          className="mt-2 text-sm text-red-600"
          id={`${id ? id : name}-errors`}
        >
          {errors.toString()}
        </p>
      ) : null}
    </>
  );
}
