import React, { useEffect, useRef } from "react";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { Combobox } from "@headlessui/react";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/24/outline";
import { classNames, getAddressFromGeocoder } from "../../utils";

interface Address {
  // [key: string]: string;
  street: string;
  state: string;
  suburb: string;
  postcode: string;
  country: string;
  latitude: number;
  longitude: number;
}

interface FieldProps {
  title: string;
  name: string;
  id?: string;
  className?: string;
  type?:
    | "street"
    | "suburb"
    | "state"
    | "postcode"
    | "country"
    | "latitude"
    | "longitude";
  placeholder?: string;
  onChange: (value: Address) => void;
  readOnly?: boolean;
  disabled?: boolean;
  value: string;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  errors?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: boolean;
}

export function FieldAddress(props: FieldProps) {
  const {
    title,
    name,
    value: initialValue,
    type,
    onChange,
    readOnly = false,
    disabled = false,
    touched,
    errors,
    label = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    defaultValue: initialValue,
    requestOptions: {
      componentRestrictions: { country: "au" },
      types: ["address"],
    },
  });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    onChange({
      street: type === "street" ? value : "",
      postcode: type === "postcode" ? value : "",
      state: type === "state" ? value : "",
      suburb: type === "suburb" ? value : "",
      country: type === "country" ? value : "",
      latitude: type === "latitude" ? parseFloat(value) : 0,
      longitude: type === "longitude" ? parseFloat(value) : 0,
    });
    setValue(value);
  };

  const handleSelect = async (val: string) => {
    // setValue(val, false);
    const results = await getGeocode({ address: val });
    const addressObject = getAddressFromGeocoder(results);
    const { street, postcode, state, suburb, country, latitude, longitude } =
      addressObject;

    onChange(addressObject);

    switch (type) {
      case "street":
        setValue(street, false);
        break;
      case "postcode":
        setValue(postcode, false);
        break;
      case "state":
        setValue(state, false);
        break;
      case "suburb":
        setValue(suburb, false);
        break;
      case "country":
        setValue(country, false);
        break;
      case "latitude":
        setValue(latitude.toString(), false);
        break;
      case "longitude":
        setValue(longitude.toString(), false);
        break;
      default:
        setValue(street, false);
        break;
    }
  };

  return (
    <Combobox as="div" value={value} onChange={handleSelect}>
      <Combobox.Label
        htmlFor={id ? id : name}
        className={`block text-base font-medium text-black ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className={classNames(
            "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
            "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
            "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
          )}
          readOnly={readOnly}
          placeholder={placeholder ? placeholder : label ? "" : title}
          onChange={handleInput}
          disabled={disabled || !ready}
          {...rest}
        />
        <Combobox.Button className="absolute inset-y-1 right-1 flex items-center rounded-r-md bg-white px-2 focus:outline-none">
          <GlobeAsiaAustraliaIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {data.length > 0 ? (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {status === "OK"
              ? data.map(
                  ({
                    place_id,
                    description,
                    structured_formatting: { main_text, secondary_text },
                  }) => (
                    <Combobox.Option
                      key={place_id}
                      value={description}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          active ? "bg-primary-600 text-white" : "text-gray-900"
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <span
                          className={classNames(
                            "ml-3 block",
                            selected ? "font-medium" : ""
                          )}
                        >
                          {description}
                          {/* <strong>{main_text}</strong> <small>{secondary_text}</small> */}
                        </span>
                      )}
                    </Combobox.Option>
                  )
                )
              : null}
          </Combobox.Options>
        ) : null}
      </div>
    </Combobox>
  );
}
