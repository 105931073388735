import { gql } from "@apollo/client/core";
import { UserModel } from "../../modules/auth/core";

export const FETCH_ORDERS = gql`
  query Me {
    me {
      orders {
        id
        orderNumber
        items {
          id
          price
          quantity
          orderComments
          variant {
            product {
              name
              handle
              description
              featureImageUrl
            }
            stockCode
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
          }
        }
        deliveryDate
        notes
        shippingAddress {
          address
          suburb
          state
          postcode
        }
        status
        subTotal
        total
        createdAt
      }
    }
  }
`;

export enum Status {
  ARCHIVE = 0,
  DRAFT = 1,
  OPEN = 2,
  PACKING = 3,
  SHIPPED = 4,
  COMPLETED = 5,
}
export type ShippingAddress = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  primaryAddress?: boolean;
};
export type Order = {
  orderNumber: string;
  customer: UserModel;
  items: {
    id: string;
    price: number;
    quantity: number;
    orderComments: string;
    variant: {
      product: {
        name: string;
        handle: string;
        description: string;
        featureImageUrl: string;
      };
      stockCode: string;
      variantImageUrl: string;
      variantTitle: {
        name: string;
        option: {
          name: string;
        };
      };
    };
  }[];
  deliveryDate: string;
  notes: string;
  shippingAddress: ShippingAddress;
  total: number;
  subTotal: number;
  createdAt: string;
  status: Status;
};

export const FETCH_ORDER = gql`
  query FetchOrder($orderNumber: String!) {
    fetchOrder(orderNumber: $orderNumber) {
      id
      orderNumber
      items {
        id
        price
        quantity
        orderComments
        variant {
          product {
            name
            handle
            description
            featureImageUrl
          }
          stockCode
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
        }
      }
      customer {
        customerName
        email
      }
      deliveryDate
      notes
      shippingAddress {
        address
        suburb
        state
        postcode
      }
      status
      subTotal
      total
      createdAt
    }
  }
`;
