import { Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";
import { useFormik } from "formik";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Button, Field } from "../../../components/form";
import { Logo, Spinner } from "../../../animations";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { useAuth } from "../../../modules/auth";
import { useAccount } from "../../../contexts/GenContext";
import { ABNValidation, toSlug } from "../../../utils";
import { UserModel } from "../../../modules/auth/core";
import {
  FETCH_ABN_DETAILS,
  IAbnDetails,
  IFetchAbnDetails,
  formatAbnDetails,
} from "../../../graphql/account";

const UPDATE_ABN_NUMBER = gql`
  mutation AbnNumberUpdate($abnNo: String) {
    abnNumberUpdate(input: { params: { abnNo: $abnNo } }) {
      customer {
        abnNo
      }
    }
  }
`;

declare module "yup" {
  interface StringSchema {
    abnNumber(message?: string): this;
  }
}

Yup.addMethod(Yup.string, "abnNumber", function (errorMessage) {
  return this.test(`test-abn`, errorMessage, function (value) {
    const { path, createError } = this;
    return ABNValidation(value) || createError({ path, message: errorMessage });
  });
});

export function AbnNumber() {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAuth();
  const { saveAccount, toggleAccount, open } = useAccount();
  const { addNotify } = useNotifyContext();
  const [showForm, setShowForm] = useState<boolean>(true);
  const [abnNumberUpdate] = useMutation(UPDATE_ABN_NUMBER);
  const [loadingAbn, setLoadingAbn] = useState(false);
  const [abnDetails, setAbnDetails] = useState<IAbnDetails | null>(null);

  const [fetchAbnDetails] = useLazyQuery<{
    fetchAbnDetails: IFetchAbnDetails;
  }>(FETCH_ABN_DETAILS);

  const formik = useFormik({
    initialValues: {
      abnNo: "",
    },
    validationSchema: Yup.object().shape({
      abnNo: Yup.string().abnNumber("Invalid ABN").required("Required"),
    }),
    onSubmit: (
      values: {
        abnNo: string;
      },
      actions
    ) => {
      console.log(values);
      // setTimeout(() => {
      //   setShowForm(false);
      //   // handleNext();
      //   action.setSubmitting(false);
      // }, 500);

      abnNumberUpdate({
        variables: {
          abnNo: values.abnNo,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.abnNumberUpdate) {
            const { customer } = data.abnNumberUpdate;
            if (!currentUser) return;
            const updatedUser: UserModel = {
              ...currentUser,
              abnNo: customer?.abnNo,
            };
            setCurrentUser(updatedUser);
            saveAccount(updatedUser);
            setShowForm(false);
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "ABN update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          addNotify({
            type: NotifyType.ERROR,
            title: "ABN update failed",
            message: error.message,
          });
        });
    },
  });

  const fetchingAbnDetails = useCallback(
    (abnNo: string) => {
      setLoadingAbn(true);
      fetchAbnDetails({
        variables: {
          abnNo,
        },
      })
        .then(({ data }) => {
          if (data?.fetchAbnDetails?.details) {
            if (data?.fetchAbnDetails?.details?.businessEntity201205) {
              const formated = formatAbnDetails(data?.fetchAbnDetails);
              setAbnDetails(formated);
            } else {
              setAbnDetails(null);
            }
          } else {
            setAbnDetails(null);
          }
        })
        .catch((e) => {
          setAbnDetails(null);
        })
        .finally(() => {
          setLoadingAbn(false);
        });
    },
    [fetchAbnDetails]
  );

  useEffect(() => {
    if (formik.values.abnNo.length < 11) {
      setAbnDetails(null);
      return;
    }
    fetchingAbnDetails(formik.values.abnNo);
  }, [fetchingAbnDetails, formik.values.abnNo]);

  return (
    <>
      <Transition.Root show={showForm && open} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setShowForm(false);
            toggleAccount();
            formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  className="flex h-full flex-col"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="relative px-4 pt-12 pb-4 text-center sm:px-6">
                      <div className="absolute top-5 right-5">
                        <button
                          type="button"
                          className="appearance-none rounded-md border-gray-700 text-gray-600 transition-colors hover:text-gray-900 focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                          onClick={() => {
                            setShowForm(false);
                            toggleAccount();
                            formik.resetForm();
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                      <Dialog.Title className="text-lg font-normal text-black">
                        Your venue's ABN
                      </Dialog.Title>
                      <p className="mt-1 text-sm font-light text-gray-500">
                        This will be added to your invoices each time you place
                        an order.
                      </p>
                    </div>
                    <div className="px-4 sm:px-6">
                      <div className="space-y-6 pb-5">
                        <div>
                          <Field
                            title={t("text_abn_number")}
                            name="abnNo"
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedValue = value.replace(/[^0-9]/g, "");
                              formik.setFieldValue("abnNo", updatedValue);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.abnNo}
                            touched={formik.touched.abnNo}
                            errors={formik.errors.abnNo}
                            label={false}
                          />
                          {loadingAbn ? (
                            <div className="mt-4 text-center">
                              <Logo />
                            </div>
                          ) : abnDetails ? (
                            <div className="mt-4 rounded-lg bg-primary-50 p-2 text-sm text-primary-900 md:p-4">
                              <ul className="space-y-2">
                                <li>
                                  <b>{abnDetails.name}</b>
                                </li>
                                <li>
                                  ABN status: Active from{" "}
                                  {abnDetails.activeFrom}
                                </li>
                                <li>Entity type: {abnDetails.type}</li>
                                <li>
                                  Goods & Services Tax (GST): Registered from{" "}
                                  {abnDetails.registeredFrom}
                                </li>
                                <li>
                                  Main business location: {abnDetails.address}
                                </li>
                                <li className="flex flex-wrap">
                                  <span className="mr-2">Buiness names:</span>
                                  <Fragment>
                                    {abnDetails.business.map((b) => (
                                      <span
                                        key={`b-${toSlug(b)}`}
                                        className="mb-1 mr-1 inline-flex items-center rounded-full bg-primary-700 pl-2 pr-3 text-xs font-normal leading-6 text-white"
                                      >
                                        <span className="mr-1 h-3 w-3 rounded-full bg-white"></span>
                                        {b}
                                      </span>
                                    ))}
                                  </Fragment>
                                </li>
                              </ul>
                            </div>
                          ) : formik.values.abnNo.length === 11 ? (
                            <div className="text-center">
                              <span className="mt-4 inline-block text-sm text-red-500">
                                <sup>*</sup> {t("text_abn_no_info")}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowForm(false);
                        toggleAccount();
                        formik.resetForm();
                      }}
                    >
                      {t("text_cancel")}
                    </Button>
                    <Button type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        t("text_save")
                      )}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
