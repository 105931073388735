import { useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

import { Button } from "../../components/form";

import { useQuery } from "@apollo/client";
import { Logo } from "../../animations";
import { ErrorFallback, Head } from "../../components/core";

import logo from "../../assets/logo.svg";
import { Breadcrumb } from "../../components/interface";
import { renderAddress } from "../../components/order";
import { FETCH_ORDER, type Order } from "../../graphql/order";
import placeholder from "../../assets/placeholder.svg";

const { REACT_APP_NAME } = process.env;

export const CompletedPage = () => {
  const { id } = useParams<{ id: string }>();
  let navigate = useNavigate();
  const handleContinue = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );

  const { data, loading, error } = useQuery<{
    fetchOrder: Order;
  }>(FETCH_ORDER, {
    variables: {
      orderNumber: id,
    },
    onError(error) {
      console.log(error);
    },
  });

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head title={CompletedResource.name} />
      <h1 className="sr-only">{CompletedResource.name}</h1>
      <div className="mx-auto max-w-xl py-16 px-4 sm:py-24 sm:px-6 lg:px-0">
        <header className="mb-4 md:mb-8">
          <img
            className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
            src={logo}
            alt={REACT_APP_NAME}
          />
        </header>
        <Breadcrumb
          data={[
            {
              name: "Back to Home",
              href: "/",
            },
            { name: "Cart", href: "/cart" },
            {
              name: "Shipping",
              href: "/checkout/shipping",
            },
            {
              name: "Checkout",
              href: "/checkout/confirmation",
            },
            {
              name: "Completed",
              href: null,
              active: true,
            },
          ]}
          className="mb-12 md:mb-16"
        />

        {loading ? (
          <div className="py-10 md:py-16">
            <div className="pointer-events-none text-center opacity-20">
              <Logo />
            </div>
          </div>
        ) : (
          <>
            <h2 className="text-base font-medium text-primary-600">
              Thank you!
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">
              It's on the way!
            </p>
            <p className="mt-2 text-base text-gray-500">
              Your order #{data?.fetchOrder.orderNumber} has recieved and will
              be shipped soon.
            </p>

            <dl className="mt-12 text-sm font-medium">
              <dt className="text-gray-900">Order Number</dt>
              <dd className="mt-2 text-primary-600">
                #{data?.fetchOrder.orderNumber}
              </dd>
            </dl>

            <dl className="mt-6 text-sm font-medium">
              <dt className="text-gray-900">Order Update</dt>
              <dd className="mt-2 font-normal text-gray-500">
                You’ll get shipping and delivery updates by email.
              </dd>
            </dl>

            <div className="mt-10 border-t border-gray-200">
              <h2 className="sr-only">Your order</h2>

              <h3 className="sr-only">Items</h3>
              {data?.fetchOrder.items.map((item) => (
                <div
                  key={item.id}
                  className="flex space-x-6 border-b border-gray-200 py-4"
                >
                  <img
                    src={
                      item.variant.product.featureImageUrl
                        ? item.variant.product.featureImageUrl
                        : placeholder
                    }
                    alt={item.variant.product.name}
                    className="h-12 w-12 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-16 sm:w-16"
                  />
                  <div className="flex flex-auto flex-col">
                    <div>
                      <h4 className="text-sm font-medium text-gray-900">
                        <Link to={`/shop/${item.variant.product.handle}`}>
                          {item.variant.product.name}
                        </Link>
                      </h4>
                    </div>
                    <div className="mt-4 flex flex-1 items-end">
                      <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                        <div className="flex">
                          <dt className="text-gray-700">Quantity</dt>
                          <dd className="ml-2 font-medium text-gray-900">
                            {item.quantity}
                          </dd>
                        </div>
                        <div className="flex pl-4 sm:pl-6">
                          <dt className="text-gray-700">Price</dt>
                          <dd className="ml-2 font-medium text-gray-900">
                            ${item.price}
                          </dd>
                        </div>
                        <div className="pl-4 sm:pl-6">
                          <dt className="text-gray-700">Comment</dt>
                          <dd className="text-xs font-medium text-gray-900">
                            {item.orderComments}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              ))}

              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-700">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">
                    ${data?.fetchOrder.subTotal}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt>Taxes</dt>
                  <dd className="text-gray-900">-</dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">${data?.fetchOrder.total}</dd>
                </div>
              </dl>

              <h3 className="mt-16 text-base font-medium">
                Customer Information
              </h3>
              <dl className="mt-4 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                <div>
                  <dt className="font-medium text-gray-900">
                    Contact Information
                  </dt>
                  <dd className="mt-2">
                    <span className="block">
                      {data?.fetchOrder.customer.customerName}
                    </span>
                    <span className="block">
                      {data?.fetchOrder.customer.email}
                    </span>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">
                    Shipping Address
                  </dt>
                  <dd className="mt-2">
                    <address className="not-italic">
                      {renderAddress(data?.fetchOrder.shippingAddress ?? null)}
                    </address>
                  </dd>
                </div>
                <div className="col-span-2">
                  <dt className="mt-5 font-medium text-gray-900">Note</dt>
                  <dd className="mt-2">
                    <span className="block">{data?.fetchOrder.notes}</span>
                  </dd>
                </div>
              </dl>

              <div className="mt-8 border-t border-gray-200 py-4 text-right">
                <Button
                  variant="text"
                  className="font-medium text-primary-600 hover:text-primary-500"
                  onClick={handleContinue}
                >
                  Continue Shopping
                  <ArrowRightIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const CompletedResource: ResourceProps = {
  name: "Order Completed",
  path: "/checkout/completed/:id",
};
