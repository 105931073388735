import { ShippingAddress, Status } from "../../graphql/order";

export const renderAddress = (shippingAddress: ShippingAddress | null) => {
  let address = "";
  if (!shippingAddress) return address;
  const { address: postalAddress, suburb, state, postcode } = shippingAddress;
  if (postalAddress) address += postalAddress;
  if (suburb) address += `, ${suburb}`;
  if (state) address += `, ${state}`;
  if (postcode) address += `, ${postcode}`;
  return address;
};

export const renderStatus = (status: Status) => {
  switch (status) {
    case Status.ARCHIVE:
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
          Archived
        </span>
      );
    case Status.DRAFT:
      return (
        <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
          Draft
        </span>
      );
    case Status.OPEN:
      return (
        <span className="inline-flex rounded-full bg-gray-200 px-2 text-xs font-medium leading-5 text-gray-700">
          Open
        </span>
      );
    case Status.PACKING:
      return (
        <span className="inline-flex rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-500">
          Packing
        </span>
      );
    case Status.SHIPPED:
      return (
        <span className="inline-flex rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-700">
          Ready To Ship
        </span>
      );
    case Status.COMPLETED:
      return (
        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
          Completed
        </span>
      );
  }
};
