import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Field,
  Button,
  FieldPassword,
  passwordValidator,
} from "../../../../components/form/";
import { Spinner } from "../../../../animations";

declare module "yup" {
  interface StringSchema {
    strongPassword(threshold?: number, strength?: number): this;
  }
}

Yup.addMethod(Yup.string, "strongPassword", strongPasswordMethod);

function strongPasswordMethod(this: any, threshold: number, strength: number) {
  return this.test(
    "strongPasswordTest",
    null,
    (value: string, context: any) => {
      const { path, createError } = context;
      try {
        passwordValidator(value, threshold, strength);
        return true;
      } catch (e: any) {
        return createError({
          path,
          message: e.message,
        });
      }
    }
  );
}

const PasswordSchema = Yup.object().shape({
  "current-password": Yup.string().required("Required"),
  "new-password": Yup.string().required("Required").strongPassword(7),
  "confirm-password": Yup.string()
    .required("Password confirmation is required")
    .when("new-password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new-password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

type IAccountPassword = {
  "current-password": string;
  "new-password": string;
  "confirm-password": string;
};

export default function FormPassword({
  initialValues,
  onSubmit,
}: {
  initialValues: IAccountPassword;
  onSubmit: (values: any, actions: any) => void;
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PasswordSchema,
    onSubmit: onSubmit,
  });

  return (
    <form className="mt-5 space-y-4" onSubmit={formik.handleSubmit}>
        <div>
          <Field
            title={t("text_current_password")}
            autoComplete="current-password"
            type="password"
            name="current-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values["current-password"]}
            touched={formik.touched["current-password"]}
            errors={formik.errors["current-password"]}
          />
        </div>
        <div>
          <FieldPassword
            title={t("text_new_password")}
            autoComplete="new-password"
            name="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values["new-password"]}
            touched={formik.touched["new-password"]}
            errors={formik.errors["new-password"]}
          />
        </div>
        <div>
          <Field
            title={t("text_confirm_password")}
            autoComplete="confirm-password"
            type="password"
            name="confirm-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values["confirm-password"]}
            touched={formik.touched["confirm-password"]}
            errors={formik.errors["confirm-password"]}
          />
        </div>
        <div className="w-full flex justify-end space-x-4">
          <Button
            variant="secondary"
            onClick={() => {
              formik.resetForm();
            }}
          >
            {t("text_reset")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              t("text_update")
            )}
          </Button>
      </div>
    </form>
  );
}
