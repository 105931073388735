import { Fragment, useContext } from "react";
import { Bars3Icon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { GenContext, useAccount } from "../contexts/GenContext";
import { Button } from "../components/form";
import { CustomerType, UserAccountModelKey } from "../modules/auth/core";
import {
  AbnNumber,
  AverageSpend,
  ContactDetails,
  VenueType,
} from "./components/wizard";
import { useAuth } from "../modules/auth";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../utils";
import { Avatar } from "../components/presentation";
import { Sidebar } from "../components/cart";
import { CartInit, useCart } from "../modules/cart/core/CartProvider";

export default function HeaderLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { toggleMenu } = useContext(GenContext);
  const { currentUser } = useAuth();
  const { cartCount, toggleCart } = useCart();
  const { activeStep, percentage, open } = useAccount();

  const renderWalkthrough = () => {
    if (
      currentUser?.customerType !== 1 ||
      (currentUser?.customerType === 1 && (!open || percentage === 100))
    )
      return null;
    switch (activeStep) {
      case UserAccountModelKey.VENUE_TYPE:
        return <VenueType />;
      case UserAccountModelKey.AVERAGE_SPEND:
        return <AverageSpend />;
      case UserAccountModelKey.ABN_NUMBER:
        return <AbnNumber />;
      case UserAccountModelKey.CONTACT_DETAILS:
        return <ContactDetails />;
    }
  };

  const userNavigation: {
    name: string;
    href: string;
    icon: string;
  }[] = [
    { name: "Your Profile", href: "/account", icon: "bi bi-person" },
    { name: "Logout", href: "/logout", icon: "bi bi-box-arrow-left" },
  ];

  return (
    <>
      {renderWalkthrough()}
      <div className="relative z-30 mx-8 flex flex-shrink-0 pt-4 pb-4">
        <button
          type="button"
          className="-ml-2 mr-2 border-0 px-2 text-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 sm:px-4 lg:hidden"
          onClick={() => toggleMenu(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="mr-4 flex flex-1 items-center justify-between">
          <div className="flex flex-col xl:self-end">{children}</div>
          <div className="flex items-center">
            <Menu as="div" className="relative mr-3">
              <Menu.Button className="flex items-center rounded-full bg-white text-left text-base focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 lg:rounded-md lg:bg-transparent lg:p-2 lg:hover:bg-gray-50">
                <span className="relative mr-4 h-12 w-12">
                  <Avatar className="relative z-10 bg-primary-700 text-white" />
                  <span className="absolute top-1/2 left-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 scale-110 rounded-full bg-primary-700"></span>
                </span>
                {currentUser?.customerType === CustomerType.COMPANY ? (
                  <div className="hidden lg:block">
                    <div className="flex items-center">
                      <span className="block">{currentUser?.companyName}</span>
                      <ChevronDownIcon
                        className="ml-1 h-4 w-4 flex-shrink-0 text-gray-700"
                        aria-hidden="true"
                      />
                    </div>
                    <span className="text-sm font-light text-gray-500">
                      Company
                    </span>
                  </div>
                ) : (
                  <div className="hidden lg:block">
                    <div className="flex items-center">
                      <span className="block">{currentUser?.customerName}</span>
                      <ChevronDownIcon
                        className="ml-1 h-4 w-4 flex-shrink-0 text-gray-700"
                        aria-hidden="true"
                      />
                    </div>
                    <span className="text-sm font-light text-gray-500">
                      Individual
                    </span>
                  </div>
                )}
                <span className="sr-only">Open user menu </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 mt-4 w-36 origin-top-right rounded-tl-2xl rounded-br-2xl rounded-bl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2 md:w-44 md:px-2.5 md:py-3"
                  static
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="16px"
                    height="16px"
                    viewBox="0 0 110.9 96"
                    enableBackground="new 0 0 110.9 96"
                    className="absolute -top-3 right-0 -z-0"
                    fill="#ffffff"
                    style={{
                      filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                    }}
                  >
                    <polygon points="110.9,0 0,96 110.9,96 " />
                  </svg>
                  {userNavigation.map((item) => (
                    <Menu.Item key={`um-${item.href}`}>
                      {({ active }) => (
                        <div className="py-0.5">
                          <Link
                            to={item.href}
                            className={classNames(
                              active
                                ? "bg-primary-700/10 text-primary-700"
                                : "",
                              "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                            )}
                          >
                            <span
                              className={classNames(
                                "mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                                item.icon
                              )}
                              aria-hidden="true"
                            />
                            <span className="flex-1 truncate">{item.name}</span>
                          </Link>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
            <CartInit>
              <Button
                variant="icon"
                className="relative flex h-12 w-12 items-center justify-center rounded-full bg-primary text-white"
                onClick={() => toggleCart()}
              >
                <span className="absolute top-3.5 right-2 z-10 min-h-[14px] min-w-[14px] rounded-full bg-white text-[8px] leading-[14px] text-primary">
                  {cartCount}
                </span>
                <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">View Cart</span>
              </Button>
            </CartInit>
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  );
}
