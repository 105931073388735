import styled from "styled-components";
export * from "./Field";
export * from "./FieldCheckbox";
export * from "./FieldPassword";
export * from "./FilePreview";
export * from "./FieldSelect";
export * from "./FieldEditor";
export * from "./FieldUploader";
export * from "./FieldAddress";
export * from "./FieldState";
export * from "./FieldDatepicker";
export * from "./FieldTime";
export * from "./Response";
export * from "./Button";

export const selectStyles = {
  menu: (base: any, state: any) => ({
    ...base,
    padding: 5,
    borderRadius: "0.375rem",
  }),
  multiValue: (base: any, state: any) => {
    return state.data.isFixed
      ? { ...base, backgroundColor: "gray", color: "#ffffff" }
      : {
          ...base,
          backgroundColor: "#68CB6D",
          color: "#ffffff",
        };
  },
  multiValueLabel: (base: any, state: any) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "#ffffff", paddingRight: 6 }
      : { ...base, color: "#ffffff" };
  },
  multiValueRemove: (base: any, state: any) => {
    return state.data.isFixed
      ? { ...base, display: "none", color: "#ffffff" }
      : {
          ...base,
          color: "#ffffff",
          ":hover": {
            backgroundColor: "#ff1100",
            color: "#ffffff",
          },
        };
  },
  option: (base: any, state: any) => ({
    ...base,
    margin: "5px 0",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "0.375rem",
    backgroundColor: state.isDisabled
      ? "#f7f7f7"
      : state.isSelected
      ? "#e5ece4"
      : "#ffffff",
    color: state.isDisabled
      ? "#808080"
      : state.isSelected
      ? "#329137"
      : "#374151",

    ":hover": {
      backgroundColor: state.isDisabled ? "#f7f7f7" : "#e5ece4",
      color: state.isDisabled ? "#808080" : "#329137",
    },
  }),
};

export const SelectWrapper = styled.div`
  position: relative;

  & > div > div:nth-child(3) {
    padding-left: 0.75rem;
    min-height: 1rem;
    border: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }
  & > div input,
  & > div input:focus,
  & > div input:hover,
  & > div input:active {
    border: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }
  & > div > div > div:first-child {
    padding: 0;
  }
  & > div > div > div > div:first-child {
    margin-left: 0;
    color: inherit;
  }
  div div[data-value] {
    margin: 0;
    padding: 0;
    color: inherit;
  }
  div input[type="text"] {
    height: 3rem;
  }
`;
