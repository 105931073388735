import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";
import { gql, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Field,
  Button,
  FieldAddress,
  FieldState,
  SelectWrapper,
  selectStyles,
} from "../../../../components/form/";
import { Spinner } from "../../../../animations";

import { classNames } from "../../../../utils";

type IAccountVenue = {
  phoneNumber: string;
  mobileNumber: string;
  postalAddress: string;
  suburb: string;
  state: string;
  postcode: string;
  customerVenueTypeId: number | null;
  customerVenueFoodSpendId: number | null;
};

const VenueSchema = Yup.object().shape({
  phoneNumber: Yup.string(),
  mobileNumber: Yup.string(),
  postalAddress: Yup.string(),
  suburb: Yup.string(),
  state: Yup.string(),
  postcode: Yup.string(),
  customerVenueTypeId: Yup.number().nullable(),
  customerVenueFoodSpendId: Yup.number().nullable(),
});

export default function FormVenue({
  initialValues,
  onSubmit,
  onCancel,
}: {
  initialValues: IAccountVenue;
  onSubmit: (values: any, actions: any) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: VenueSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <FieldAddress
            title={t("text_venue_address")}
            name="postalAddress"
            type="street"
            onChange={(value) => {
              const { street, state, suburb, postcode } = value;

              formik.setValues({
                ...formik.values,
                postalAddress: street,
                suburb: suburb || formik.values.suburb,
                state: state || formik.values.state,
                postcode: postcode || formik.values.postcode,
              });
            }}
            value={formik.values.postalAddress}
            touched={formik.touched.postalAddress}
            errors={formik.errors.postalAddress}
          />
        </div>
        <div>
          <Field
            title={t("text_venue_suburb")}
            name="suburb"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.suburb}
            touched={formik.touched.suburb}
            errors={formik.errors.suburb}
          />
        </div>
        <div>
          <FieldState
            title={t("text_venue_state")}
            name="state"
            onChange={(value) => {
              formik.setFieldValue("state", value);
            }}
            value={formik.values.state}
            touched={formik.touched.state}
            errors={formik.errors.state}
          />
        </div>
        <div>
          <FieldAddress
            title={t("text_venue_postcode")}
            name="postcode"
            type="postcode"
            onChange={(value) => {
              const { street, state, suburb, postcode } = value;

              formik.setValues({
                ...formik.values,
                postalAddress: street || formik.values.postalAddress,
                suburb: suburb || formik.values.suburb,
                state: state || formik.values.state,
                postcode: postcode,
              });
            }}
            value={formik.values.postcode}
            touched={formik.touched.postcode}
            errors={formik.errors.postcode}
          />
        </div>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <Field
            title={t("text_venue_phone")}
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            touched={formik.touched.phoneNumber}
            errors={formik.errors.phoneNumber}
          />
        </div>

        <div>
          <Field
            title={t("text_venue_mobile")}
            name="mobileNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mobileNumber}
            touched={formik.touched.mobileNumber}
            errors={formik.errors.mobileNumber}
          />
        </div>

        <div>
          <label className="block text-base font-medium text-gray-700">
            {t("text_venue_type")}
          </label>
          <FieldVenueType
            value={formik.values.customerVenueTypeId}
            onChange={(value) => {
              formik.setFieldValue("customerVenueTypeId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.customerVenueTypeId &&
                formik.errors.customerVenueTypeId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.customerVenueTypeId &&
          formik.errors.customerVenueTypeId ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.customerVenueTypeId.toString()}
            </p>
          ) : null}
        </div>

        <div>
          <label className="block text-base font-medium text-gray-700">
            {t("text_venue_food_spend")}
          </label>
          <FieldVenueSpend
            value={formik.values.customerVenueFoodSpendId}
            onChange={(value) => {
              formik.setFieldValue("customerVenueFoodSpendId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.customerVenueFoodSpendId &&
                formik.errors.customerVenueFoodSpendId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.customerVenueFoodSpendId &&
          formik.errors.customerVenueFoodSpendId ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.customerVenueFoodSpendId.toString()}
            </p>
          ) : null}
        </div>

        <div className="col-span-2 flex justify-end space-x-4">
          <Button
            variant="secondary"
            onClick={() => {
              onCancel();
              formik.resetForm();
            }}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              t("text_save")
            )}
          </Button>
        </div>
      </div>

      {/* <header className="mb-2">
        <b>Venue Attendance Hours</b>
      </header>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-900">
            {t("text_venue_attendance_from")}
          </label>
          <FieldTime
            onChange={formik.handleChange}
            value={formik.values.venueAttendanceFrom}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.venueAttendanceFrom &&
                formik.errors.venueAttendanceFrom
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.venueAttendanceFrom &&
          formik.errors.venueAttendanceFrom ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.venueAttendanceFrom.toString()}
            </p>
          ) : null}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-900">
            {t("text_venue_attendance_to")}
          </label>
          <FieldTime
            onChange={formik.handleChange}
            value={formik.values.venueAttendanceTo}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.venueAttendanceTo &&
                formik.errors.venueAttendanceTo
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.venueAttendanceTo &&
          formik.errors.venueAttendanceTo ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.venueAttendanceTo.toString()}
            </p>
          ) : null}
        </div>

        <div className="col-span-2 flex justify-end space-x-4">
          <Button
            variant="secondary"
            onClick={() => {
              onCancel();
              formik.resetForm();
            }}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit">
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              t("text_save")
            )}
          </Button>
        </div>
      </div> */}
    </form>
  );
}

const FETCH_VENUE_TYPE = gql`
  query FetchVenueType {
    fetchVenueType {
      id
      name
    }
  }
`;

export function FieldVenueType({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVenueType] = useLazyQuery(FETCH_VENUE_TYPE);

  useEffect(() => {
    fetchVenueType()
      .then(({ data, error }) => {
        if (data?.fetchVenueType) {
          const fetchVenueTypes = data.fetchVenueType.map(
            (v: { id: string; name: string }) => ({
              value: v.id,
              label: v.name,
            })
          );

          setOptions(fetchVenueTypes);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "VenueTypes read is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchVenueType, navigate]);

  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current && value) {
      const selected = options.find((v) => parseInt(v.value) === value);
      if (selected) {
        setValues(selected);
      }
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue) => {
          setValues(newValue);
          const updateValue = newValue?.value ? parseInt(newValue.value) : null;
          onChange(updateValue);
        }}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_VENUE_FOODSPEND = gql`
  query FetchVenueFoodSpend {
    fetchVenueFoodSpend {
      id
      spendRange
    }
  }
`;

export function FieldVenueSpend({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVenueFoodSpend] = useLazyQuery(FETCH_VENUE_FOODSPEND);

  useEffect(() => {
    fetchVenueFoodSpend()
      .then(({ data, error }) => {
        if (data?.fetchVenueFoodSpend) {
          const fetchVenueFoodSpends = data.fetchVenueFoodSpend.map(
            (v: { id: string; spendRange: string }) => ({
              value: v.id,
              label: v.spendRange,
            })
          );

          setOptions(fetchVenueFoodSpends);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "VenueFoodSpends read is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchVenueFoodSpend, navigate]);

  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current && value) {
      const selected = options.find((v) => parseInt(v.value) === value);
      if (selected) {
        setValues(selected);
      }
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue) => {
          setValues(newValue);
          const updateValue = newValue?.value ? parseInt(newValue.value) : null;
          onChange(updateValue);
        }}
        isClearable
      />
    </SelectWrapper>
  );
}
