import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Field,
  FieldAddress,
  FieldState,
} from "../../../components/form";
import { Spinner } from "../../../animations";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { useAuth } from "../../../modules/auth";
import { useAccount } from "../../../contexts/GenContext";
import { UserModel } from "../../../modules/auth/core";

const UPDATE_VENUE_ADDRESS = gql`
  mutation VenueAddressUpdate(
    $phoneNumber: String
    $mobileNumber: String
    $postalAddress: String
    $suburb: String
    $state: String
    $postcode: String
  ) {
    venueAddressUpdate(
      input: {
        params: {
          phoneNumber: $phoneNumber
          mobileNumber: $mobileNumber
          postalAddress: $postalAddress
          suburb: $suburb
          state: $state
          postcode: $postcode
        }
      }
    ) {
      customer {
        details {
          emailImportantUpdates
          emailSpecials
          mobileNumber
          phoneNumber
          postalAddress
          postcode
          state
          suburb
          venueFoodSpend {
            id
            spendRange
          }
          venueType {
            id
            name
          }
        }
      }
    }
  }
`;

export function ContactDetails() {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAuth();
  const { saveAccount, toggleAccount, open } = useAccount();
  const { addNotify } = useNotifyContext();
  const [showForm, setShowForm] = useState<boolean>(true);
  const [venueAddressUpdate] = useMutation(UPDATE_VENUE_ADDRESS);

  const formik = useFormik({
    initialValues: {
      phoneNumber: currentUser?.details?.phoneNumber || "",
      mobileNumber: currentUser?.details?.mobileNumber || "",
      postalAddress: currentUser?.details?.postalAddress || "",
      suburb: currentUser?.details?.suburb || "",
      state: currentUser?.details?.state || "",
      postcode: currentUser?.details?.postcode || "",
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("Required"),
      mobileNumber: Yup.string().required("Required"),
      postalAddress: Yup.string().required("Required"),
      suburb: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      postcode: Yup.string().required("Required"),
    }),
    onSubmit: (
      values: {
        phoneNumber: string;
        mobileNumber: string;
        postalAddress: string;
        suburb: string;
        state: string;
        postcode: string;
      },
      actions
    ) => {
      console.log(values);
      // setTimeout(() => {
      //   setShowForm(false);
      //   // handleNext();
      //   action.setSubmitting(false);
      // }, 500);

      venueAddressUpdate({
        variables: {
          phoneNumber: values.phoneNumber,
          mobileNumber: values.mobileNumber,
          postalAddress: values.postalAddress,
          suburb: values.suburb,
          state: values.state,
          postcode: values.postcode,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.venueAddressUpdate) {
            const { customer } = data.venueAddressUpdate;
            if (!currentUser) return;
            const updatedUser: UserModel = {
              ...currentUser,
              details: customer?.details,
            };
            setCurrentUser(updatedUser);
            saveAccount(updatedUser);
            setShowForm(false);
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Venue Address update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          addNotify({
            type: NotifyType.ERROR,
            title: "Venue Address update failed",
            message: error.message,
          });
        });
    },
  });

  return (
    <>
      <Transition.Root show={showForm && open} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setShowForm(false);
            toggleAccount();
            formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  className="flex h-full flex-col"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="relative px-4 pt-12 pb-4 text-center sm:px-6">
                      <div className="absolute top-5 right-5">
                        <button
                          type="button"
                          className="appearance-none rounded-md border-gray-700 text-gray-600 transition-colors hover:text-gray-900 focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                          onClick={() => {
                            setShowForm(false);
                            toggleAccount();
                            formik.resetForm();
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                      <Dialog.Title className="text-lg font-normal text-black">
                        Your venue's delivery details
                      </Dialog.Title>
                      <p className="mt-1 text-sm font-light text-gray-500">
                        Help suppliers to easily find your venue.
                      </p>
                    </div>
                    <div className="px-4 sm:px-6">
                      <div className="space-y-6 pb-5">
                        <div>
                          <FieldAddress
                            title={t("text_venue_address")}
                            name="postalAddress"
                            type="street"
                            onChange={(value) => {
                              const { street, state, suburb, postcode } = value;

                              formik.setValues({
                                ...formik.values,
                                postalAddress: street,
                                suburb: suburb || formik.values.suburb,
                                state: state || formik.values.state,
                                postcode: postcode || formik.values.postcode,
                              });
                            }}
                            value={formik.values.postalAddress}
                            touched={formik.touched.postalAddress}
                            errors={formik.errors.postalAddress}
                            label={false}
                          />
                        </div>
                        <div>
                          <Field
                            title={t("text_venue_suburb")}
                            name="suburb"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.suburb}
                            touched={formik.touched.suburb}
                            errors={formik.errors.suburb}
                            label={false}
                          />
                        </div>
                        <div>
                          <FieldState
                            title={t("text_venue_state")}
                            name="state"
                            onChange={(value) => {
                              formik.setFieldValue("state", value);
                            }}
                            value={formik.values.state}
                            touched={formik.touched.state}
                            errors={formik.errors.state}
                            label={false}
                          />
                        </div>
                        <div>
                          <FieldAddress
                            title={t("text_venue_postcode")}
                            name="postcode"
                            type="postcode"
                            onChange={(value) => {
                              const { street, state, suburb, postcode } = value;

                              formik.setValues({
                                ...formik.values,
                                postalAddress:
                                  street || formik.values.postalAddress,
                                suburb: suburb || formik.values.suburb,
                                state: state || formik.values.state,
                                postcode: postcode,
                              });
                            }}
                            value={formik.values.postcode}
                            touched={formik.touched.postcode}
                            errors={formik.errors.postcode}
                            label={false}
                          />
                        </div>

                        <div>
                          <Field
                            title={t("text_venue_phone")}
                            name="phoneNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phoneNumber}
                            touched={formik.touched.phoneNumber}
                            errors={formik.errors.phoneNumber}
                            label={false}
                          />
                        </div>

                        <div>
                          <Field
                            title={t("text_venue_mobile")}
                            name="mobileNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobileNumber}
                            touched={formik.touched.mobileNumber}
                            errors={formik.errors.mobileNumber}
                            label={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowForm(false);
                        toggleAccount();
                        formik.resetForm();
                      }}
                    >
                      {t("text_cancel")}
                    </Button>
                    <Button type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        t("text_save")
                      )}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
