import { Fragment, useContext } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { XMarkIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useTrail, animated as a } from "@react-spring/web";
import { useAuth } from "../modules/auth";
import { classNames } from "../utils";

import { Avatar } from "../components/presentation";
import { pages } from "../modules";

import { GenContext, useAccount } from "../contexts/GenContext";
import LogoDark from "../assets/logo-dark.svg";
import { UserAccountModelKey } from "../modules/auth/core";

import IconExit from "../assets/icon-exit.svg";

const navigation: IPage[] = pages;

interface NavigationLinkProps extends LinkProps {
  name: string;
  icon: string;
  child?: { name: string; path: string }[];
}

interface SubLinkProps {
  open: boolean;
  name: string;
  icon: string;
  child: { name: string; path: string }[];
  active: boolean;
}
type NavProps = {
  navBorder?: boolean;
};
export default function NavLayout(props: NavProps) {
  const { navBorder = true } = props;
  const { sidebarOpen, toggleMenu } = useContext(GenContext);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={toggleMenu}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-[18rem] flex-1 flex-col border-r border-gray-300 bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full text-white focus:outline-none"
                      onClick={() => toggleMenu(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SidebarBody />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div
          className={classNames(
            "flex flex-grow flex-col overflow-y-auto bg-white pt-5 pb-4",
            navBorder ? "border-r border-gray-200" : ""
          )}
        >
          <SidebarBody />
        </div>
      </div>
    </>
  );
}

function SidebarBody() {
  const navigate = useNavigate();
  const { currentUser, currentType } = useAuth();
  const { steps, activeStep, percentage, toggleAccount } = useAccount();
  const location = useLocation();

  function MenuItem({
    children,
    to,
    name,
    icon,
    child,
    ...props
  }: NavigationLinkProps) {
    let resolved = useResolvedPath(to);
    let isMatch = location.pathname.match(resolved.pathname);
    let active = isMatch ? true : false;

    return !child ? (
      <div>
        <Link
          to={to}
          className={classNames(
            active
              ? " bg-primary-700 text-white"
              : "text-black hover:text-primary-700",
            "group flex items-center rounded-lg px-4 py-3 text-base font-normal leading-6 transition-colors"
          )}
          aria-current={active ? "page" : undefined}
          {...props}
        >
          <img
            src={icon}
            alt={`Icon for ${name}`}
            className={classNames(
              active
                ? "brightness-0 grayscale-0 invert"
                : "brightness-0 grayscale-0 invert-0 group-hover:brightness-100 group-hover:grayscale-0 group-hover:invert-0",
              "mr-4 h-5 w-5 flex-shrink-0 text-center text-xl leading-6 transition"
            )}
            aria-hidden="true"
          />
          <span className="truncate">{name}</span>
        </Link>
      </div>
    ) : (
      <>
        <Disclosure as="div" defaultOpen={active} className="space-y-1">
          {(props) => (
            <MenuSubItem
              {...props}
              name={name}
              icon={icon}
              child={child}
              active={active}
            />
          )}
        </Disclosure>
      </>
    );
  }

  function MenuSubItem({ open, name, icon, child, active }: SubLinkProps) {
    const trailConfig = {
      mass: 3,
      tension: active ? 0 : 1000,
      friction: 100,
    };

    const childTrail = useTrail(child ? child.length : 0, {
      config: trailConfig,
      opacity: open ? 1 : 0,
      x: open ? 0 : 10,
      height: open ? 36 : 0,
      from: { opacity: 0, x: 10, height: 0 },
    });

    return (
      <>
        <Disclosure.Button
          className={classNames(
            active
              ? " bg-primary-700 text-white"
              : "text-primary-50 hover:text-primary-700",
            "group flex w-full items-center rounded-lg px-4 py-3 text-left text-sm font-normal leading-6 transition-colors"
          )}
        >
          <img
            src={icon}
            alt={`Icon for ${name}`}
            className={classNames(
              active
                ? "brightness-0 grayscale-0 invert"
                : "brightness-0 grayscale-0 invert-0 group-hover:brightness-100 group-hover:grayscale-0 group-hover:invert-0",
              "mr-4 h-5 w-5 flex-shrink-0 text-center text-xl leading-6 transition"
            )}
            aria-hidden="true"
          />
          <span className="flex-1 truncate">{name}</span>
          <ChevronUpIcon
            className={classNames(
              open ? "text-gray-400" : "rotate-90 text-gray-300",
              "ml-3 h-4 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
            )}
            aria-hidden="true"
          />
        </Disclosure.Button>
        <Disclosure.Panel className="space-y-1">
          {childTrail.map(({ x, height, ...rest }, index) => {
            let isMatch = location.pathname.match(child[index].path);
            let active = isMatch ? true : false;

            return (
              <a.div
                key={child[index].name}
                style={{
                  ...rest,
                  transform: x.to((x) => `translate3d(0,${x}px,0)`),
                }}
              >
                <a.div style={{ height }}>
                  <Link
                    to={child[index].path}
                    className={classNames(
                      active
                        ? "text-primary-300 hover:text-primary-400"
                        : "text-gray-300 hover:text-primary-700",
                      "group flex w-full items-center rounded-md py-2 pl-12 pr-2 text-sm font-light transition-colors"
                    )}
                  >
                    <span className="truncate">{child[index].name}</span>
                  </Link>
                </a.div>
              </a.div>
            );
          })}
        </Disclosure.Panel>
      </>
    );
  }

  const renderSteps = (key: UserAccountModelKey) => {
    switch (key) {
      case UserAccountModelKey.VENUE_TYPE:
        return "Venue Type";
      case UserAccountModelKey.AVERAGE_SPEND:
        return "Average Spend";
      case UserAccountModelKey.ABN_NUMBER:
        return "ABN Number";
      case UserAccountModelKey.CONTACT_DETAILS:
        return "Contact Details";
    }
  };

  return (
    <>
      <div className="flex flex-shrink-0 items-center justify-center px-4 py-6 sm:px-6 md:px-8">
        <Link to="/" className="w-3/4">
          <span className="sr-only">{process.env.REACT_APP_NAME}</span>
          <img src={LogoDark} alt={process.env.REACT_APP_NAME} />
        </Link>
      </div>
      <nav
        className="flex flex-1 flex-col overflow-y-auto"
        aria-label="Sidebar"
      >
        <div className="space-y-1 px-4 py-6 sm:px-6 md:px-8">
          {navigation.map((item) => (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              to={item.path}
              child={item?.children}
            />
          ))}
        </div>
        <div className="mt-6 pt-6 md:mt-auto">
          <div className="space-y-4 px-4 sm:px-6 md:px-8">
            {/* {currentUser?.customerType === 1 && percentage < 100 ? (
              <div className="space-y-4 p-3">
                <div className="text-sm font-medium uppercase">
                  Profile is {percentage}% complete
                </div>
                <div>
                  {Object.entries(steps).map(([key, val]) => (
                    <div
                      key={key}
                      className="mt-2.5 flex items-center justify-between text-sm font-light"
                    >
                      <span>{renderSteps(key as UserAccountModelKey)}</span>
                      <span
                        className={classNames(
                          "relative ml-2 flex h-6 w-6 items-center justify-center rounded-full border",
                          "border-primary-700",
                          key === activeStep ? "border-2" : ""
                        )}
                      >
                        {key === activeStep ? (
                          <span className="absolute z-10 inline-flex h-full w-full animate-ping rounded-full bg-primary-700 opacity-75"></span>
                        ) : null}
                        <span
                          className={classNames(
                            "relative z-20 flex h-full w-full items-center justify-center rounded-full",
                            val === UserAccountModelStatus.COMPLETED
                              ? "bg-primary-700 text-white"
                              : "bg-white text-primary-700"
                          )}
                        >
                          <CheckIcon
                            className={classNames(
                              "h-4 w-4",
                              val === UserAccountModelStatus.COMPLETED
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
                <div>
                  <Button
                    variant="tertiary"
                    className="w-full justify-center"
                    onClick={() => {
                      toggleAccount();
                    }}
                  >
                    Complete Now
                  </Button>
                </div>
              </div>
            ) : null} */}

            <button
              type="button"
              className={classNames(
                "text-black hover:text-primary-700",
                "group flex items-center rounded-lg px-4 py-3 text-base font-normal leading-6 transition-colors"
              )}
              onClick={() => {
                navigate("/logout");
              }}
            >
              <img
                src={IconExit}
                alt={`Icon for Logout`}
                className={classNames(
                  "brightness-0 grayscale-0 invert-0 group-hover:brightness-100 group-hover:grayscale-0 group-hover:invert-0",
                  "mr-4 h-5 w-5 flex-shrink-0 text-center text-xl leading-6 transition"
                )}
                aria-hidden="true"
              />
              <span className="truncate">Logout</span>
            </button>

            <div className="hidden rounded-md border border-gray-200">
              <div className="px-3 pt-6 pb-3 text-center">
                <div
                  className="relative mx-auto mb-3 h-[80px] w-[80px] cursor-pointer p-[4px]"
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  <Avatar className="bg-primary-700 text-2xl font-medium text-white" />
                </div>
                <div
                  className="mb-1 inline-flex cursor-pointer font-medium transition-colors hover:text-primary-700"
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  {currentUser?.customerName}
                </div>
                <div className="mb-4 text-xs font-light text-gray-500">
                  {currentType === 1 ? "Company" : "Individual"}
                </div>
                <div className="flex justify-center border-t border-gray-200 pt-3">
                  <button
                    type="button"
                    className="group flex w-full items-center justify-center rounded-md bg-primary-700 px-4 py-2 text-sm font-light leading-6 text-white transition hover:bg-primary-600"
                    onClick={() => {
                      navigate("/logout");
                    }}
                  >
                    <span
                      className="bi bi-box-arrow-left mr-2 text-xl"
                      aria-hidden="true"
                    ></span>
                    <span className="truncate">Logout</span>
                  </button>

                  {/* <Button
                        variant="secondary"
                        className="w-full justify-center border-gray-900 px-0 text-gray-900 hover:border-gray-700 hover:text-gray-700"
                        onClick={() => {
                          navigate("/account");
                        }}
                      >
                        Account Settings
                      </Button> */}
                </div>
                {/* <Menu as="div" className="relative z-20">
                      <Menu.Button className="group block w-full flex-shrink-0 rounded-md bg-gray-900 py-2.5 text-sm font-medium text-primary-100 transition hover:bg-gray-700">
                        <span>Account Settings</span>
                        <span className="sr-only">Open user menu </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute left-0 bottom-14 w-36 origin-bottom-right rounded-tl-2xl rounded-br-2xl rounded-tr-2xl bg-white px-1.5 py-2 text-left shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:w-44 md:px-2.5 md:py-3"
                          static
                        >
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="16px"
                            height="16px"
                            viewBox="0 0 137 137"
                            enableBackground="new 0 0 137 137"
                            className="absolute -bottom-4 left-0 -z-0"
                            fill="#ffffff"
                            style={{
                              filter:
                                "drop-shadow(0px 1px 1px rgba(0,0,0,0.15))",
                            }}
                          >
                            <polygon points="0,137 0,0 137,0 " />
                          </svg>
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.href}>
                              {({ active }) => (
                                <div className="py-0.5">
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active
                                        ? "bg-primary-700/10 text-primary-700"
                                        : "",
                                      "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        "mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                                        item.icon
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="flex-1 truncate">
                                      {item.name}
                                    </span>
                                  </Link>
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
