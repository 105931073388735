import { Link } from "react-router-dom";
import { ErrorDebug } from "./";

const { REACT_APP_DEBUG } = process.env;

export function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="flex flex-col justify-center h-screen">
      <div className="text-center">
        <p className="text-base font-medium text-primary-600">Oops</p>
        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Sorry about that ...
        </h1>
        <p className="mt-2 text-base text-gray-500">
          Something went wrong. Please try again later.
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="text-base font-medium text-primary-600 hover:text-primary-500"
          >
            Go back home <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
      {error && REACT_APP_DEBUG === "true" && (
        <ErrorDebug
          error={error}
          className="mx-auto mt-10 w-full max-w-3xl md:mt-16"
        />
      )}
    </div>
  );
}
