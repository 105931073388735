import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/form";
import { Modal, Row } from "../../../components/order";
import { type Order } from "../../../graphql/order";

export default function OrderSection({ orders }: { orders: Order[] }) {
  const { t } = useTranslation();
  const [viewOrder, setVewOrder] = useState<Order | undefined>(undefined);

  if (orders.length === 0) return null;
  const filteredOrders = orders.length > 5 ? orders.slice(0, 5) : orders;

  return (
    <>
      <section className="mt-6 md:mt-8">
        <header className="mb-4 flex items-end justify-between">
          <h3 className="text-xl font-medium">{t("text_recent_order")}</h3>
          <div>
            <Button type="link" href="/account/orders" variant="secondary">
              {t("text_view_all")}
            </Button>
          </div>
        </header>
        <div className="flow-root rounded-2xl border border-gray-200 overflow-hidden">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("text_order_number")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("text_order_date")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("text_order_total")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("text_order_status")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("text_order_destination")}
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">{t("text_edit")}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((order: Order, index: number) => (
                    <Row
                      key={order.orderNumber}
                      order={order}
                      index={index}
                      setOrder={setVewOrder}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Modal order={viewOrder} setOrder={setVewOrder} />
    </>
  );
}
