import IconHome from "../assets/icon-home.svg";
import IconCategory from "../assets/icon-category.svg";
import IconOrder from "../assets/icon-order.svg";
import IconSpecial from "../assets/icon-special.svg";

export const pages: IPage[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: IconHome,
  },
  {
    name: "Shop",
    path: "/shop",
    icon: IconCategory,
  },
  {
    name: "Special",
    path: "/special",
    icon: IconSpecial,
  },
  {
    name: "Orders",
    path: "/account/orders",
    icon: IconOrder,
  },
];
