import { TrashIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Accept, FileWithPath, useDropzone } from "react-dropzone";

import { getToken } from "../../modules/auth";
import { Loader } from "../../animations";
import { useNotifyContext, NotifyType } from "../../contexts/NotifyContext";

const { REACT_APP_GRAPHQL_URL } = process.env;

type FieldUploaderProps = {
  title: string;
  name: string;
  id?: string;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  accept?: Accept;
  directory: string;
  onChange: (files: string | string[]) => void;
  value?: string | string[];
  touched?: boolean | undefined;
  errors?: string | undefined;
  label?: boolean;
};

type FileWithPathWithPreview = FileWithPath & { url: string; preview: string };

export function FieldUploader(props: FieldUploaderProps) {
  const {
    title,
    id,
    name,
    minSize = 0,
    maxSize = 5,
    maxFiles = 1,
    multiple,
    accept = {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
    },
    directory,
    onChange,
    label = true,
    value,
    touched,
    errors,
  } = props;

  const { addNotify } = useNotifyContext();

  const [loading, setLoading] = useState(false);
  const initialLoad = useRef(true);
  const [files, setFiles] = useState<FileWithPathWithPreview[]>([]);
  const values = useMemo(() => {
    if (!value) return [];
    if (value === "") return [];
    if (typeof value === "string") return [value];
    return value || [];
  }, [value]);

  const loadFile = useCallback(
    (url: string, blob: Blob, name: string, type: string) => {
      let file = new File([blob], name, { type: type });
      file = Object.assign(file, {
        url,
        preview: URL.createObjectURL(file),
      });
      return file as FileWithPathWithPreview;
    },
    []
  );

  useEffect(() => {
    if (values.length === 0) return;
    if (!initialLoad.current) return;
    (async () => {
      setFiles([]);
      const loadedFiles: FileWithPathWithPreview[] = [];
      for (const v of values) {
        try {
          await fetch(v).then(async (r) => {
            const blob = await r.blob();
            const name = new URL(r.url).pathname.split("/").pop() || "";
            const newFile = loadFile(
              v,
              blob,
              name,
              r.headers.get("Content-Type") || ""
            );
            loadedFiles.push(newFile);
          });
        } catch (e: any) {
          console.error(e.message);
        }
      }
      setFiles(loadedFiles);
      initialLoad.current = false;
    })();
  }, [loadFile, values]);

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      setLoading(true);
      const uploadedFiles = [...values];
      for (const file of acceptedFiles) {
        try {
          let formData = new FormData();
          formData.append("path", directory);
          formData.append("image", file);
          const token = await getToken();
          const response = await fetch(
            `${REACT_APP_GRAPHQL_URL}/image/upload`,
            {
              method: "POST",
              headers: {
                authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );
          const data = await response.json();

          if (!data.url) throw new Error("No url returned from server.");

          if ((maxFiles && maxFiles > 1) || multiple) {
            uploadedFiles.push(data.url);
            setFiles((files) => [
              ...files,
              Object.assign(file, {
                url: data.url,
                preview: URL.createObjectURL(file),
              }),
            ]);
          } else {
            uploadedFiles[0] = data.url;
            setFiles([
              Object.assign(file, {
                url: data.url,
                preview: URL.createObjectURL(file),
              }),
            ]);
          }
        } catch (e: any) {
          addNotify({
            type: NotifyType.ERROR,
            title: e.message,
          });
        } finally {
          setLoading(false);
        }
      }

      onChange(
        (maxFiles && maxFiles > 1) || multiple
          ? uploadedFiles
          : uploadedFiles[0]
      );
    },
    [directory, maxFiles, multiple, onChange, values]
  );

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles,
      minSize,
      maxSize: 1024 * 1024 * maxSize,
      accept,
      multiple: maxFiles && maxFiles > 1 ? true : multiple ? true : false,
      onDrop,
    });

  const extensions = Object.values(accept).flat().join(", ");

  return (
    <>
      <Loader loading={loading} />
      <label
        htmlFor={id ? id : name}
        className={`block text-base font-medium text-black ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      <div
        {...getRootProps({
          className:
            "w-full flex justify-center items-center px-6 h-42 md:h-44 bg-white border border-gray-300 border-dashed rounded-md relative overflow-hidden hover:border-gray-400 hover:bg-gray-50 cursor-pointer",
        })}
      >
        <input id="resume" {...getInputProps()} />
        {files.length === 0 || (files.length && maxFiles > 1) ? (
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              {isDragActive ? (
                <p className="pl-1">Drop the files here ...</p>
              ) : (
                <p className="pl-1">
                  Drag 'n' drop some files here, or click to{" "}
                  <span className="relative cursor-pointer rounded-md font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500">
                    select files
                  </span>
                </p>
              )}
            </div>
          </div>
        ) : (
          files.map(
            (
              file: { name: string; size: number; preview?: string },
              index: number
            ) => (
              <div key={index} className="absolute h-full w-full">
                <img
                  src={file.preview}
                  alt={file.name}
                  className="h-full w-full object-cover"
                />
              </div>
            )
          )
        )}
      </div>
      <p className="mt-2 space-x-4 text-sm text-gray-500">
        <span>Accepted File Types: {extensions} only.</span>
        <span>Max File Size: {maxSize}MB.</span>
        {maxFiles > 1 && <span>Max Files: {maxFiles}.</span>}
      </p>

      {touched && errors ? (
        <p className="mt-2 text-sm text-red-600" id="image-errors">
          {errors}
        </p>
      ) : null}

      {fileRejections && (
        <ul className="divide-y divide-gray-200 text-red-600">
          {fileRejections.map(
            ({
              file,
              errors,
            }: {
              file: FileWithPath;
              errors: {
                code: string;
                message: string;
              }[];
            }) => (
              <li
                key={file.path}
                className="relative flex items-center py-2 text-sm md:py-4"
              >
                <span className="relative inline-flex h-10 w-12 items-center justify-center overflow-hidden rounded-md border border-gray-200 bg-white text-gray-600">
                  <span className="bi bi-file-earmark-image-fill text-lg"></span>
                </span>
                <span className="ml-2 mr-4 font-medium text-gray-800">
                  {file.path}
                </span>
                <ul>
                  {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                  ))}
                </ul>

                <div className="ml-auto inline-flex items-center">
                  <ExclamationTriangleIcon
                    aria-hidden="true"
                    className="h-6 w-6"
                  />
                </div>
              </li>
            )
          )}
        </ul>
      )}

      {maxFiles > 1 && files.length ? (
        <ul className="divide-y divide-gray-200">
          {files.map(
            (
              file: {
                url: string;
                name: string;
                size: number;
                preview?: string;
              },
              index: number
            ) => (
              <li
                key={file.name + index}
                className="relative flex flex-wrap items-center py-2 text-sm md:py-4"
              >
                <span className="relative inline-block h-10 w-12 overflow-hidden rounded-md border border-gray-200 bg-white">
                  <img
                    className="absolute h-full w-full object-cover"
                    src={file.preview}
                    alt={file.name}
                  />
                </span>
                <p className="pointer-events-none ml-2 mb-0 block truncate font-medium text-gray-800">
                  {file.name}
                </p>

                <div className="ml-auto inline-flex items-center">
                  <p className="pointer-events-none mb-0 mr-3 block text-gray-500">
                    {(file.size / (1024 * 1024)).toFixed(2)}MB
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      setLoading(true);
                      const path = file.url.split(".com/alphafresh/")[1];
                      if (!path) return;
                      let formData = new FormData();
                      formData.append("path", path);

                      fetch(`${REACT_APP_GRAPHQL_URL}/image/delete`, {
                        method: "POST",
                        body: formData,
                      })
                        .then((res) => res.json())
                        .then((data) => {
                          if (data.status) return;
                          console.log(data);
                        })
                        .catch((error) => {
                          addNotify({
                            type: NotifyType.ERROR,
                            message: error.message,
                          });
                        })
                        .finally(() => {
                          const newFiles = files.filter((_, i) => i !== index);
                          setFiles(newFiles);
                          const newUrls = newFiles.map((f) => f.url);
                          onChange(
                            (maxFiles && maxFiles > 1) || multiple
                              ? newUrls
                              : newUrls[0]
                          );
                          setLoading(false);
                        });
                    }}
                    className="appearance-none text-gray-900"
                  >
                    <TrashIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </li>
            )
          )}
        </ul>
      ) : null}
    </>
  );
}
