import { useMemo } from "react";
import { useAuth } from "../../modules/auth";
import { classNames } from "../../utils";
import { CustomerType } from "../../modules/auth/core";
export function Avatar({
  className,
  textClass,
}: {
  className?: string;
  textClass?: string;
}) {
  const { currentUser } = useAuth();

  const userLetter = useMemo(() => {
    if (!currentUser) return "U";
    let userLetter = "";
    const { firstName, lastName, customerName, companyName, customerType } =
      currentUser;
    if (customerType === CustomerType.INDIVIDUAL) {
      if (firstName && lastName) {
        userLetter = `${firstName.charAt(0)}${lastName.charAt(0)}`;
      } else if (firstName) {
        userLetter = firstName.charAt(0);
      } else if (lastName) {
        userLetter = lastName.charAt(0);
      } else if (customerName) {
        userLetter = customerName.charAt(0);
      } else {
        userLetter = "U";
      }
      return userLetter;
    } else {
      if (companyName) {
        userLetter = companyName.charAt(0);
      } else {
        userLetter = "U";
      }
      return userLetter;
    }
  }, [currentUser]);

  return (
    <div
      className={classNames(
        "pb-100 relative block items-center justify-center overflow-hidden rounded-full",
        className ? className : "bg-primary-700"
      )}
    >
      {currentUser?.profileImageUrl ? (
        <img
          className="absolute top-0 left-0 h-full w-full object-cover"
          src={currentUser.profileImageUrl}
          alt={currentUser.customerName}
        />
      ) : (
        <span
          className={classNames(
            "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 leading-none",
            textClass ?? ""
          )}
        >
          {userLetter}
        </span>
      )}
    </div>
  );
}
