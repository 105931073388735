import { useCallback, useEffect, useMemo, useRef } from "react";
import { useQuery } from "@apollo/client/react";
import HeaderLayout from "../../layouts/HeaderLayout";
import { useAuth } from "./../auth";
import { useAccount } from "../../contexts/GenContext";
import { UserAccountModelKey } from "../auth/core";
import { ErrorFallback, Head } from "../../components/core";
import { PageTitle } from "../../components/page";
import FeaturedBlock, { FeaturedType } from "./components/FeaturedBlock";
import WelcomeBlock from "./components/WelcomeBlock";

import OrderSection from "./components/OrderSection";
import { FETCH_STOREHOME } from "../../graphql/home";
import SpecialSection from "./components/SpecialSection";
import RecentProducts from "./components/RecentProducts";
import { Waiting } from "../../animations";

export const DashboardPage = () => {
  const { currentUser } = useAuth();
  const { activeStep, toggleAccount } = useAccount();
  const didMount = useRef(false);

  const showAccount = useCallback(() => {
    if (
      currentUser?.customerType === 1 &&
      activeStep === UserAccountModelKey.VENUE_TYPE
    ) {
      toggleAccount();
    }
  }, [activeStep, currentUser?.customerType, toggleAccount]);

  useEffect(() => {
    if (!didMount.current) {
      showAccount();
      didMount.current = true;
    }
  }, [showAccount]);

  const { data, error, loading } = useQuery(FETCH_STOREHOME, {
    variables: {
      deviceType: 2,
    },
  });

  const {
    banner,
    recentProducts,
    recentOrders,
    specialProducts,
    mostProducts,
    latestProducts,
  } = useMemo(() => {
    if (!data?.fetchIndex)
      return {
        banner: null,
        recentProducts: [],
        recentOrders: [],
        specialProducts: [],
        mostProducts: [],
        latestProducts: [],
      };
    const home = data.fetchIndex;
    const banner = home.webBanners;
    const recentProducts = home.recentPurchasedProducts || [];
    const recentOrders = home.recentOrders || [];
    const specialProducts = home.specials || [];
    const mostProducts = home.mostPurchasedProducts || [];
    const latestProducts = home.latestProducts || [];

    return {
      banner,
      recentProducts,
      recentOrders,
      specialProducts,
      mostProducts,
      latestProducts,
    };
  }, [data]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <div className="w-prev-container w-full pb-10 3xl:px-8">
      <Head title={DashboardResource.name} />
      <HeaderLayout>
        <PageTitle>{DashboardResource.name}</PageTitle>
      </HeaderLayout>
      <div className="px-4 md:mr-4 md:px-8">
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
          <div className="mt-6 xl:col-span-8">
            {loading ? (
              <Waiting />
            ) : banner ? (
              <WelcomeBlock
                title={banner.title}
                caption={banner.caption}
                button={banner.button}
              />
            ) : (
              <WelcomeBlock
                title="Store setup is in progress.."
                caption="
                The store is not ready to sell products. Please come back later. 
                If you are a owner of this store, please complete your store setup before selling products."
                button={[]}
                setup={true}
              />
            )}
          </div>
          <div className="mt-6 xl:col-span-4 xl:flex">
            <FeaturedBlock
              type={
                recentProducts.length > 0
                  ? FeaturedType.RECENT_PURCHASES
                  : FeaturedType.MOST_PURCHASES
              }
              products={
                recentProducts.length > 0 ? recentProducts : mostProducts
              }
            />
          </div>
        </div>
        <SpecialSection products={specialProducts} />
        {!specialProducts.length || !recentOrders.length ? (
          <RecentProducts
            products={latestProducts}
            count={!specialProducts.length && !recentOrders.length ? 6 : 3}
          />
        ) : null}
        <OrderSection orders={recentOrders} />
      </div>
    </div>
  );
};

export const DashboardResource: ResourceProps = {
  name: "Dashboard",
  path: "/dashboard",
};
