import { Outlet } from "react-router-dom";
import LogRocket from "logrocket";
import Providers from "./contexts/Providers";
import { AuthInit } from "./modules/auth";

import { ScrollToTop } from "./components/presentation";

function App() {
  // useEffect(() => {
  //   LogRocket.init("i5w0za/alpha-fresh");
  // }, []);
  return (
    <>
      <ScrollToTop />
      <Providers>
        <AuthInit>
            <Outlet />
        </AuthInit>
      </Providers>
    </>
  );
}

export default App;
