import { CheckCircleIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";
import { useAccount } from "../../../contexts/GenContext";
import { ProgressRadial } from "../../../animations";
import { Avatar } from "../../../components/presentation";
import { useAuth } from "../../auth";
import { Button } from "../../../components/form";
import { classNames } from "../../../utils";
import { Fragment } from "react";

const Div = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before {
    width: 70%;
    height: 150%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      275deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 80%,
      rgba(0, 0, 0, 0.1) 100%
    );
    transform: rotate(45deg);
    content: "";
    position: absolute;
    top: -50%;
    right: -24%;
    z-index: 0;
  }
`;

type WelcomeProps = {
  title: string;
  caption: string;
  button: {
    title: string;
    link: string;
  }[];
  setup?: boolean;
};
export default function WelcomeBlock(props: WelcomeProps) {
  const { title, caption, button, setup = false } = props;
  const { percentage, toggleAccount, isVerified } = useAccount();
  const { currentUser } = useAuth();

  return (
    <Div className="items-start rounded-2xl bg-primary-100 p-4 text-black sm:flex md:block md:p-6 xl:p-8">
      <div className="relative mb-3 h-24 w-24 min-w-[6rem] p-[5px] sm:absolute sm:mb-0 sm:mr-3 md:right-10 md:top-10 md:mr-0 md:h-36 md:w-36 xl:right-20">
        <ProgressRadial
          progress={currentUser?.customerType === 1 ? percentage : 100}
          indicatorWidth={8}
          trackWidth={8}
          indicatorColor="#4ba84f"
          trackColor="#86c395"
          className="absolute top-0 left-0 z-10"
        />
        <Avatar
          className="bg-transparent"
          textClass="text-6xl font-semibold first-letter:text-gray-900/95 text-gray-900/90"
        />
        {currentUser?.customerType === 1 && isVerified() ? (
          <CheckCircleIcon
            className={classNames(
              "absolute right-4 top-4 z-10 h-6 w-6 flex-shrink-0 rounded-full bg-primary-100 text-primary"
            )}
            aria-hidden="true"
          />
        ) : null}
      </div>

      <div className="max-w-sm space-y-4">
        <h2 className="text-xl font-semibold md:text-2xl">
          {setup && (
            <Fragment>
              Hello <span>{currentUser?.customerName},</span>
              <br />
            </Fragment>
          )}
          {title ? title : "Hello again,"}
          {!setup && (
            <Fragment>
              <br />
              <span>{currentUser?.customerName}</span>
            </Fragment>
          )}
        </h2>
        <p className="text-sm leading-snug">
          {caption
            ? caption
            : "Alpha is your digital fresh produce market, allowing you to do all of your fresh produce shopping from the comfort of your couch."}
        </p>
        {currentUser?.customerType === 1 && percentage < 100 ? (
          <p className="text-sm leading-snug">
            Your account is at {percentage}% completion. Please complete your
            profile.
          </p>
        ) : null}

        <div className="flex flex-wrap">
          {button?.map((btn) => {
            if (btn.title === "") return null;
            return (
              <Button
                key={btn.link}
                type="link"
                href={btn.link ? btn.link : "/shop"}
                variant="tertiary"
                className="mr-2 inline-flex"
              >
                {btn.title ? btn.title : "Shop Now"}
              </Button>
            );
          })}
          {currentUser?.customerType === 1 && percentage < 100 ? (
            <Button
              variant="warning"
              onClick={() => {
                toggleAccount();
              }}
            >
              Complete Profile
            </Button>
          ) : null}
        </div>
      </div>
    </Div>
  );
}
