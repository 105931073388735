import NotifyProvider from "./NotifyContext";
import GenProvider from "./GenContext";
import { SplashScreenProvider } from "../components/core";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <SplashScreenProvider>
      <GenProvider>
        <NotifyProvider>{children}</NotifyProvider>
      </GenProvider>
    </SplashScreenProvider>
  );
};

export default Providers;
