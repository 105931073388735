import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/solid";

import { Button } from "../form";
import placeholder from "../../assets/placeholder.svg";
import { classNames } from "../../utils";
import { useNotifyContext, NotifyType } from "../../contexts/NotifyContext";
import { Addtocart, SpinnerInline } from "../../animations";
import { Swatch, Price, Timer } from "./";
import {
  type Product,
  ProductAction,
  ProductVariantQty,
} from "../../graphql/shop";
import { useCart } from "../../modules/cart/core/CartProvider";

const Div = styled.div`
  .label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(107, 114, 128);
  }
`;

export function ProductCard({
  archive,
  product,
}: {
  archive: string;
  product: Product;
}) {
  const { addNotify } = useNotifyContext();
  const { cartItems, findCart, addCart, updateCart, removeCart } = useCart();
  const [loading, setLoading] = useState(false);
  const firstVariant = product.variants[0];
  const [currentVariant, setCurrentVariant] = useState<ProductVariantQty>({
    ...firstVariant,
    quantity: 0,
  });

  useEffect(() => {
    const cartVariant = findCart(currentVariant.id);
    if (cartVariant) {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: cartVariant.quantity,
      }));
    } else {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: 0,
      }));
    }
  }, [cartItems, currentVariant.id, findCart]);

  // const handleAddToCart = useCallback(() => {
  //   setLoading(true);
  //   addCart(product, currentVariant, currentVariant.minimumQuantity || 1, true)
  //     .then(() => {
  //       addNotify({
  //         type: NotifyType.SUCCESS,
  //         title: "Added to cart",
  //       });
  //     })
  //     .catch((error: { message: string }) => {
  //       console.log(error);
  //       addNotify({
  //         type: NotifyType.ERROR,
  //         title: "Failed to add to cart",
  //         message: error.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [addCart, addNotify, currentVariant, product]);

  const handleAddOrIncrease = useCallback(() => {
    setLoading(true);
    const cartVariant = findCart(currentVariant.id);
    if (!cartVariant) {
      addCart(
        product,
        currentVariant,
        currentVariant.minimumQuantity || 1,
        true
      )
        .then(() => {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Added to cart",
          });
        })
        .catch((error: { message: string }) => {
          console.log(error);
          addNotify({
            type: NotifyType.ERROR,
            title: "Failed to add to cart",
            message: error.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    if (currentVariant.quantity >= currentVariant.inventory) {
      addNotify({
        type: NotifyType.ERROR,
        title: "Maximum quantity reached",
      });
      setLoading(false);
      return;
    }
    updateCart(currentVariant.id, currentVariant.quantity + 1)
      .catch((error: { message: string }) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to update cart",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addCart, addNotify, currentVariant, findCart, product, updateCart]);

  // const handleRemoveFromCart = useCallback(() => {
  //   setLoading(true);
  //   removeCart(currentVariant.id)
  //     .catch((error: { message: string }) => {
  //       addNotify({
  //         type: NotifyType.ERROR,
  //         title: "Failed to update cart",
  //         message: error.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [currentVariant.id, removeCart, addNotify]);

  const handleUpdate = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      action: ProductAction
    ) => {
      e.preventDefault();
      setLoading(true);

      const cartVariant = findCart(currentVariant.id);
      if (!cartVariant) {
        addCart(
          product,
          currentVariant,
          currentVariant.minimumQuantity || 1,
          true
        )
          .then(() => {
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Added to cart",
            });
          })
          .catch((error: { message: string }) => {
            console.log(error);
            addNotify({
              type: NotifyType.ERROR,
              title: "Failed to add to cart",
              message: error.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
        return;
      }

      switch (action) {
        case ProductAction.PLUS:
          if (currentVariant.quantity >= currentVariant.inventory) {
            addNotify({
              type: NotifyType.ERROR,
              title: "Maximum quantity reached",
            });
            setLoading(false);
            return;
          }
          updateCart(currentVariant.id, currentVariant.quantity + 1)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case ProductAction.MINUS:
          if (currentVariant.quantity <= currentVariant.minimumQuantity) {
            removeCart(currentVariant.id)
              .catch((error: { message: string }) => {
                addNotify({
                  type: NotifyType.ERROR,
                  title: "Failed to update cart",
                  message: error.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
            return;
          }
          if (currentVariant.quantity === 1) {
            removeCart(currentVariant.id)
              .catch((error: { message: string }) => {
                addNotify({
                  type: NotifyType.ERROR,
                  title: "Failed to update cart",
                  message: error.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
            return;
          }

          updateCart(currentVariant.id, currentVariant.quantity - 1)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }
    },
    [
      addCart,
      addNotify,
      currentVariant,
      findCart,
      product,
      removeCart,
      updateCart,
    ]
  );

  return (
    <Div className="flex flex-wrap space-y-4 rounded-2xl border border-gray-200 px-2 py-4 lg:space-y-0">
      <div className="w-full px-2 sm:w-1/2 md:w-1/3 lg:w-[18%]">
        <Link
          to={`/${archive}/${product.handle}`}
          className="relative z-10 block w-full cursor-pointer appearance-none overflow-hidden rounded-xl pb-[64%] ring-2 ring-white filter transition hover:hue-rotate-15"
        >
          {currentVariant.variantImageUrl ? (
            <img
              className={classNames(
                "absolute inset-0 h-full w-full object-cover"
              )}
              src={currentVariant.variantImageUrl}
              alt={product.name}
            />
          ) : (
            <img
              className={classNames(
                "absolute inset-0 h-full w-full object-cover"
              )}
              src={
                product.featureImageUrl ? product.featureImageUrl : placeholder
              }
              alt={product.name}
            />
          )}
          {loading && (
            <div className="absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2">
              <SpinnerInline className="text-primary-700" />
            </div>
          )}
        </Link>
      </div>
      <div className="w-full space-y-3 px-2 text-sm font-medium leading-tight text-primary-900 sm:w-1/2 md:w-1/3 lg:w-[28%]">
        <div>
          <div className="label">Name</div>
          <Link
            to={`/${archive}/${product.handle}`}
            className="cursor-pointer appearance-none text-left text-base text-primary-900 transition hover:text-primary-800"
          >
            {product.name}
          </Link>
        </div>
        <div>
          <div className="label">Department</div>
          <span>{product.department.name}</span>
        </div>
        {product.categories.length > 0 ? (
          <div>
            <div className="label">Category</div>
            <span>{product.categories.flatMap((c) => c.name).join(", ")}</span>
          </div>
        ) : null}
      </div>
      <div className="w-full px-2 sm:w-1/2 md:w-1/3 lg:w-[18%]">
        <Swatch
          variants={product.variants}
          currentVariant={currentVariant}
          setCurrentVariant={setCurrentVariant}
        />
      </div>
      <div className="w-full px-2 sm:w-1/2 md:w-1/3 lg:w-[18%]">
        <div className="label">Price</div>
        <Price
          currentVariant={currentVariant}
          className="items-end text-2xl font-semibold text-gray-500"
        />
        <Timer price={currentVariant.customerSpecialPrice} />
      </div>
      <div className="w-full space-y-4 px-2 sm:w-1/2 md:w-1/3 lg:w-[18%]">
        <div>
          <div className="flex w-full flex-wrap justify-between">
            <span className="label">Quantity</span>
            {currentVariant.minimumQuantity > 1 ? (
              <span className="text-xs font-normal text-gray-500">
                Mininum qty: {currentVariant.minimumQuantity}
              </span>
            ) : null}
          </div>
          <div className="flex items-center justify-center rounded-lg border border-gray-200 text-center">
            <Button
              variant="icon"
              className="inline-flex h-10 w-10 items-center justify-center bg-transparent transition hover:border-primary-700 hover:text-primary-700"
              onClick={(e) => handleUpdate(e, ProductAction.MINUS)}
              disabled={loading || currentVariant.quantity === 0}
            >
              <MinusSmallIcon aria-hidden="true" className="h-4 w-4" />
            </Button>
            <span className="w-full min-w-[2rem]">
              {currentVariant.quantity}
            </span>
            <Button
              variant="icon"
              className="inline-flex h-10 w-10 items-center justify-center bg-transparent transition hover:border-primary-700 hover:text-primary-700"
              onClick={(e) => handleUpdate(e, ProductAction.PLUS)}
              disabled={loading || currentVariant.inventory === 0}
            >
              <PlusSmallIcon aria-hidden="true" className="h-4 w-4" />
            </Button>
          </div>
        </div>
        {currentVariant.inventory === 0 ? (
          <Button
            variant="warning"
            disabled
            className="w-full justify-center whitespace-nowrap"
          >
            Out of Stock
          </Button>
        ) : (
          <Addtocart
            onClick={handleAddOrIncrease}
            disabled={loading}
            loading={loading}
            count={currentVariant.quantity}
            className="w-full justify-center whitespace-nowrap"
          />
        )}
      </div>
    </Div>
  );
}
