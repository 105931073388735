import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/solid";
import placeholder from "../../assets/placeholder.svg";
import { NotifyType, useNotifyContext } from "../../contexts/NotifyContext";
import { Button } from "../form";
import { SpinnerInline } from "../../animations";
import { useCart } from "../../modules/cart/core/CartProvider";
import { CartAction, CartItem } from "../../graphql/cart";

export function ProductCard({ cartItem }: { cartItem: CartItem }) {
  const [loading, setLoading] = useState(false);
  const { addNotify } = useNotifyContext();
  const { removeCart, updateCart } = useCart();

  const handleUpdate = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      action: CartAction,
      cartItem: CartItem
    ) => {
      e.preventDefault();
      setLoading(true);
      switch (action) {
        case CartAction.PLUS:
          if (cartItem.quantity >= cartItem.inventory) {
            addNotify({
              type: NotifyType.ERROR,
              title: "Maximum quantity reached",
            });
            setLoading(false);
            return;
          }
          updateCart(cartItem.id, cartItem.quantity + 1)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case CartAction.MINUS:
          if (cartItem.quantity <= cartItem.minimumQuantity) {
            addNotify({
              type: NotifyType.ERROR,
              title: "Minimum quantity reached",
            });
            setLoading(false);
            return;
          }
          if (cartItem.quantity === 1) {
            removeCart(cartItem.id)
              .catch((error: { message: string }) => {
                addNotify({
                  type: NotifyType.ERROR,
                  title: "Failed to update cart",
                  message: error.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
            return;
          }

          updateCart(cartItem.id, cartItem.quantity - 1)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case CartAction.REMOVE:
          removeCart(cartItem.id)
            .catch((error: { message: string }) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to update cart",
                message: error.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });

          break;
      }
    },
    [addNotify, removeCart, updateCart]
  );

  return (
    <div className="py-5">
      <div className="grid grid-cols-7 gap-4">
        <div className="col-span-2">
          <Link
            to={`/shop/${cartItem.product.handle}`}
            className="pb-100 relative block w-full overflow-hidden rounded-md border border-gray-200"
          >
            <img
              className="absolute top-0 left-0 h-full w-full object-cover"
              src={
                cartItem.product.featureImageUrl
                  ? cartItem.product.featureImageUrl
                  : placeholder
              }
              alt={cartItem.product.name}
            />
            {loading && (
              <div className="absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2">
                <SpinnerInline className="text-primary-700" />
              </div>
            )}
          </Link>
        </div>
        <div className="col-span-5">
          <div className="grid grid-cols-2 gap-4 sm:h-full">
            <div className="space-y-2">
              <Link
                to={`/shop/${cartItem.product.handle}`}
                className="inline-flex text-sm font-medium transition hover:text-primary-700"
              >
                {cartItem.product.name}
              </Link>
              <div className="text-xs font-light text-gray-700">
                {cartItem.variantTitle?.map((v) => v.name).join(" / ")}
              </div>
            </div>
            <div className="flex flex-col justify-between space-y-2">
              <div className="text-right text-sm font-normal text-gray-700">
                ${cartItem.customerPrice?.toFixed(2)}
              </div>

              <div className="flex items-center justify-end text-center">
                <Button
                  variant="icon"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-md border border-gray-200 bg-transparent transition hover:border-primary-700 hover:text-primary-700"
                  onClick={(e) => {
                    handleUpdate(e, CartAction.MINUS, cartItem);
                  }}
                  disabled={loading}
                >
                  <MinusSmallIcon aria-hidden="true" className="h-3 w-3" />
                </Button>
                <span className="min-w-[2rem] font-light">
                  {cartItem.quantity}
                </span>
                <Button
                  variant="icon"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-md border border-gray-200 bg-transparent transition hover:border-primary-700 hover:text-primary-700"
                  onClick={(e) => {
                    handleUpdate(e, CartAction.PLUS, cartItem);
                  }}
                  disabled={loading}
                >
                  <PlusSmallIcon aria-hidden="true" className="h-4 w-4" />
                </Button>
              </div>

              <div className="flex justify-end">
                <Button
                  variant="text"
                  className="text-xs font-light text-blue-500 transition hover:text-blue-700"
                  onClick={(e) => {
                    handleUpdate(e, CartAction.REMOVE, cartItem);
                  }}
                  disabled={loading}
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
