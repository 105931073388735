import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import FormPassword from "./components/FormPassword";
import { Head } from "../../../components/core";
import { PageTitle } from "../../../components/page";

const UPDATE_CUSTOMER_PASSWORD = gql`
  mutation CustomerPasswordUpdate($password: String!, $newPassword: String!) {
    customerPasswordUpdate(
      input: { params: { password: $password, newPassword: $newPassword } }
    ) {
      customer {
        customerName
      }
    }
  }
`;

type IAccountPassword = {
  "current-password": string;
  "new-password": string;
  "confirm-password": string;
};

export default function PasswordPage() {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const [customerPasswordUpdate] = useMutation(UPDATE_CUSTOMER_PASSWORD);

  const handleSubmitPassword = (
    values: IAccountPassword,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    customerPasswordUpdate({
      variables: {
        password: values["current-password"],
        newPassword: values["new-password"],
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerPasswordUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Password updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Password update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Password update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head title={PasswordResource.name} />
      <div className="w-full w-prev-container flex h-full flex-col items-center justify-center">
        <div className="w-full max-w-md rounded-2xl bg-greyish px-4 py-6 md:px-6 md:py-12">
          <header className="mb-4 text-center md:mb-8">
            <h1 className="text-md font-medium capitalize leading-7 text-primary-900 sm:text-lg sm:tracking-tight md:text-xl lg:text-2xl">
              {PasswordResource.name}
            </h1>
            <p className="text-sm text-gray-500">Update your password</p>
          </header>
          <FormPassword
            initialValues={{
              "current-password": "",
              "new-password": "",
              "confirm-password": "",
            }}
            onSubmit={handleSubmitPassword}
          />
        </div>
      </div>
    </>
  );
}

export const PasswordResource: ResourceProps = {
  name: "Change Password",
  path: "password",
};
