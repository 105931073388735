import { useState } from "react";
import { useQuery } from "@apollo/client/react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../components/core";
import { Logo } from "../../../animations";
import { PageTitle } from "../../../components/page";
import { FETCH_ORDERS, type Order } from "../../../graphql/order";
import { Modal, Row } from "../../../components/order";

export default function OrderPage() {
  const { t } = useTranslation();
  const [viewOrder, setVewOrder] = useState<Order | undefined>(undefined);

  const { data, loading, error } = useQuery(FETCH_ORDERS);
  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head title={OrdersResource.name} />
      <div className="w-full w-prev-container pb-10">
        <header className="mb-4 md:mb-8">
          <PageTitle className="text-primary-900">
            {OrdersResource.name}
          </PageTitle>
          <p className="text-sm text-gray-500">
            View your order history and details.
          </p>
        </header>
        {loading ? (
          <div className="py-10 md:py-16">
            <div className="pointer-events-none text-center opacity-20">
              <Logo />
            </div>
          </div>
        ) : (
          <div className="flow-root rounded-2xl border border-gray-200 overflow-hidden">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t("text_order_number")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("text_order_date")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("text_order_total")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("text_order_status")}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t("text_order_destination")}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">{t("text_edit")}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.me?.orders.map((order: Order, index: number) => (
                      <Row
                        key={order.orderNumber}
                        order={order}
                        index={index}
                        setOrder={setVewOrder}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal order={viewOrder} setOrder={setVewOrder} />
    </>
  );
}

export const OrdersResource: ResourceProps = {
  name: "Orders",
  path: "orders",
};
