import { Link } from "react-router-dom";
import placeholder from "../../../assets/placeholder.svg";
import { CartItem } from "../../../graphql/cart";
import { useTranslation } from "react-i18next";
import { Button, Field } from "../../../components/form";
import { useState } from "react";

export function Product({
  cartItem,
  orderComments,
  updateComments,
}: {
  cartItem: CartItem;
  orderComments: string;
  updateComments: (value: string) => void;
}) {
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState(false);

  return (
    <div className="py-5">
      <div className="grid grid-cols-8 gap-4">
        <div className="col-span-2">
          <Link
            to={`/shop/${cartItem.product.handle}`}
            className="relative block w-full"
          >
            <figure className="pb-100 relative block w-full overflow-hidden rounded-md border border-gray-200">
              <img
                className="absolute top-0 left-0 h-full w-full object-cover"
                src={
                  cartItem.product.featureImageUrl
                    ? cartItem.product.featureImageUrl
                    : placeholder
                }
                alt={cartItem.product.name}
              />
            </figure>
            <span className="absolute -top-2 -right-1 z-10 min-h-[18px] min-w-[18px] rounded-full bg-primary-700 text-center text-[10px] leading-[18px] text-white">
              {cartItem.quantity}
            </span>
          </Link>
        </div>
        <div className="col-span-6">
          <div className="grid grid-cols-2 gap-x-4">
            <div className="space-y-2">
              <Link
                to={`/shop/${cartItem.product.handle}`}
                className="inline-flex text-sm font-medium transition hover:text-primary-700"
              >
                {cartItem.product.name}
              </Link>
              <div className="text-xs font-light text-gray-700">
                {cartItem.variantTitle?.map((v) => v.name).join(" / ")}
              </div>
            </div>
            <div className="space-y-2">
              <div className="text-right text-base font-normal text-gray-700">
                ${(cartItem.quantity * cartItem.customerPrice).toFixed(2)}
              </div>
            </div>
            <div className="col-span-2">
              {showComments ? (
                <div className="mt-1">
                  <span className="inline-flex text-xs font-medium">
                    {t("text_order_comments")}
                  </span>
                  <Field
                    title={t("text_order_comments")}
                    type="textarea"
                    name="orderComments"
                    onChange={(e) => updateComments(e.target.value)}
                    value={orderComments}
                    label={false}
                    placeholder="Add your comments here"
                    className="md:h-12 md:text-xs"
                  />
                </div>
              ) : (
                <div className="flex justify-end">
                  <Button
                    variant="text"
                    className="inline-flex text-xs font-medium text-blue-900 hover:underline"
                    onClick={() => setShowComments(true)}
                  >
                    {orderComments ? "Edit Comment" : "Add Comment"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
