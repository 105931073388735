import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Addtocart, SpinnerInline } from "../../../animations";
import { Button } from "../../../components/form";
import { Price, Timer } from "../../../components/product";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { ProductVariantQty, type Product } from "../../../graphql/shop";
import { classNames } from "../../../utils";
import placeholder from "../../../assets/placeholder.svg";
import { useCart } from "../../cart/core/CartProvider";

export default function SpecialCard({ product }: { product: Product }) {
  const { addNotify } = useNotifyContext();
  const { cartItems, findCart, addCart, updateCart } = useCart();
  const [loading, setLoading] = useState(false);
  const firstVariant = product.variants[0];
  const [currentVariant, setCurrentVariant] = useState<ProductVariantQty>({
    ...firstVariant,
    quantity: 0,
  });

  useEffect(() => {
    const cartVariant = findCart(currentVariant.id);
    if (cartVariant) {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: cartVariant.quantity,
      }));
    } else {
      setCurrentVariant((prev) => ({
        ...prev,
        quantity: 0,
      }));
    }
  }, [cartItems, currentVariant.id, findCart]);

  // const handleAddToCart = useCallback(() => {
  //   setLoading(true);
  //   addCart(product, currentVariant, currentVariant.minimumQuantity || 1, true)
  //     .then(() => {
  //       addNotify({
  //         type: NotifyType.SUCCESS,
  //         title: "Added to cart",
  //       });
  //     })
  //     .catch((error: { message: string }) => {
  //       console.log(error);
  //       addNotify({
  //         type: NotifyType.ERROR,
  //         title: "Failed to add to cart",
  //         message: error.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [addCart, addNotify, currentVariant, product]);

  const handleAddOrIncrease = useCallback(() => {
    setLoading(true);
    const cartVariant = findCart(currentVariant.id);
    if (!cartVariant) {
      addCart(
        product,
        currentVariant,
        currentVariant.minimumQuantity || 1,
        true
      )
        .then(() => {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Added to cart",
          });
        })
        .catch((error: { message: string }) => {
          console.log(error);
          addNotify({
            type: NotifyType.ERROR,
            title: "Failed to add to cart",
            message: error.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    if (currentVariant.quantity >= currentVariant.inventory) {
      addNotify({
        type: NotifyType.ERROR,
        title: "Maximum quantity reached",
      });
      setLoading(false);
      return;
    }
    updateCart(currentVariant.id, currentVariant.quantity + 1)
      .catch((error: { message: string }) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to update cart",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addCart, addNotify, currentVariant, findCart, product, updateCart]);

  // const handleRemoveFromCart = useCallback(() => {
  //   setLoading(true);
  //   removeCart(currentVariant.id)
  //     .catch((error: { message: string }) => {
  //       addNotify({
  //         type: NotifyType.ERROR,
  //         title: "Failed to update cart",
  //         message: error.message,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [currentVariant.id, removeCart, addNotify]);

  return (
    <div className="grid grid-cols-1 gap-4 rounded-2xl border border-gray-200 p-3 md:grid-cols-2 lg:grid-cols-2">
      <div>
        <Link
          to={`/special/${product.handle}`}
          className="relative z-10 block w-full cursor-pointer appearance-none overflow-hidden rounded-xl pb-[100%] ring-2 ring-white filter transition hover:hue-rotate-15"
        >
          {currentVariant.variantImageUrl ? (
            <img
              className={classNames(
                "absolute inset-0 h-full w-full object-cover"
              )}
              src={currentVariant.variantImageUrl}
              alt={product.name}
            />
          ) : (
            <img
              className={classNames(
                "absolute inset-0 h-full w-full object-cover"
              )}
              src={
                product.featureImageUrl ? product.featureImageUrl : placeholder
              }
              alt={product.name}
            />
          )}
          {loading && (
            <div className="absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2">
              <SpinnerInline className="text-primary-700" />
            </div>
          )}
        </Link>
      </div>

      <div className="flex flex-col">
        <h3 className="text-md mb-auto font-semibold">
          <Link
            to={`/special/${product.handle}`}
            className="cursor-pointer appearance-none text-left text-primary-900 transition hover:text-primary-800"
          >
            {product.name}
          </Link>
        </h3>

        <Price
          currentVariant={currentVariant}
          className="mb-1 text-2xl font-semibold text-gray-500"
        />
        <Timer price={currentVariant.customerSpecialPrice} />

        <div>
          {currentVariant.inventory === 0 ? (
            <Button
              variant="warning"
              className="mt-2 w-full justify-center"
              disabled
            >
              Out of Stock
            </Button>
          ) : (
            <Addtocart
              onClick={handleAddOrIncrease}
              disabled={loading}
              loading={loading}
              count={currentVariant.quantity}
              className="mt-2 w-full justify-center"
            />
          )}
        </div>
      </div>
    </div>
  );
}
