import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { useState } from "react";
import { classNames } from "../../utils";

interface FieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  errors?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: boolean;
}
export function Field(props: FieldProps) {
  const {
    title,
    name,
    type,
    value,
    onChange,
    onBlur,
    readOnly = false,
    touched,
    errors,
    label = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const [passwordVisibile, setPasswordVisibile] = useState(false);

  switch (type) {
    case "checkbox":
      return (
        <>
          <div className="inline-flex items-center">
            <input
              name={name}
              id={id ? id : name}
              type={type}
              className={classNames(
                "relative h-4 w-4 rounded border border-gray-300 text-primary-600 sm:text-sm",
                "focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
                "checked:border-primary-500 checked:ring-primary-500",
                touched && errors ? "border-red-600 text-red-900" : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              {...rest}
            />
            {label && (
              <label
                htmlFor={id ? id : name}
                className="ml-1.5 block text-base font-normal text-black"
              >
                {title}
              </label>
            )}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors.toString()}
            </p>
          ) : null}
        </>
      );
    case "radio":
      return (
        <>
          <div className="inline-flex items-center">
            <input
              name={name}
              id={id ? id : name}
              type={type}
              className={classNames(
                "relative h-4 w-4 rounded-full border border-gray-300 text-primary-600 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
                "checked:border-primary-500 checked:ring-primary-500",
                touched && errors ? "border-red-600 text-red-900" : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              {...rest}
            />
            {label && (
              <label
                htmlFor={id ? id : name}
                className="ml-1.5 block text-base font-normal text-black"
              >
                {title}
              </label>
            )}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors.toString()}
            </p>
          ) : null}
        </>
      );
    case "textarea":
      return (
        <>
          <label
            htmlFor={id ? id : name}
            className={`block text-base font-medium text-black ${
              label ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </label>
          <div className="relative">
            <textarea
              name={name}
              id={id ? id : name}
              className={classNames(
                "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm h-24",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                touched && errors ? "border-red-600 text-red-900" : "",
                className ? className : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              readOnly={readOnly}
              placeholder={placeholder ? placeholder : label ? "" : title}
              value={value}
              {...rest}
            />
            {touched && errors ? (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            ) : null}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors.toString()}
            </p>
          ) : null}
        </>
      );
    case "password":
      return (
        <>
          <label
            htmlFor={id ? id : name}
            className={`block text-base font-medium text-black ${
              label ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </label>
          <div className="relative">
            <input
              name={name}
              id={id ? id : name}
              type={passwordVisibile ? "text" : "password"}
              className={classNames(
                "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                touched && errors ? "border-red-600 text-red-900" : "",
                className ? className : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              placeholder={placeholder ? placeholder : label ? "" : title}
              {...rest}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 z-10 flex items-center pl-3 pr-3 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
              title={passwordVisibile ? "Hide Password" : "Show Password"}
              onClick={() => {
                setPasswordVisibile(!passwordVisibile);
              }}
            >
              {passwordVisibile ? (
                <span
                  className="bi bi-eye h-5 w-5 text-center text-xl leading-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <span
                  className="bi bi-eye-slash h-5 w-5 text-center text-xl leading-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors.toString()}
            </p>
          ) : null}
        </>
      );
    default:
      return (
        <>
          <label
            htmlFor={id ? id : name}
            className={`block text-base font-medium text-black ${
              label ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </label>
          <div className="relative">
            <input
              name={name}
              id={id ? id : name}
              type={type ?? "text"}
              className={classNames(
                "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                touched && errors ? "border-red-600 text-red-900" : "",
                className ? className : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              placeholder={placeholder ? placeholder : label ? "" : title}
              {...rest}
            />
            {touched && errors ? (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            ) : null}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors.toString()}
            </p>
          ) : null}
        </>
      );
  }
}
