import { Fragment, useState } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "../../../components/form";
import { Logo, Spinner } from "../../../animations";
import { classNames } from "../../../utils";
import { ErrorDebug } from "../../../components/core";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { useAuth } from "../../../modules/auth";
import { useAccount } from "../../../contexts/GenContext";
import { UserModel } from "../../../modules/auth/core";

const FETCH_VENUE_TYPE = gql`
  query FetchVenueType {
    fetchVenueType {
      id
      name
    }
  }
`;

const UPDATE_VENUE_TYPE = gql`
  mutation VenueTypeUpdate($customerVenueTypeId: Int) {
    venueTypeUpdate(
      input: { params: { customerVenueTypeId: $customerVenueTypeId } }
    ) {
      customer {
        details {
          emailImportantUpdates
          emailSpecials
          mobileNumber
          phoneNumber
          postalAddress
          postcode
          state
          suburb
          venueFoodSpend {
            id
            spendRange
          }
          venueType {
            id
            name
          }
        }
      }
    }
  }
`;

export function VenueType() {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const { currentUser, setCurrentUser } = useAuth();
  const { saveAccount, toggleAccount, open } = useAccount();
  const [showForm, setShowForm] = useState<boolean>(false);
  const { data, loading, error } = useQuery(FETCH_VENUE_TYPE, {
    onCompleted: (data) => {
      if (data?.fetchVenueType) {
        setShowForm(true);
      }
    },
  });
  const [venueTypeUpdate] = useMutation(UPDATE_VENUE_TYPE);

  const formik = useFormik({
    initialValues: {
      customerVenueTypeId: null,
    },
    validationSchema: Yup.object().shape({
      customerVenueTypeId: Yup.number().required("Required"),
    }),
    onSubmit: (
      values: {
        customerVenueTypeId: number | null;
      },
      actions: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      console.log(values);

      venueTypeUpdate({
        variables: {
          customerVenueTypeId: values.customerVenueTypeId,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.venueTypeUpdate) {
            const { customer } = data.venueTypeUpdate;
            if (!currentUser) return;
            const updatedUser: UserModel = {
              ...currentUser,
              details: customer?.details,
            };
            setCurrentUser(updatedUser);
            saveAccount(updatedUser);
            setShowForm(false);
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Venue Type update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          addNotify({
            type: NotifyType.ERROR,
            title: "Venue Type update failed",
            message: error.message,
          });
        });
    },
  });

  return (
    <>
      <Transition.Root show={showForm && open} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setShowForm(false);
            toggleAccount();
            formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  className="flex h-full flex-col"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="relative px-4 pt-12 pb-4 text-center sm:px-6">
                      <div className="absolute top-5 right-5">
                        <button
                          type="button"
                          className="appearance-none rounded-md border-gray-700 text-gray-600 transition-colors hover:text-gray-900 focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                          onClick={() => {
                            setShowForm(false);
                            toggleAccount();
                            formik.resetForm();
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                      <Dialog.Title className="text-lg font-normal text-black">
                        Add Venue Type
                      </Dialog.Title>
                      <p className="mt-1 text-sm font-light text-gray-500">
                        Get started by filling in the information below to
                        create your new department.
                      </p>
                    </div>
                    <div className="px-4 sm:px-6">
                      <div className="space-y-6 pb-5">
                        {loading ? (
                          <div className="pointer-events-none absolute inset-center">
                            <Logo />
                          </div>
                        ) : error ? (
                          <ErrorDebug
                            error={error}
                            className="mx-auto w-full max-w-3xl"
                          />
                        ) : data.fetchVenueType.length > 0 ? (
                          <RadioGroup
                            value={formik.values.customerVenueTypeId}
                            onChange={(value: number) => {
                              formik.setFieldValue(
                                "customerVenueTypeId",
                                value
                              );
                            }}
                            className="mt-2"
                          >
                            <RadioGroup.Label className="sr-only">
                              Select a venue type
                            </RadioGroup.Label>
                            <div className="flex flex-wrap justify-center">
                              {data.fetchVenueType.map(
                                (option: { id: string; name: string }) => (
                                  <RadioGroup.Option
                                    key={option.id}
                                    value={Number(option.id)}
                                    className={({ checked }) =>
                                      classNames(
                                        "focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2",
                                        checked
                                          ? "border-transparent bg-primary-600 text-white hover:bg-primary-700"
                                          : "border-gray-200 bg-white text-gray-500 hover:bg-gray-50",
                                        "m-2 flex cursor-pointer items-center justify-center rounded-md border py-2 px-5 text-sm font-normal focus:outline-none"
                                      )
                                    }
                                  >
                                    <RadioGroup.Label as="span">
                                      {option.name}
                                    </RadioGroup.Label>
                                  </RadioGroup.Option>
                                )
                              )}
                            </div>
                          </RadioGroup>
                        ) : (
                          <div>{t("text_empty_venue_types")}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowForm(false);
                        toggleAccount();
                        formik.resetForm();
                      }}
                    >
                      {t("text_cancel")}
                    </Button>
                    <Button type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        t("text_save")
                      )}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
