import { gql } from "@apollo/client/core";

export const STORE_BANNER_FRAGMENT = gql`
  fragment StoreBannerFragment on Customerstorebanner {
    title
    caption
    button
    bannerImageUrl
    contentPosition
  }
`;

export const FETCH_STOREHOME = gql`
  ${STORE_BANNER_FRAGMENT}
  query FetchIndex($deviceType: Int!) {
    fetchIndex(deviceType: $deviceType) {
      webBanners {
        ...StoreBannerFragment
      }
      recentOrders {
        id
        orderNumber
        items {
          id
          price
          quantity
          variant {
            product {
              name
              handle
              description
              featureImageUrl
            }
            stockCode
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
          }
        }
        notes
        shippingAddress {
          address
          suburb
          state
          postcode
        }
        status
        subTotal
        total
        createdAt
      }
      recentPurchasedProducts {
        id
        stockCode
        comparePrice
        customerBasePrice
        customerSpecialPrice {
          endDate
          price
        }
        variantImageUrl
        variantTitle {
          name
          option {
            name
          }
        }
        minimumQuantity
        inventory
        product {
          id
          name
          handle
          description
          hasVariants
          featureImageUrl
          galleryImages {
            imageUrl
          }
          variants {
            id
            stockCode
            comparePrice
            customerBasePrice
            customerSpecialPrice {
              endDate
              price
            }
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
          department {
            name
          }
          categories {
            name
          }
        }
      }
      mostPurchasedProducts {
        id
        stockCode
        comparePrice
        customerBasePrice
        customerSpecialPrice {
          endDate
          price
        }
        variantImageUrl
        variantTitle {
          name
          option {
            name
          }
        }
        minimumQuantity
        inventory
        product {
          id
          name
          handle
          description
          hasVariants
          featureImageUrl
          galleryImages {
            imageUrl
          }
          variants {
            id
            stockCode
            comparePrice
            customerBasePrice
            customerSpecialPrice {
              endDate
              price
            }
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
          department {
            name
          }
          categories {
            name
          }
        }
      }
      specials {
        description
        featureImageUrl
        galleryImages {
          imageUrl
        }
        handle
        hasVariants
        id
        name
        variants {
          id
          stockCode
          comparePrice
          customerBasePrice
          customerSpecialPrice {
            endDate
            price
          }
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
          minimumQuantity
          inventory
        }
      }
      latestProducts {
        description
        featureImageUrl
        galleryImages {
          imageUrl
        }
        handle
        hasVariants
        id
        name
        variants {
          id
          stockCode
          comparePrice
          customerBasePrice
          customerSpecialPrice {
            endDate
            price
          }
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
          minimumQuantity
          inventory
        }
        department {
          name
        }
        categories {
          name
        }
      }
    }
  }
`;
