import React, { useEffect, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useTimeout } from "../hooks/util";
import { classNames } from "../utils";

const iconAnimate = keyframes`
    0%{
      left: 7.5%;
    }
    36% {
      transform: rotate(0deg) translateY(0px);
    }
    38% {
      transform: rotate(-15deg) translateY(-5px);
    }
    40% {
      transform: rotate(0deg) translateY(0px);
    }
    42% {
      transform: rotate(-10deg) translateY(-3px);
    }
    54% {
      transform: rotate(0deg) translateY(0px);
    }
    70%{
      left: 50%;
    }
    100% {
      left: 100%;
    }
  `;
const plusAnimate = keyframes`
    0%{
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(-200deg);
      opacity: 1;
    }
    100% {
      transform: rotate(-200deg);
      opacity: 0;
    }
`;
const cartAnimate = keyframes`
    0%{
        transform: rotate(0deg);
        opacity: 0;
    }
    80% {
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: rotate(0deg);
      opacity: 1;
    }
  `;
const textAddingAnimate = keyframes`
    0%{
      transform: translateY(0px);
    }
    28% {
      transform: translateY(-5px);
    }
    30% {
      opacity: 1;
      transform: translateY(-5px);
    }
    32% {
      transform: translateY(-5px);
    }
    34% {
      opacity: 0;
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(-5px);
      opacity: 0;
    }
  `;
const textAddedAnimate = keyframes`
  0%{
    transform: translateY(1rem);
    opacity: 0;
  }
  32% {
    transform: translateY(1rem);
    opacity: 0;
  }
  34%{
    opacity: 1;
  }
  38% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const dot1Animate = keyframes`
    0%{
      transform: scale(1.5) translateY(0);
      opacity: 0;
    }
    15% {
      opacity: 1;
      transform: scale(1.5) translateY(0);
    }
    30% {
      transform: scale(.8) translateY(0);
    }
    45% {
      transform: scale(.8) translateY(0);
    }
    50% {
      transform: scale(.8) translateY(-15px);
    }
    55% {
      transform: scale(.8) translateY(3px);
      opacity: 1;
    }
    60% {
      transform: scale(.8) translateY(3px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  `;
const dot2Animate = keyframes`
  0%{
    transform: scale(1.5) translateY(0);
    opacity: 0;
  }
  15% {
    opacity: 1;
    transform: scale(1.5) translateY(0);
  }
  30% {
    transform: scale(.8) translateY(0);
  }
  45% {
    transform: scale(.8) translateY(0);
  }
  50% {
    transform: scale(.8) translateY(-15px);
  }
  55% {
    transform: scale(.8) translateY(3px);
    opacity: 1;
  }
  60% {
    transform: scale(.8) translateY(3px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;
const dot3Animate = keyframes`
  0%{
    transform: scale(1.5) translateY(0);
    opacity: 0;
  }
  15% {
    opacity: 1;
    transform: scale(1.5) translateY(0);
  }
  30% {
    transform: scale(.8) translateY(0);
  }
  45% {
    transform: scale(.8) translateY(0);
  }
  50% {
    transform: scale(.8) translateY(-15px);
  }
  55% {
    transform: scale(.8) translateY(3px);
    opacity: 1;
  }
  60% {
    transform: scale(.8) translateY(3px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;
const dot4Animate = keyframes`
  0%{
    transform: scale(1.5) translateY(0);
    opacity: 0;
  }
  15% {
    opacity: 1;
    transform: scale(1.5) translateY(0);
  }
  30% {
    transform: scale(.8) translateY(0);
  }
  45% {
    transform: scale(.8) translateY(0);
  }
  50% {
    transform: scale(.8) translateY(-15px);
  }
  55% {
    transform: scale(.8) translateY(3px);
    opacity: 1;
  }
  60% {
    transform: scale(.8) translateY(3px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const Button = styled.button`
  position: relative;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 10rem;
  overflow: hidden;
  text-align: center;

  .icon-anim {
    position: absolute;
    top: 50%;
    left: 7.5%;
    z-index: 3;
    margin-top: -1rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    .icon-plus {
      width: 1rem;
      height: 1rem;
      position: absolute;
      z-index: 5;
    }
    .icon-cart {
      display: block;
      width: 1.75rem;
      height: 1.75rem;
      position: absolute;
      z-index: 4;
      opacity: 0;

      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: currentColor;
      }
      &::before {
        left: 0.7rem;
      }
      &::after {
        left: 1.25rem;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .text-add {
    position: relative;
    color: transparent;
    text-align: center;

    &::before,
    &::after {
      display: block;
      width: 100%;
      position: absolute;
      top: 50%;
      color: #fff;
      margin-top: -0.75rem;
    }

    &::before {
      content: attr(data-before);
    }
    &::after {
      content: attr(data-after);
      opacity: 0;
      transform: translateY(1rem);
    }
  }
  .text-remove {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .text-added{
    display: none;
  }
  .dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-left: -4px;
    margin-top: -4px;
    transform: scale(1.5) translateY(0);
    opacity: 0;
  }
  .dot-1 {
    background-color: #007D4C;
    margin-left: -12px;
  }
  .dot-2 {
    background-color: #386C5F;
    margin-left: -4px;
  }
  .dot-3 {
    background-color: #007D4C;
    margin-left: 4px;
  }
  .dot-4 {
    background-color: #5F762E;
    margin-left: 12px;
  }

  &.loading {
    .icon-anim {
      animation: ${iconAnimate} 1s linear 1;
      animation-fill-mode: forwards;
      animation-delay: 0.8s;
    }
    .icon-plus {
      animation: ${plusAnimate} 0.3s linear 1;
      animation-fill-mode: forwards;
    }
    .icon-cart {
      animation: ${cartAnimate} 0.3s linear 1;
      animation-fill-mode: forwards;
      animation-delay: 0.25s;
    }
    .text-add {
      &::before {
        animation: ${textAddingAnimate} 1s linear 1;
        animation-fill-mode: forwards;
        animation-delay: 0.8s;
      }
      &::after {
        animation: ${textAddedAnimate} 1s linear 1;
        animation-fill-mode: forwards;
        animation-delay: 1.5s;
      }
    }
    .dot-1 {
      animation: ${dot1Animate} 1.4s linear 1;
      animation-fill-mode: forwards;
      animation-delay: 0.75s;
    }
    .dot-2 {
      animation: ${dot2Animate} 1.4s linear 1;
      animation-fill-mode: forwards;
      animation-delay: 0.8s;
    }
    .dot-3 {
      animation: ${dot3Animate} 1.3s linear 1;
      animation-fill-mode: forwards;
      animation-delay: 0.85s;
    }
    .dot-4 {
      animation: ${dot4Animate} 1.2s linear 1;
      animation-fill-mode: forwards;
      animation-delay: 0.9s;
    }
  }
  &.loaded {
    .icon-anim,
    .text-add,
    .dot {
      display: none;
      opacity: 0;
    }
    .text-added {
      display: block;
    }
`;

interface AddtocartProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
  addtext?: string;
  addedtext?: string;
  count: number;
  className?: string;
}

export function Addtocart(props: AddtocartProps) {
  const {
    loading,
    disabled,
    addtext = "Add to cart",
    addedtext = "Added",
    count,
    className,
    ...rest
  } = props;
  const [adding, setAdding] = useState(false);
  const counter = useMemo(() => (count === 0 ? 1 : count), [count]);

  useEffect(() => {
    if (loading) {
      setAdding(true);
    }
    const timer = setTimeout(() => {
      setAdding(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, [loading]);

  let classes =
    "inline-flex items-center whitespace-nowrap transition focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2";
  classes += className ? " " + className : " justify-center";
  classes +=
    " border-transparent bg-primary-600 text-white rounded-lg border text-base font-normal px-4 md:px-6 py-2.5";
  classes += adding ? " loading" : " hover:bg-primary-700";

  return (
    <Button type="button" className={classNames(classes)} {...rest}>
      <span className="icon-anim" aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 40"
          className="icon-plus"
        >
          <path
            fill="currentColor"
            d="M38,18H22V2a2,2,0,0,0-4,0V18H2a2,2,0,0,0,0,4H18V38a2,2,0,0,0,4,0V22H38a2,2,0,0,0,0-4Z"
          />
        </svg>
        <span className="icon-cart">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 43.61 30.67"
            className="icon-cart"
          >
            <polygon
              fill="rgb(64,187,70)"
              points="10.92 10.59 42.07 10.59 38.21 28.75 14.87 28.75 10.92 10.59"
            />
            <path
              fill="currentColor"
              d="M43.27,9.78h0a1.46,1.46,0,0,0-1.13-.54H11.92L10.31,1.18A1.48,1.48,0,0,0,8.87,0H1.47a1.48,1.48,0,0,0,0,3H7.66l4.72,23.56a5.15,5.15,0,0,0,5,4.16H35.56a5.2,5.2,0,0,0,5.07-4.17L43.58,11A1.43,1.43,0,0,0,43.27,9.78Zm-2.92,2.41L37.73,25.93a2.28,2.28,0,0,1-2.25,1.79h-18a2.18,2.18,0,0,1-2.18-1.79L12.51,12.19Z"
            />
          </svg>
        </span>
      </span>
      <span
        className="text-add"
        data-before={addtext}
        data-after={`${addedtext} (${counter})`}
      >
        {addtext}
      </span>
      <span className="dot dot-1"></span>
      <span className="dot dot-2"></span>
      <span className="dot dot-3"></span>
      <span className="dot dot-4"></span>
    </Button>
  );
}
