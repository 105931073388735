import { useEffect, useRef, useState } from "react";
import { SignalIcon, SignalSlashIcon } from "@heroicons/react/24/outline";
import { animated, Spring } from "@react-spring/web";

export function NetworkStatus() {
  const intialLoad = useRef(true);
  const [online, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", (e) => {
      if (intialLoad.current) {
        intialLoad.current = false;
      }
      setOnline(false);
    });
    window.addEventListener("online", (e) => {
      setOnline(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnline(false);
      });
      window.removeEventListener("online", () => {
        setOnline(true);
      });
    };
  }, []);

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6"
      >
        <div className="flex w-full flex-col items-end space-y-4">
          {!intialLoad.current && (
            <div title={online ? "Online" : "Offline"}>
              {online ? (
                <Spring
                  from={{ opacity: 1, transform: "translateY(0%)" }}
                  delay={2000}
                  to={[{ opacity: 0, transform: "translateY(100%)" }]}
                >
                  {(styles) => (
                    <animated.div style={styles}>
                      <SignalIcon
                        className="h-6 w-6 text-green-700"
                        aria-hidden="true"
                      />
                    </animated.div>
                  )}
                </Spring>
              ) : (
                <Spring
                  from={{ opacity: 0, transform: "translateY(100%)" }}
                  to={[{ opacity: 1, transform: "translateY(0%)" }]}
                >
                  {(styles) => (
                    <animated.div style={styles}>
                      <SignalSlashIcon
                        className="h-6 w-6 text-red-700"
                        aria-hidden="true"
                      />
                    </animated.div>
                  )}
                </Spring>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
