import { RouteObject, useRoutes, Link, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { InView } from "react-intersection-observer";
import HeaderLayout from "../../layouts/HeaderLayout";
import { Logo } from "../../animations";
import { ErrorFallback, Head, NotFound } from "../../components/core";
import { PageTitle } from "../../components/page";
import { FETCH_SPECIAL_PRODUCTS, type Product } from "../../graphql/shop";
import { ProductCard, ProductSidebar } from "../../components/product";

export const SpecialPage = () => {
  let routes: RouteObject[] = [
    {
      path: "/",
      element: <ProductListing />,
      children: [
        {
          path: "/:handle",
          element: <ProductSidebar archive="special" />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
};

export const SpecialResource: ResourceProps = {
  name: "Special Offers",
  path: "/special/*",
};

function ProductListing() {
  const { data, loading, error, fetchMore, networkStatus } = useQuery(
    FETCH_SPECIAL_PRODUCTS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  if (error) return <ErrorFallback error={error} />;

  // if (networkStatus === 1) {
  //   return <div>Loading...</div>;
  // }

  // const isRefetching = networkStatus === 3;

  // console.log("isRefetching", isRefetching);

  return (
    <>
      <div className="w-prev-container w-full pb-10 3xl:px-8">
        <Head title={SpecialResource.name} />
        <HeaderLayout>
          <PageTitle>
            <span>{SpecialResource.name}</span>
            <span className="ml-3 text-lg font-normal text-gray-400">
              {data?.fetchSpecialProducts?.totalCount ?? 0} Products
            </span>
          </PageTitle>
        </HeaderLayout>
        <div className="px-4 md:mr-4 md:px-8">
          {data?.fetchSpecialProducts?.edges?.length > 0 ? (
            <div className="mt-6 flex flex-col space-y-4">
              {data.fetchSpecialProducts.edges.map(
                (edge: { node: Product }) => (
                  <ProductCard
                    key={edge.node.id}
                    archive="special"
                    product={edge.node}
                  />
                )
              )}
            </div>
          ) : !loading ? (
            <div className="py-10 text-center md:py-16 xl:py-20">
              <h3 className="text-xl font-semibold text-gray-900">
                No special offers found
              </h3>
              <p className="text-gray-700">
                Plase check back later for new special offers.
              </p>
              <Link
                to="/shop"
                className="mt-4 inline-flex items-center rounded-lg border border-gray-200 bg-transparent p-2 px-4 py-3 text-sm font-medium text-gray-900"
              >
                Shop All Products
              </Link>
            </div>
          ) : null}

          {data?.fetchSpecialProducts && (
            <InView
              disabled={!data.fetchSpecialProducts.pageInfo.hasNextPage}
              onChange={async (inView) => {
                console.log("inView", inView);
                if (inView) {
                  await fetchMore({
                    variables: {
                      cursor: data.fetchSpecialProducts.pageInfo.endCursor,
                    },
                  });
                }
              }}
            />
          )}
          {loading ? (
            <div className="mt-6 flex justify-center py-10 md:py-16 xl:py-20">
              <Logo />
            </div>
          ) : null}
        </div>
      </div>
      <Outlet />
    </>
  );
}
