import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { useAuth } from "..";

import { Field, Response } from "../../../components/form/";

import { Spinner } from "../../../animations";
import { Head } from "../../../components/core";
import logo from "../../../assets/logo.svg";
import { useAccount } from "../../../contexts/GenContext";
import { useCart } from "../../cart/core/CartProvider";
import { ShippingAddress } from "../../../graphql/order";
import { UserModel } from "../core";

const { REACT_APP_NAME } = process.env;

export function Login() {
  const { t } = useTranslation();

  return (
    <>
      <Head title="Login" />
      <header>
        <img
          className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
          src={logo}
          alt={REACT_APP_NAME}
        />
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {t("heading_login")}
        </h2>
      </header>
      <UserLogin />
    </>
  );
}

const UserLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [response, setResponse] = useState<FormResponse | null>(null);
  let location: any = useLocation();
  let from =
    location.state?.from && !location.state?.from.includes("error")
      ? location.state.from
      : "/";

  const {
    setCurrentUser,
    setCurrentType,
    setMfaEnabled,
    // setLocked,
    saveAccess,
    saveRefresh,
  } = useAuth();
  const { loadCart } = useCart();
  const { saveAccount } = useAccount();

  const LOGIN = gql`
    mutation Login($email: String!, $password: String!, $rememberMe: Boolean!) {
      login(
        input: {
          params: {
            email: $email
            password: $password
            rememberMe: $rememberMe
            platform: "web"
          }
        }
      ) {
        accessToken
        refreshToken
        customer {
          abnNo
          customerCode
          customerName
          companyName
          firstName
          lastName
          profileImageUrl
          customerPlu
          customerType
          companyName
          lastActiveCustomerAgent
          lastActiveIp
          lastActiveLocation
          packingInstruction
          email
          storeEmail
          storePhoneNumber
          storeContact
          storeFax
          deliveryInstruction
          details {
            emailImportantUpdates
            emailSpecials
            mobileNumber
            phoneNumber
            postalAddress
            postcode
            state
            suburb
            venueFoodSpend {
              id
              spendRange
            }
            venueType {
              id
              name
            }
          }
          shippingAddresses {
            id
            address
            suburb
            state
            postcode
          }
        }
      }
    }
  `;

  const [login] = useMutation(LOGIN);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
    rememberMe: Yup.boolean(),
  });

  const handleSubmit = (
    values: {
      email: string;
      password: string;
      rememberMe: boolean;
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    setResponse(null);

    login({
      variables: {
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      },
    })
      .then(({ data }) => {
        if (data?.login) {
          actions.setSubmitting(false);

          const { accessToken, refreshToken, customer } = data.login;
          saveAccess(accessToken);
          saveRefresh(refreshToken);
          const primaryAddress = customer?.shippingAddresses?.find(
            (a: ShippingAddress) => a?.primaryAddress
          );

          const updatedUser: UserModel = {
            ...customer,
            primaryAddress: primaryAddress
              ? primaryAddress
              : customer?.shippingAddresses?.length > 0
              ? customer?.shippingAddresses[0]
              : null,
          };

          saveAccount(updatedUser);
          setCurrentUser(updatedUser);
          setCurrentType(updatedUser.customerType);
          setMfaEnabled(updatedUser?.enableMfa ?? false);
          console.log("updatedUser", updatedUser);
          loadCart(updatedUser);
          return navigate(from, { replace: true });
        } else {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        setResponse({
          type: "error",
          message: error.message,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
  });

  const { errors, touched } = formik;

  return (
    <div className="mt-6">
      <form className="space-y-4" onSubmit={formik.handleSubmit}>
        <div className="relative mt-1">
          <Field
            title={t("text_email")}
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            touched={touched.email}
            errors={errors.email}
            autoComplete="email"
          />
        </div>
        <div>
          <Field
            title={t("text_password")}
            type="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            touched={touched.password}
            errors={errors.password}
          />
        </div>

        <div className="flex items-center justify-between">
          <Field
            title={t("text_remember_me")}
            id="rememberMe"
            name="rememberMe"
            type="checkbox"
            aria-describedby="rememberMe-description"
            checked={formik.values.rememberMe}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div className="text-base">
            <Link
              to="/auth/forgot-password"
              className="rounded-sm font-medium text-primary-700 hover:text-primary-700 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
            >
              Forgot your password?
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            data-testid="login-submit"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2.5 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            disabled={formik.isSubmitting}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
                aria-hidden="true"
              />
            </span>

            {formik.isSubmitting ? (
              <>
                <Spinner />
                Processing...
              </>
            ) : (
              "Sign in"
            )}
          </button>
        </div>

        <div>
          <div className="relative text-center text-base">
            <div className="absolute top-0 bottom-0 m-auto h-px w-full bg-gray-200"></div>
            <span className="relative z-10 inline-flex bg-white p-2">
              Are you new to Alpha Fresh?
            </span>
          </div>
          <Link
            to="/auth/register"
            className="group relative flex w-full justify-center rounded-md border border-gray-300 bg-transparent px-4 py-2.5 text-base font-medium text-primary hover:border-primary-500 hover:bg-primary-50 hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Sign Up
          </Link>
        </div>

        <Response
          response={response}
          onDismiss={() => {
            setResponse(null);
          }}
          className="mt-5"
        />
      </form>
    </div>
  );
};
