import { Outlet } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

import NavLayout from "./NavLayout";

const GlobalStyle = createGlobalStyle`
html {
  background-color: #ffffff;
}
`;

type LayoutProps = {
  navBorder?: boolean;
  children: React.ReactNode;
};
export function MasterLayout(props: LayoutProps) {
  const { navBorder = true, children } = props;
  return (
    <>
      <GlobalStyle />
      <SimpleBar className="h-full overflow-y-auto">
        <NavLayout navBorder={navBorder} />
        <main className="flex h-full flex-1 flex-col lg:pl-64">
          <div className="h-full">{children}</div>
        </main>
      </SimpleBar>
    </>
  );
}
