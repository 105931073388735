import { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { Transition, Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import {
  Button,
  Field,
  FieldAddress,
  FieldState,
} from "../../../components/form/";
import { Logo, Spinner } from "../../../animations";

import { ErrorFallback, Head } from "../../../components/core";
import {
  MapIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { PageTitle } from "../../../components/page";
import { Address } from "../../../graphql/shipping";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../utils";

const FETCH_SHIPPING_ADDRESSES = gql`
  query Me {
    me {
      shippingAddresses {
        id
        address
        suburb
        state
        postcode
        primaryAddress
      }
    }
  }
`;

const CREATE_SHIPPING_ADDRESS = gql`
  mutation ShippingAddressCreate(
    $address: String
    $suburb: String
    $state: String
    $postcode: String
    $primaryAddress: Boolean
  ) {
    shippingAddressCreate(
      input: {
        params: {
          address: $address
          suburb: $suburb
          state: $state
          postcode: $postcode
          primaryAddress: $primaryAddress
        }
      }
    ) {
      shippingAddress {
        id
        address
        postcode
        state
        suburb
        primaryAddress
      }
    }
  }
`;

const DELETE_SHIPPING_ADDRESS = gql`
  mutation ShippingAddressDelete($id: ID!) {
    shippingAddressDelete(input: { id: $id }) {
      shippingAddress {
        id
        address
        suburb
        state
        postcode
        primaryAddress
      }
    }
  }
`;

const UPDATE_SHIPPING_ADDRESS = gql`
  mutation ShippingAddressUpdate(
    $id: ID!
    $address: String
    $suburb: String
    $state: String
    $postcode: String
    $primaryAddress: Boolean
  ) {
    shippingAddressUpdate(
      input: {
        id: $id
        params: {
          address: $address
          suburb: $suburb
          state: $state
          postcode: $postcode
          primaryAddress: $primaryAddress
        }
      }
    ) {
      shippingAddress {
        id
        address
        postcode
        state
        suburb
        primaryAddress
      }
    }
  }
`;

interface IShippingAddressData extends Address {
  id: string;
}
export default function ShippingPage() {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const [creatingAddress, setCreatingAddress] = useState<boolean>(false);
  const [editingAddress, setEditingAddress] = useState<
    IShippingAddressData | undefined
  >(undefined);

  const {
    data,
    loading,
    error,
    refetch: refetchShippingAddresses,
  } = useQuery(FETCH_SHIPPING_ADDRESSES);
  const [createShippingAddress] = useMutation(CREATE_SHIPPING_ADDRESS);
  const [updateShippingAddress] = useMutation(UPDATE_SHIPPING_ADDRESS);
  const [deleteShippingAddress] = useMutation(DELETE_SHIPPING_ADDRESS);

  const ShippingAddressSchema = Yup.object().shape({
    address: Yup.string().required("Required"),
    suburb: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
    primaryAddress: Yup.boolean(),
  });

  const formikShippingAddressCreate = useFormik({
    initialValues: {
      address: "",
      suburb: "",
      state: "",
      postcode: "",
      primaryAddress: false,
    },
    validationSchema: ShippingAddressSchema,
    onSubmit: (
      values: Address,
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      createShippingAddress({
        variables: {
          address: values.address,
          suburb: values.suburb,
          state: values.state,
          postcode: values.postcode,
          primaryAddress: values.primaryAddress,
        },
      })
        .then(({ data }) => {
          if (data?.shippingAddressCreate) {
            refetchShippingAddresses().finally(() => {
              setCreatingAddress(false);
              addNotify({
                type: NotifyType.SUCCESS,
                title: "Shipping address created",
              });
              formikShippingAddressCreate.resetForm();
              actions.setSubmitting(false);
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Shipping address creation failed",
              message: "Something went wrong, please try again later",
            });
            actions.setSubmitting(false);
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Shipping address creation failed",
            message: error.message,
          });
          actions.setSubmitting(false);
        });
    },
  });

  const formikShippingAddressUpdate = useFormik({
    initialValues: {
      address: editingAddress?.address || "",
      suburb: editingAddress?.suburb || "",
      state: editingAddress?.state || "",
      postcode: editingAddress?.postcode || "",
      primaryAddress: editingAddress?.primaryAddress || false,
    },
    enableReinitialize: true,
    validationSchema: ShippingAddressSchema,
    onSubmit: (
      values: Address,
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      if (!editingAddress) return;
      updateShippingAddress({
        variables: {
          id: editingAddress.id,
          address: values.address,
          suburb: values.suburb,
          state: values.state,
          postcode: values.postcode,
          primaryAddress: values.primaryAddress,
        },
      })
        .then(({ data }) => {
          if (data?.shippingAddressUpdate) {
            refetchShippingAddresses().finally(() => {
              setEditingAddress(undefined);
              addNotify({
                type: NotifyType.SUCCESS,
                title: "Shipping address updated",
              });
              formikShippingAddressUpdate.resetForm();
              actions.setSubmitting(false);
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Shipping address update failed",
              message: "Something went wrong, please try again later",
            });
            actions.setSubmitting(false);
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Shipping address update failed",
            message: error.message,
          });
          actions.setSubmitting(false);
        });
    },
  });

  const handleDelete = async (id: string) => {
    return await deleteShippingAddress({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.shippingAddressDelete) {
          const deletedShippingAddress =
            data?.shippingAddressDelete?.shippingAddress;
          refetchShippingAddresses().finally(() => {
            addNotify({
              type: NotifyType.SUCCESS,
              title: `Shipping address ${deletedShippingAddress.address} deleted`,
            });
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Shipping address delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Shipping address delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <ResponseAlert />
      <Head title={ShippingResource.name} />
      <div className="w-prev-container w-full pb-10">
        <header className="mb-4 flex items-end justify-between md:mb-8">
          <div>
            <PageTitle className="text-primary-900">
              {ShippingResource.name}
            </PageTitle>
            <p className="text-sm text-gray-500">
              View your shipping addresses and manage them.
            </p>
          </div>
          <Button
            variant="tertiary"
            onClick={() => {
              setCreatingAddress(true);
            }}
          >
            Create Address
          </Button>
        </header>

        {loading ? (
          <div className="py-10 md:py-16">
            <div className="pointer-events-none text-center opacity-20">
              <Logo />
            </div>
          </div>
        ) : (
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            {data.me.shippingAddresses.map((address: IShippingAddressData) => {
              return (
                <li
                  key={address.id}
                  className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border border-gray-200 bg-white text-center"
                >
                  <div className="relative flex flex-1 flex-col p-8">
                    <div className="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-primary-50">
                      <MapIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <h3 className="mt-6 text-sm font-medium text-gray-900">
                      {address.address}
                    </h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dt className="sr-only">Address</dt>
                      <dd className="text-sm text-gray-500">
                        {address.address +
                          " " +
                          address.suburb +
                          " " +
                          address.state +
                          " " +
                          address.postcode}
                      </dd>
                      <dt className="sr-only">Suburb</dt>
                      <dd className="mt-3">
                        <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                          {address.suburb}
                        </span>
                      </dd>
                    </dl>
                    {address.primaryAddress && (
                      <CheckCircleIcon
                        className={classNames(
                          "absolute right-4 top-4 z-10 h-6 w-6 flex-shrink-0 rounded-full bg-primary-100 text-primary"
                        )}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="flex w-0 flex-1">
                        <Button
                          variant="icon"
                          className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                          onClick={() => {
                            setAlert({
                              id: address.id,
                              name: address.address,
                            });
                          }}
                        >
                          <TrashIcon
                            className="h-5 w-5 text-red-700"
                            aria-hidden="true"
                          />
                          <span className="ml-3">Delete</span>
                        </Button>
                      </div>
                      <div className="-ml-px flex w-0 flex-1">
                        <Button
                          variant="icon"
                          className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                          onClick={() => {
                            setEditingAddress(address);
                          }}
                        >
                          <PencilIcon
                            className="h-5 w-5 text-gray-700"
                            aria-hidden="true"
                          />
                          <span className="ml-3">Edit</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        <Transition.Root show={creatingAddress} as={Fragment} appear>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setCreatingAddress(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                  <form
                    className="flex h-full flex-col divide-y divide-gray-200"
                    onSubmit={formikShippingAddressCreate.handleSubmit}
                  >
                    <div className="h-0 flex-1">
                      <div className="py-8 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-black">
                            New Shipping Address -{" "}
                            <span>
                              {formikShippingAddressCreate.values.address
                                ? formikShippingAddressCreate.values.address
                                : t("text_untitled")}
                            </span>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                              onClick={() => setCreatingAddress(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500">
                            Get started by filling in the information below to
                            create your new docks.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pb-5">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                              <div>
                                <FieldAddress
                                  title={t("text_address")}
                                  name="address"
                                  type="street"
                                  onChange={(value) => {
                                    const { street, state, suburb, postcode } =
                                      value;

                                    formikShippingAddressCreate.setValues({
                                      ...formikShippingAddressCreate.values,
                                      address: street,
                                      suburb:
                                        suburb ||
                                        formikShippingAddressCreate.values
                                          .suburb,
                                      state:
                                        state ||
                                        formikShippingAddressCreate.values
                                          .state,
                                      postcode:
                                        postcode ||
                                        formikShippingAddressCreate.values
                                          .postcode,
                                    });
                                  }}
                                  value={
                                    formikShippingAddressCreate.values.address
                                  }
                                  touched={
                                    formikShippingAddressCreate.touched.address
                                  }
                                  errors={
                                    formikShippingAddressCreate.errors.address
                                  }
                                />
                              </div>
                              <div>
                                <Field
                                  title={t("text_suburb")}
                                  name="suburb"
                                  onChange={
                                    formikShippingAddressCreate.handleChange
                                  }
                                  onBlur={
                                    formikShippingAddressCreate.handleBlur
                                  }
                                  value={
                                    formikShippingAddressCreate.values.suburb
                                  }
                                  touched={
                                    formikShippingAddressCreate.touched.suburb
                                  }
                                  errors={
                                    formikShippingAddressCreate.errors.suburb
                                  }
                                />
                              </div>
                              <div>
                                <FieldState
                                  title={t("text_state")}
                                  name="state"
                                  onChange={(value) => {
                                    formikShippingAddressCreate.setFieldValue(
                                      "state",
                                      value
                                    );
                                  }}
                                  value={
                                    formikShippingAddressCreate.values.state
                                  }
                                  touched={
                                    formikShippingAddressCreate.touched.state
                                  }
                                  errors={
                                    formikShippingAddressCreate.errors.state
                                  }
                                />
                              </div>
                              <div>
                                <FieldAddress
                                  title={t("text_postcode")}
                                  name="postcode"
                                  type="postcode"
                                  onChange={(value) => {
                                    const { street, state, suburb, postcode } =
                                      value;

                                    formikShippingAddressCreate.setValues({
                                      ...formikShippingAddressCreate.values,
                                      address:
                                        street ||
                                        formikShippingAddressCreate.values
                                          .address,
                                      suburb:
                                        suburb ||
                                        formikShippingAddressCreate.values
                                          .suburb,
                                      state:
                                        state ||
                                        formikShippingAddressCreate.values
                                          .state,
                                      postcode: postcode,
                                    });
                                  }}
                                  value={
                                    formikShippingAddressCreate.values.postcode
                                  }
                                  touched={
                                    formikShippingAddressCreate.touched.postcode
                                  }
                                  errors={
                                    formikShippingAddressCreate.errors.postcode
                                  }
                                />
                              </div>
                              <div>
                                <Field
                                  title={t("text_primary_address")}
                                  id="primaryAddress"
                                  name="primaryAddress"
                                  type="checkbox"
                                  aria-describedby="primaryAddress-description"
                                  checked={
                                    formikShippingAddressCreate.values
                                      .primaryAddress
                                  }
                                  onChange={
                                    formikShippingAddressCreate.handleChange
                                  }
                                  onBlur={
                                    formikShippingAddressCreate.handleBlur
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                      <Button
                        variant="secondary"
                        onClick={() => setCreatingAddress(false)}
                      >
                        {t("text_cancel")}
                      </Button>
                      <Button
                        type="submit"
                        disabled={formikShippingAddressCreate.isSubmitting}
                      >
                        {formikShippingAddressCreate.isSubmitting ? (
                          <>
                            <Spinner />
                            {t("text_processing")}
                          </>
                        ) : (
                          t("text_create")
                        )}
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root
          show={editingAddress ? true : false}
          as={Fragment}
          appear
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setEditingAddress(undefined);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                  <form
                    className="flex h-full flex-col divide-y divide-gray-200"
                    onSubmit={formikShippingAddressUpdate.handleSubmit}
                  >
                    <div className="h-0 flex-1">
                      <div className="py-8 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-black">
                            Edit Shipping Address -{" "}
                            <span>
                              {formikShippingAddressUpdate.values.address
                                ? formikShippingAddressUpdate.values.address
                                : t("text_untitled")}
                            </span>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                              onClick={() => setEditingAddress(undefined)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500">
                            Get started by filling in the information below to
                            create your new docks.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pb-5">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                              <div>
                                <FieldAddress
                                  title={t("text_address")}
                                  name="address"
                                  type="street"
                                  onChange={(value) => {
                                    const { street, state, suburb, postcode } =
                                      value;

                                    formikShippingAddressUpdate.setValues({
                                      ...formikShippingAddressUpdate.values,
                                      address: street,
                                      suburb:
                                        suburb ||
                                        formikShippingAddressUpdate.values
                                          .suburb,
                                      state:
                                        state ||
                                        formikShippingAddressUpdate.values
                                          .state,
                                      postcode:
                                        postcode ||
                                        formikShippingAddressUpdate.values
                                          .postcode,
                                    });
                                  }}
                                  value={
                                    formikShippingAddressUpdate.values.address
                                  }
                                  touched={
                                    formikShippingAddressUpdate.touched.address
                                  }
                                  errors={
                                    formikShippingAddressUpdate.errors.address
                                  }
                                />
                              </div>
                              <div>
                                <Field
                                  title={t("text_suburb")}
                                  name="suburb"
                                  onChange={
                                    formikShippingAddressUpdate.handleChange
                                  }
                                  onBlur={
                                    formikShippingAddressUpdate.handleBlur
                                  }
                                  value={
                                    formikShippingAddressUpdate.values.suburb
                                  }
                                  touched={
                                    formikShippingAddressUpdate.touched.suburb
                                  }
                                  errors={
                                    formikShippingAddressUpdate.errors.suburb
                                  }
                                />
                              </div>
                              <div>
                                <FieldState
                                  title={t("text_state")}
                                  name="state"
                                  onChange={(value) => {
                                    formikShippingAddressUpdate.setFieldValue(
                                      "state",
                                      value
                                    );
                                  }}
                                  value={
                                    formikShippingAddressUpdate.values.state
                                  }
                                  touched={
                                    formikShippingAddressUpdate.touched.state
                                  }
                                  errors={
                                    formikShippingAddressUpdate.errors.state
                                  }
                                />
                              </div>
                              <div>
                                <FieldAddress
                                  title={t("text_postcode")}
                                  name="postcode"
                                  type="postcode"
                                  onChange={(value) => {
                                    const { street, state, suburb, postcode } =
                                      value;

                                    formikShippingAddressUpdate.setValues({
                                      ...formikShippingAddressUpdate.values,
                                      address:
                                        street ||
                                        formikShippingAddressUpdate.values
                                          .address,
                                      suburb:
                                        suburb ||
                                        formikShippingAddressUpdate.values
                                          .suburb,
                                      state:
                                        state ||
                                        formikShippingAddressUpdate.values
                                          .state,
                                      postcode: postcode,
                                    });
                                  }}
                                  value={
                                    formikShippingAddressUpdate.values.postcode
                                  }
                                  touched={
                                    formikShippingAddressUpdate.touched.postcode
                                  }
                                  errors={
                                    formikShippingAddressUpdate.errors.postcode
                                  }
                                />
                              </div>
                              <div>
                                <Field
                                  title={t("text_primary_address")}
                                  id="primaryAddress"
                                  name="primaryAddress"
                                  type="checkbox"
                                  aria-describedby="primaryAddress-description"
                                  checked={
                                    formikShippingAddressUpdate.values
                                      .primaryAddress
                                  }
                                  onChange={
                                    formikShippingAddressUpdate.handleChange
                                  }
                                  onBlur={
                                    formikShippingAddressUpdate.handleBlur
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                      <Button
                        variant="secondary"
                        onClick={() => setEditingAddress(undefined)}
                      >
                        {t("text_cancel")}
                      </Button>
                      <Button
                        type="submit"
                        disabled={formikShippingAddressUpdate.isSubmitting}
                      >
                        {formikShippingAddressUpdate.isSubmitting ? (
                          <>
                            <Spinner />
                            {t("text_processing")}
                          </>
                        ) : (
                          t("text_update")
                        )}
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}

export const ShippingResource: ResourceProps = {
  name: "Shipping Address",
  path: "shipping",
};
