import Slider, { type Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { createGlobalStyle } from "styled-components";

import { type Product } from "../../../graphql/shop";
import ProductFeatured from "./ProductFeatured";

const GlobalStyle = createGlobalStyle`
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
  // .slick-slide figure::before {
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  //   background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,1) 100%);
  // }
  .slick-slider .slick-dots{
    width: auto;
    right: 1.5rem;
    bottom: 1.5rem;
    z-index: 3;
  }
  .slick-dots li{
    margin: 0;
  }
  .slick-dots li button{
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    padding: 0;
  }
  .slick-dots li button:before{
    color: rgba(var(--color-primary),1);
    font-size: 10px;
    opacity: .5;
  }
  .slick-dots li.slick-active button:before{
    color: rgba(var(--color-primary),1);
    opacity: 1;
  }
`;

export enum FeaturedType {
  RECENT_PURCHASES,
  MOST_PURCHASES,
}
type FeaturedProps = {
  type: FeaturedType;
  products: {
    id: string;
    product: Product;
  }[];
};

export default function FeaturedBlock(props: FeaturedProps) {
  const { type, products } = props;
  if (products.length === 0) return null;
  const filteredFeatured =
    products.length > 3 ? products.slice(0, 5) : products;

  const settings: Settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <GlobalStyle />
      <div className="relative flex w-full overflow-hidden rounded-2xl border border-gray-200">
        <h3 className="absolute top-5 left-5 z-10 text-sm font-medium uppercase tracking-wide text-black md:top-7 md:left-8">
          {type === FeaturedType.MOST_PURCHASES
            ? "Most Popular Products"
            : "Recent Purchases"}
        </h3>
        <Slider {...settings} className="w-full">
          {filteredFeatured.map((p) => {
            const product = p.product;
            const currentVariant = p.product.variants.find(
              (variant) => variant.id === p.id
            );
            if (!currentVariant) return null;
            return (
              <ProductFeatured
                key={p.id}
                product={product}
                variant={currentVariant}
              />
            );
          })}
        </Slider>
      </div>
    </>
  );
}
