import { DateTime, Settings } from "luxon";
import { Variant } from "../../graphql/shop";
import { classNames } from "../../utils";

Settings.defaultLocale = "en-AU";

function isExpired(date: string) {
  if (!date) return false;
  const now = DateTime.now().toISO();
  return date < now;
}

export function Price({
  currentVariant,
  className,
}: {
  currentVariant: Variant;
  className: string;
}) {
  const { customerSpecialPrice, comparePrice, customerBasePrice } =
    currentVariant;

  return (
    <div className={classNames("flex space-x-2", className)}>
      {comparePrice && comparePrice > 0 ? (
        <span className="text-[.8em] text-current line-through">
          ${comparePrice?.toFixed(2)}
        </span>
      ) : customerSpecialPrice ? (
        <span className="text-[.8em] text-current line-through">
          ${customerBasePrice?.toFixed(2)}
        </span>
      ) : null}

      {customerSpecialPrice && !isExpired(customerSpecialPrice.endDate) ? (
        <span className={classNames("text-red-500")}>
          ${parseInt(customerSpecialPrice.price)?.toFixed(2)}
        </span>
      ) : (
        <span className="text-primary">${customerBasePrice?.toFixed(2)}</span>
      )}
    </div>
  );
}
