import { DateTime, Settings } from "luxon";
import { useTranslation } from "react-i18next";
import { Order } from "../../graphql/order";

Settings.defaultLocale = "en-AU";

export function PrintTemplate({ order }: { order: Order }) {
  const { i18n } = useTranslation();
  if (!order) return null;

  return (
    <>
      <div className="py-8 px-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-semibold uppercase">
              Alpha Fresh Foods
            </h2>
            <p className="font-normal uppercase">Order Details</p>
          </div>
          <div className="w-1/4">
            <img src="/logo.png" alt="logo" />
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col justify-between">
        <div className="px-4 sm:px-6">
          <div className="space-y-6 pb-5">
            <div>
              <div className="text-base text-gray-900">
                Order No.{" "}
                <span className="font-medium">#{order?.orderNumber}</span>
              </div>
              <div className="text-base text-gray-900">
                Order created on{" "}
                <time dateTime={order.createdAt} className="font-medium">
                  {DateTime.fromISO(order.createdAt, {
                    locale: i18n.language,
                  }).toLocaleString(DateTime.DATE_MED)}
                </time>
              </div>
            </div>
            <div className="-mx-4 mt-8 flex flex-col rounded-lg border border-gray-300 pb-4 sm:-mx-6 md:mx-0">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Rate
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item) => (
                    <tr key={item.id} className="border-b border-gray-200">
                      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">
                          {item.variant.product.name}
                        </div>
                        <div className="mt-0.5 text-gray-500 sm:hidden">
                          {item.quantity} quantity at {item.price}
                        </div>
                      </td>
                      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                        {item.quantity}
                      </td>
                      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                        ${item.price}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6">
                        ${item.price * item.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th
                      scope="row"
                      colSpan={3}
                      className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                    >
                      Subtotal
                    </th>
                    <th
                      scope="row"
                      className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                    >
                      Subtotal
                    </th>
                    <td className="pl-3 pr-4 pt-6 text-right text-base text-gray-500">
                      ${order.subTotal}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      colSpan={3}
                      className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                    >
                      Total
                    </th>
                    <th
                      scope="row"
                      className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                    >
                      Total
                    </th>
                    <td className="pl-3 pr-4 pt-4 text-right text-lg font-semibold text-gray-900">
                      ${order.total}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="mb-5">
                <h3 className="text-base font-medium text-gray-900">
                  Delivery Address
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {`${order.shippingAddress.address} ${order.shippingAddress.suburb} ${order.shippingAddress.state} ${order.shippingAddress.postcode}`}
                </p>
              </div>
              {order.notes ? (
                <div className="mb-5">
                  <h3 className="text-base font-medium text-gray-900">
                    Delivery Note
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{order.notes}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
