import { Role } from "./";

const ACCESS_LOCAL_STORAGE_KEY = "afc-access";
const REFRESH_LOCAL_STORAGE_KEY = "afc-refresh";

const getAccess = (): string | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    const lsValue = localStorage.getItem(ACCESS_LOCAL_STORAGE_KEY);
    if (lsValue) {
      return lsValue;
    } else {
      return;
    }
  } catch (error) {
    console.error("ACCESS LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAccess = (lsValue: string) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(ACCESS_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("ACCESS LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAccess = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(ACCESS_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("ACCESS LOCAL STORAGE REMOVE ERROR", error);
  }
};

const getRefresh = (): string | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    const lsValue = localStorage.getItem(REFRESH_LOCAL_STORAGE_KEY);
    if (lsValue) {
      return lsValue;
    } else {
      return;
    }
  } catch (error) {
    console.error("REFRESH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setRefresh = (lsValue: string) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(REFRESH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("REFRESH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeRefresh = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(REFRESH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("REFRESH LOCAL STORAGE REMOVE ERROR", error);
  }
};

const PROTECTED_RESOURCE_LIST: string[] = [
  "protected",
  "Permissions",
  "Resources",
  "create-permissions",
  "update-permissions",
  "destroy-permissions",
  "create-resources",
  "update-resources",
  "destroy-resources",
];
const isAuthorizedForResource = (
  role: Role | undefined,
  resources?: string[]
) => {
  if (!resources || resources?.length === 0) return true;
  if (!role) return false;
  if (resources.some((resource) => PROTECTED_RESOURCE_LIST.includes(resource)))
    return false;
  return resources.some((resource) => {
    return role.permissions.some((p) => p.permissionName === resource);
  });
};

const isAuthorizedForProtectedResource = (
  role: Role | undefined,
  resources?: string[]
) => {
  if (!resources || resources?.length === 0) return true;
  if (!role) return false;
  return resources.some(
    (resource) => !PROTECTED_RESOURCE_LIST.includes(resource)
  );
};

const sessionExpired = () => {
  removeAccess();
  removeRefresh();
  window.location.href = "/auth/expired";
};

export {
  getAccess,
  setAccess,
  removeAccess,
  ACCESS_LOCAL_STORAGE_KEY,
  getRefresh,
  setRefresh,
  removeRefresh,
  REFRESH_LOCAL_STORAGE_KEY,
  isAuthorizedForResource,
  isAuthorizedForProtectedResource,
  PROTECTED_RESOURCE_LIST,
  sessionExpired,
};
