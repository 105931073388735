import { Fragment, useState } from "react";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { useAuth } from "../../auth";
import { Button } from "../../../components/form/";

import { useTranslation } from "react-i18next";
import { Spinner } from "../../../animations";
import FormProfile from "./components/FormProfile";
import { useAccount } from "../../../contexts/GenContext";

import { Head } from "../../../components/core";
import { PageTitle } from "../../../components/page";
import { CustomerType, UserModel } from "../../auth/core";
import { ShippingAddress } from "../../../graphql/order";

const UPDATE_CUSTOMER_PROFILE = gql`
  mutation CustomerProfileUpdate(
    $customerName: String
    $companyName: String
    $firstName: String
    $lastName: String
    $profileImageUrl: String
    $storeEmail: String
    $storeContact: String
    $storePhoneNumber: String
    $storeFax: String
    $abnNo: String
    $deliveryInstruction: String
    $emailSpecials: Boolean
    $emailImportantUpdates: Boolean
  ) {
    customerProfileUpdate(
      input: {
        params: {
          customerName: $customerName
          companyName: $companyName
          firstName: $firstName
          lastName: $lastName
          profileImageUrl: $profileImageUrl
          storeEmail: $storeEmail
          storeContact: $storeContact
          storePhoneNumber: $storePhoneNumber
          storeFax: $storeFax
          abnNo: $abnNo
          deliveryInstruction: $deliveryInstruction
          emailSpecials: $emailSpecials
          emailImportantUpdates: $emailImportantUpdates
        }
      }
    ) {
      customer {
        abnNo
        customerCode
        customerName
        companyName
        firstName
        lastName
        profileImageUrl
        customerPlu
        customerType
        lastActiveCustomerAgent
        lastActiveIp
        lastActiveLocation
        packingInstruction
        email
        storeEmail
        storePhoneNumber
        storeContact
        storeFax
        deliveryInstruction
        details {
          emailImportantUpdates
          emailSpecials
          mobileNumber
          phoneNumber
          postalAddress
          postcode
          state
          suburb
          venueFoodSpend {
            id
            spendRange
          }
          venueType {
            id
            name
          }
        }
        shippingAddresses {
          id
          address
          suburb
          state
          postcode
        }
      }
    }
  }
`;

type IAccountProfile = {
  customerName: string;
  companyName: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  storeEmail: string;
  storeContact: string;
  storePhoneNumber: string;
  storeFax: string;
  abnNo: string;
  deliveryInstruction: string;
  emailSpecials: boolean;
  emailImportantUpdates: boolean;
};

export default function ProfilePage() {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const { currentUser, setCurrentUser } = useAuth();
  const { saveAccount } = useAccount();
  const [edit, setEdit] = useState(false);
  const [updateCustomerProfile] = useMutation(UPDATE_CUSTOMER_PROFILE);

  const handleSubmitProfile = (
    values: IAccountProfile,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!currentUser) return;
    updateCustomerProfile({
      variables: {
        customerName:
          currentUser.customerType === CustomerType.COMPANY
            ? values.companyName
            : values.customerName,
        companyName:
          currentUser.customerType === CustomerType.COMPANY
            ? values.companyName
            : values.customerName,
        firstName: values.firstName,
        lastName: values.lastName,
        profileImageUrl: values.profileImageUrl,
        storeEmail: values.storeEmail,
        storeContact: values.storeContact,
        storePhoneNumber: values.storePhoneNumber,
        storeFax: values.storeFax,
        abnNo: values.abnNo,
        deliveryInstruction: values.deliveryInstruction,
        emailSpecials: values.emailSpecials,
        emailImportantUpdates: values.emailImportantUpdates,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerProfileUpdate) {
          const { customer } = data.customerProfileUpdate;
          if (!currentUser) return;

          const primaryAddress = customer?.shippingAddresses?.find(
            (a: ShippingAddress) => a?.primaryAddress
          );
          const updatedUser: UserModel = {
            ...customer,
            primaryAddress: primaryAddress
              ? primaryAddress
              : customer?.shippingAddresses?.length > 0
              ? customer?.shippingAddresses[0]
              : null,
          };

          setCurrentUser(updatedUser);
          saveAccount(updatedUser);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer update failed",
          message: error.message,
        });
      });
  };

  const handleCancelProfile = () => {
    setEdit(false);
  };

  if (!currentUser) return <Spinner />;

  return (
    <>
      <Head title={ProfileResource.name} />
      <div className="w-prev-container w-full pb-10">
        <header className="mb-4 flex items-end justify-between md:mb-8">
          <div>
            <PageTitle className="text-primary-900">
              {ProfileResource.name}
            </PageTitle>
            <p className="text-sm text-gray-500">
              View and update your profile details
            </p>
          </div>
          {!edit && <Button onClick={() => setEdit(true)}>Edit</Button>}
        </header>

        {edit ? (
          <FormProfile
            initialValues={{
              firstName: currentUser.firstName || "",
              lastName: currentUser.lastName || "",
              customerName: currentUser.customerName || "",
              companyName: currentUser.companyName || "",
              customerType: currentUser.customerType ?? 0,
              profileImageUrl: currentUser.profileImageUrl || "",
              abnNo: currentUser.abnNo || "",
              storeEmail: currentUser.storeEmail || "",
              storeContact: currentUser.storeContact || "",
              storePhoneNumber: currentUser.storePhoneNumber || "",
              storeFax: currentUser.storeFax || "",
              emailSpecials: currentUser.details.emailSpecials || false,
              emailImportantUpdates:
                currentUser.details.emailImportantUpdates || false,
              deliveryInstruction: currentUser.deliveryInstruction || "",
            }}
            onSubmit={handleSubmitProfile}
            onCancel={handleCancelProfile}
          />
        ) : (
          <>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {currentUser.customerType === CustomerType.COMPANY ? (
                <Fragment>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">
                      {t("text_company_name")}
                    </span>
                    <p className="font-light">{currentUser.companyName}</p>
                  </div>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">
                      {t("text_abn_number")}
                    </span>
                    <p className="font-light">{currentUser.abnNo}</p>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">
                      {t("text_firstname")}
                    </span>
                    <p className="font-light">{currentUser.firstName}</p>
                  </div>
                  <div>
                    <span className="block text-sm font-medium text-gray-700">
                      {t("text_lastname")}
                    </span>
                    <p className="font-light">{currentUser.lastName}</p>
                  </div>
                </Fragment>
              )}
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_store_email")}
                </span>
                <p className="font-light">{currentUser.storeEmail}</p>
              </div>
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_store_phone")}
                </span>
                <p className="font-light">{currentUser.storePhoneNumber}</p>
              </div>
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_store_contact")}
                </span>
                <p className="font-light">{currentUser.storeContact}</p>
              </div>
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_store_fax")}
                </span>
                <p className="font-light">{currentUser.storeFax}</p>
              </div>
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_email_important_updates")}
                </span>
                <p className="font-light">
                  {currentUser.details.emailImportantUpdates ? "Yes" : "No"}
                </p>
              </div>
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_email_specials")}
                </span>
                <p className="font-light">
                  {currentUser.details.emailSpecials ? "Yes" : "No"}
                </p>
              </div>
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  {t("text_delivery_instruction")}
                </span>
                <p className="font-light">{currentUser.deliveryInstruction}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export const ProfileResource: ResourceProps = {
  name: "Profile",
  path: "profile",
};
