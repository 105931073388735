import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Outlet,
  RouteObject,
  useSearchParams,
  useRoutes,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { InView } from "react-intersection-observer";
import { Transition, Listbox } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  BarsArrowDownIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { DebounceInput } from "react-debounce-input";
import Select, { MultiValue } from "react-select";

import HeaderLayout from "../../layouts/HeaderLayout";
import { Logo } from "../../animations";
import { ErrorFallback, Head, NotFound } from "../../components/core";
import { PageTitle } from "../../components/page";
import {
  FETCH_PRODUCTS,
  FETCH_DEPARTMENTS,
  FETCH_CATEGORIES,
  type Product,
  SortBy,
  Department,
  Category,
} from "../../graphql/shop";
import { classNames } from "../../utils";
import { SelectWrapper, selectStyles } from "../../components/form";
import { ProductCard, ProductSidebar } from "../../components/product";

export const ShopPage = () => {
  let routes: RouteObject[] = [
    {
      path: "/",
      element: <ProductListing />,
      children: [
        {
          path: "/:handle",
          element: <ProductSidebar archive="shop" />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
};

export const ShopResource: ResourceProps = {
  name: "Shop",
  path: "/shop/*",
};

function ProductListing() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.ASC);
  const [searchQuery, setSearchQuery] = useState<string | null>(
    searchParams.get("query") || ""
  );
  const [departments, setDepartments] = useState<MultiValue<OptionProps>>([]);
  const [categories, setCategories] = useState<MultiValue<OptionProps>>([]);

  useEffect(() => {
    setSearchQuery(searchParams.get("query") || "");
  }, [searchParams]);

  const handleClear = useCallback(() => {
    setSearchParams({ query: "" });
    setDepartments([]);
    setCategories([]);
  }, [setSearchParams]);

  const { data: departmentsData, loading: departmentsLoading } =
    useQuery(FETCH_DEPARTMENTS);

  const { data: categoriesData, loading: categoriesLoading } =
    useQuery(FETCH_CATEGORIES);

  const departmentId =
    departments.length > 0 ? departments.flatMap((d) => d.value) : null;
  const categoryIds =
    categories.length > 0 ? categories.flatMap((c) => c.value) : null;
  const searchFilter = [];
  if (searchQuery) searchFilter.push("keyword");
  if (departmentId) searchFilter.push("departments");
  if (categoryIds) searchFilter.push("categories");

  const { data, loading, error, fetchMore, networkStatus } = useQuery(
    FETCH_PRODUCTS,
    {
      variables: {
        first: 10,
        searchQuery,
        sortBy,
        searchFilter,
        departmentId,
        categoryIds,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  if (error) return <ErrorFallback error={error} />;

  // if (networkStatus === 1) {
  //   return <div>Loading...</div>;
  // }

  // const isRefetching = networkStatus === 3;

  // console.log("isRefetching", isRefetching);

  return (
    <>
      <div className="w-prev-container w-full pb-10 3xl:px-8">
        <Head title={ShopResource.name} />
        <HeaderLayout>
          <PageTitle>
            <span>{ShopResource.name}</span>
            <span className="ml-3 text-lg font-normal text-gray-400">
              {data?.fetchProducts?.totalCount ?? 0} Products
            </span>
          </PageTitle>
        </HeaderLayout>
        <div className="px-4 md:mr-4 md:px-8">
          <div className="mt-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <div className="flex items-center rounded-lg bg-greyish pl-3 text-primary-900/50">
                <MagnifyingGlassIcon className="h-5 w-5 text-primary-900/70" />
                <span className="ml-3 block h-4 w-[1px] bg-gray-300"></span>
                <DebounceInput
                  type="search"
                  className={classNames(
                    "ml-1 h-12 w-full border-none bg-transparent p-2 font-normal",
                    "shadow-none outline-none focus:ring-0",
                    "placeholder-primary-900/50 placeholder-opacity-100"
                  )}
                  placeholder="Search for name"
                  minLength={2}
                  debounceTimeout={300}
                  value={searchQuery ?? ""}
                  onChange={(e) => {
                    setSearchParams({
                      query: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex items-center rounded-lg bg-greyish pl-3 text-primary-900/50">
              <FunnelIcon className="h-5 w-5 text-primary-900/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-300"></span>
              <SelectWrapper className="w-full font-normal text-primary-900/70">
                <Select
                  className="w-full"
                  closeMenuOnSelect={true}
                  styles={selectStyles}
                  value={departments}
                  options={departmentsData?.fetchDepartments.map(
                    (d: Department) => ({
                      value: d.id,
                      label: d.name,
                    })
                  )}
                  onChange={setDepartments}
                  isLoading={departmentsLoading}
                  isMulti
                  placeholder="Filter by department"
                />
              </SelectWrapper>
            </div>
            <div className="flex items-center rounded-lg bg-greyish pl-3 text-primary-900/50">
              <FunnelIcon className="h-5 w-5 text-primary-900/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-300"></span>
              <SelectWrapper className="w-full font-normal text-primary-900/70">
                <Select
                  className="w-full"
                  closeMenuOnSelect={true}
                  styles={selectStyles}
                  value={categories}
                  options={categoriesData?.fetchCategories.map(
                    (d: Category) => ({
                      value: d.id,
                      label: d.name,
                    })
                  )}
                  onChange={setCategories}
                  isLoading={categoriesLoading}
                  isMulti
                  placeholder="Filter by category"
                />
              </SelectWrapper>
            </div>
            {/* <div className="hidden">
              <Popover className="relative z-20">
                <Popover.Button className="flex h-12 w-full items-center rounded-lg bg-greyish pl-3 pr-2 font-medium text-primary-900/80">
                  <AdjustmentsHorizontalIcon className="h-5 w-5 text-primary-900/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-300"></span>
                  <span className="ml-3 block">
                    <span>Filters</span>
                    <span className="ml-2 text-sm">
                      ({departments.length + categories.length})
                    </span>
                  </span>
                  <ChevronDownIcon
                    className="ml-auto h-5 w-5"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute right-0 z-10 mt-4 origin-top-right md:mt-2">
                    <div className="w-screen max-w-[280px] flex-auto rounded-tl-2xl rounded-br-2xl rounded-bl-2xl bg-white pb-1 text-sm leading-6 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-gray-900/5 lg:max-w-md">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="16px"
                        height="16px"
                        viewBox="0 0 110.9 96"
                        enableBackground="new 0 0 110.9 96"
                        className="absolute -top-3 right-0 -z-0"
                        fill="#ffffff"
                        style={{
                          filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                        }}
                      >
                        <polygon points="110.9,0 0,96 110.9,96 " />
                      </svg>
                      <div className="space-y-2 p-4">
                        <div>
                          <div className="mb-1 block text-sm font-medium text-gray-900">
                            Select Department
                          </div>
                          <SelectWrapper className="rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm">
                            <Select
                              closeMenuOnSelect={true}
                              styles={selectStyles}
                              value={departments}
                              options={departmentsData?.fetchDepartments.map(
                                (d: Department) => ({
                                  value: d.id,
                                  label: d.name,
                                })
                              )}
                              onChange={setDepartments}
                              isLoading={departmentsLoading}
                              isMulti
                            />
                          </SelectWrapper>
                        </div>
                        <div>
                          <div className="mb-1 block text-sm font-medium text-gray-900">
                            Select Category
                          </div>
                          <SelectWrapper className="rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm">
                            <Select
                              closeMenuOnSelect={true}
                              styles={selectStyles}
                              value={categories}
                              options={categoriesData?.fetchCategories.map(
                                (d: Category) => ({
                                  value: d.id,
                                  label: d.name,
                                })
                              )}
                              onChange={setCategories}
                              isLoading={categoriesLoading}
                              isMulti
                            />
                          </SelectWrapper>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div> */}
            <div className="relative z-20">
              <Listbox value={sortBy} onChange={setSortBy}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      Set result order
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="flex h-12 w-full items-center rounded-lg bg-greyish pl-3 pr-2 font-medium text-primary-900/80">
                        <BarsArrowDownIcon className="h-5 w-5 text-primary-900/70" />
                        <span className="ml-3 block h-4 w-[1px] bg-gray-300"></span>
                        <span className="ml-3 block">
                          {sortBy === SortBy.DSC
                            ? "Alphabetically Z-A"
                            : sortBy === SortBy.ASC
                            ? "Alphabetically A-Z"
                            : "Sortby"}
                        </span>
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="ml-auto h-5 w-5"
                        >
                          <path
                            fill="currentColor"
                            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                          ></path>
                        </svg>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 px-1.5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm md:px-2.5">
                          <Listbox.Option
                            className="select-none py-0.5"
                            value={SortBy.ASC}
                          >
                            {({ selected }) => (
                              <div className="py-0.5">
                                <span
                                  className={classNames(
                                    "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2",
                                    "hover:bg-primary-700/10 hover:text-primary-700"
                                  )}
                                >
                                  {selected ? (
                                    <CheckIcon
                                      className="mr-2 h-4 w-4 flex-shrink-0 text-center text-xl leading-6 text-current"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <span
                                      className={classNames(
                                        "mr-2 h-4 w-4 flex-shrink-0 text-center text-xl leading-6 text-current"
                                      )}
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className="flex-1 truncate">
                                    Alphabetically A-Z
                                  </span>
                                </span>
                              </div>
                            )}
                          </Listbox.Option>
                          <Listbox.Option
                            className="select-none py-0.5"
                            value={SortBy.DSC}
                          >
                            {({ selected }) => (
                              <div className="py-0.5">
                                <span
                                  className={classNames(
                                    "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2",
                                    "hover:bg-primary-700/10 hover:text-primary-700"
                                  )}
                                >
                                  {selected ? (
                                    <CheckIcon
                                      className="mr-2 h-4 w-4 flex-shrink-0 text-center text-xl leading-6 text-current"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <span
                                      className={classNames(
                                        "mr-2 h-4 w-4 flex-shrink-0 text-center text-xl leading-6 text-current"
                                      )}
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className="flex-1 truncate">
                                    Alphabetically Z-A
                                  </span>
                                </span>
                              </div>
                            )}
                          </Listbox.Option>
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className="flex flex-wrap">
            {departments.map((d) => (
              <button
                key={d.value}
                type="button"
                className={classNames(
                  "mr-2 mt-2 inline-flex items-center rounded-lg border-none bg-greyish p-2 px-4 py-3 text-sm font-medium text-primary-900/70"
                )}
                onClick={() => {
                  setDepartments(
                    departments.filter((dept) => dept.value !== d.value)
                  );
                }}
              >
                {d.label}
                <XMarkIcon className="ml-4 h-4 w-4 text-gray-500" />
              </button>
            ))}
            {categories.map((c) => (
              <button
                key={c.value}
                type="button"
                className={classNames(
                  "mr-2 mt-2 inline-flex items-center rounded-lg border-none bg-greyish p-2 px-4 py-3 text-sm font-medium text-primary-900/70"
                )}
                onClick={() => {
                  setCategories(
                    categories.filter((dept) => dept.value !== c.value)
                  );
                }}
              >
                {c.label}
                <XMarkIcon className="ml-4 h-4 w-4 text-gray-500" />
              </button>
            ))}
            {departments.length > 0 || categories.length > 0 ? (
              <button
                type="button"
                className={classNames(
                  "ml-4 mt-2 inline-flex items-center rounded-lg border border-gray-200 bg-transparent p-2 px-4 py-3 text-sm font-medium text-primary-900/90"
                )}
                onClick={handleClear}
              >
                Clear all filters
                <TrashIcon className="ml-4 h-4 w-4 text-gray-500" />
              </button>
            ) : null}
          </div>

          {data?.fetchProducts?.edges?.length > 0 ? (
            <div className="mt-6 flex flex-col space-y-4">
              {data.fetchProducts.edges.map((edge: { node: Product }) => (
                <ProductCard
                  key={edge.node.id}
                  archive="shop"
                  product={edge.node}
                />
              ))}
            </div>
          ) : !loading &&
            !searchQuery &&
            !departments.length &&
            !categories.length ? (
            <div className="py-10 text-center md:py-16 xl:py-20">
              <h3 className="text-xl font-semibold text-gray-900">
                No products found
              </h3>
              <p className="text-gray-700">Please try again later.</p>
            </div>
          ) : (
            <div className="py-10 text-center md:py-16 xl:py-20">
              <h3 className="text-xl font-semibold text-gray-900">
                No products found
              </h3>
              <p className="text-gray-700">
                Search didn't match any products. <br />
                Please try again.
              </p>
              <button
                type="button"
                className="mt-4 inline-flex items-center rounded-lg border border-gray-200 bg-transparent p-2 px-4 py-3 text-sm font-medium text-gray-900"
                onClick={handleClear}
              >
                Clear Search
              </button>
            </div>
          )}

          {data?.fetchProducts && (
            <InView
              disabled={!data.fetchProducts.pageInfo.hasNextPage}
              onChange={async (inView) => {
                console.log("inView", inView);
                if (inView) {
                  await fetchMore({
                    variables: {
                      cursor: data.fetchProducts.pageInfo.endCursor,
                    },
                  });
                }
              }}
            />
          )}
          {loading ? (
            <div className="mt-6 flex justify-center py-10 md:py-16 xl:py-20">
              <Logo />
            </div>
          ) : null}
        </div>
      </div>
      <Outlet />
    </>
  );
}
