import { ApolloQueryResult } from "@apollo/client";
import { DELETE_CART, FETCH_CART, UPDATE_CART } from "../../../graphql/cart";
import { client } from "../../auth";

const getCart = async (cartId: string): Promise<ApolloQueryResult<any>> => {
  const response = await client.query({
    query: FETCH_CART,
    variables: {
      cartId,
    },
    fetchPolicy: "network-only",
  });
  return response;
};

const setCart = async (cartId: string, productId: number, quantity: number) => {
  const response = await client.mutate({
    mutation: UPDATE_CART,
    variables: {
      cartId,
      productId,
      quantity,
    },
  });
  return response;
};

const deleteCart = async (cartId: string, productIds: number[]) => {
  const response = await client.mutate({
    mutation: DELETE_CART,
    variables: {
      cartId,
      productIds,
    },
  });
  return response;
};

export { getCart, setCart, deleteCart };
