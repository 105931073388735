import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LockOpenIcon, XCircleIcon } from "@heroicons/react/24/solid";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import {
  Field,
  FieldPassword,
  passwordValidator,
} from "../../../components/form/";
import { Logo, Spinner } from "../../../animations";
import logo from "../../../assets/logo.svg";
import { Head } from "../../../components/core";
import { RadioGroup } from "@headlessui/react";
import { classNames, toSlug } from "../../../utils";
import styled, { createGlobalStyle } from "styled-components";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  FETCH_ABN_DETAILS,
  IAbnDetails,
  IFetchAbnDetails,
  formatAbnDetails,
} from "../../../graphql/account";
import { CustomerType } from "../core";

const { REACT_APP_NAME } = process.env;

const GlobalStyle = createGlobalStyle`
html {
  background-color: #f7f7f7;
}
`;
const Box = styled.div`
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.025);
  &:hover {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.05);
  }
`;

const REGISTER = gql`
  mutation Register(
    $firstName: String
    $lastName: String
    $email: String!
    $phoneNumber: String!
    $password: String!
    $customerType: Int!
    $companyName: String
    $abnNo: String
    $acceptTerms: Boolean!
  ) {
    register(
      input: {
        params: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          password: $password
          customerType: $customerType
          companyName: $companyName
          abnNo: $abnNo
          acceptTerms: $acceptTerms
        }
      }
    ) {
      message
    }
  }
`;

declare module "yup" {
  interface StringSchema {
    strongPassword(threshold?: number, strength?: number): this;
  }
}

Yup.addMethod(Yup.string, "strongPassword", strongPasswordMethod);

function strongPasswordMethod(this: any, threshold: number, strength: number) {
  return this.test(
    "strongPasswordTest",
    null,
    (value: string, context: any) => {
      const { path, createError } = context;
      try {
        passwordValidator(value, threshold, strength);
        return true;
      } catch (e: any) {
        return createError({
          path,
          message: e.message,
        });
      }
    }
  );
}

const RegisterSchema = Yup.object().shape({
  customerType: Yup.number().required("Required"),
  firstName: Yup.string().when("customerType", {
    is: (val: number) => val === CustomerType.INDIVIDUAL,
    then: (schema) =>
      schema.min(2, "Too Short!").max(80, "Too Long!").required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  lastName: Yup.string().when("customerType", {
    is: (val: number) => val === CustomerType.INDIVIDUAL,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  companyName: Yup.string().when("customerType", {
    is: (val: number) => val === CustomerType.COMPANY,
    then: (schema) =>
      schema.min(2, "Too Short!").max(80, "Too Long!").required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber: Yup.string().required("Required"),
  password: Yup.string().required("Required").strongPassword(7),
  passwordConfirmation: Yup.string()
    .required("Password confirmation is required")
    .when("new-password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new-password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  abnNo: Yup.string().nullable(),
  acceptTerms: Yup.boolean().oneOf([true], "Accept Terms is required"),
});

export function Register() {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();

  let navigate = useNavigate();

  const [loadingAbn, setLoadingAbn] = useState(false);
  const [abnDetails, setAbnDetails] = useState<IAbnDetails | null>(null);

  const [fetchAbnDetails] = useLazyQuery<{
    fetchAbnDetails: IFetchAbnDetails;
  }>(FETCH_ABN_DETAILS);

  const [registerFunction, { error }] = useMutation(REGISTER);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    actions.setSubmitting(false);
    registerFunction({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
        customerType: values.customerType,
        companyName: values.companyName,
        abnNo: values.abnNo,
        acceptTerms: values.acceptTerms,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);

        if (data?.register) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Registration successful",
            message: data.register?.message,
          });
          return navigate("/auth/login", { replace: true });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Registration failed",
            message: "Please try again.",
          });
        }
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Registration failed",
          message: error.message,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      passwordConfirmation: "",
      customerType: CustomerType.COMPANY,
      companyName: "",
      abnNo: "",
      acceptTerms: false,
    },
    validationSchema: RegisterSchema,
    onSubmit: handleSubmit,
  });

  const fetchingAbnDetails = useCallback(
    (abnNo: string) => {
      setLoadingAbn(true);
      fetchAbnDetails({
        variables: {
          abnNo,
        },
      })
        .then(({ data }) => {
          if (data?.fetchAbnDetails?.details) {
            if (data?.fetchAbnDetails?.details?.businessEntity201205) {
              const formated = formatAbnDetails(data?.fetchAbnDetails);
              setAbnDetails(formated);
            } else {
              setAbnDetails(null);
            }
          } else {
            setAbnDetails(null);
          }
        })
        .catch((e) => {
          setAbnDetails(null);
        })
        .finally(() => {
          setLoadingAbn(false);
        });
    },
    [fetchAbnDetails]
  );

  useEffect(() => {
    if (formik.values.abnNo.length < 11) {
      setAbnDetails(null);
      return;
    }
    fetchingAbnDetails(formik.values.abnNo);
  }, [fetchingAbnDetails, formik.values.abnNo]);

  const { errors, touched } = formik;

  const customerTypes = [
    {
      label: "Company",
      value: 1,
    },
    {
      label: "Individual",
      value: 0,
    },
  ];

  return (
    <>
      <GlobalStyle />
      <Head title="Register" />
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-3xl">
          <Box className={classNames("rounded-xl bg-gray-100 p-2.5")}>
            <div className="rounded-xl bg-white px-4 py-12">
              <header>
                <img
                  className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
                  src={logo}
                  alt={REACT_APP_NAME}
                />
                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
                  {t("heading_register")}
                </h2>
              </header>

              <div className="mt-8 space-y-6">
                <form onSubmit={formik.handleSubmit} className="space-y-3">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="md:col-span-2">
                      <RadioGroup
                        value={formik.values.customerType}
                        onChange={(value: number) => {
                          formik.setFieldValue("customerType", value);
                        }}
                      >
                        <RadioGroup.Label className="sr-only">
                          Customer Type
                        </RadioGroup.Label>
                        <div className="grid grid-cols-2 gap-4">
                          {customerTypes.map((option, index) => (
                            <RadioGroup.Option
                              key={option.label}
                              value={option.value}
                              className={({ active, checked }) =>
                                classNames(
                                  active
                                    ? "ring-2 ring-primary-600 ring-offset-2"
                                    : "",
                                  checked
                                    ? "border-primary-600 bg-primary-50 text-primary hover:bg-primary-100"
                                    : "border-gray-200 bg-white text-gray-600 hover:bg-gray-50",
                                  "text-md flex cursor-pointer items-center justify-center rounded-md border-2 py-3 px-3 font-medium ring-0 sm:flex-1 md:py-5"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <RadioGroup.Label
                                  as="span"
                                  className="flex items-center"
                                >
                                  <span
                                    className={classNames(
                                      "mr-4 block h-5 w-5 rounded-full border-4 transition-all duration-300 ease-in-out",
                                      checked
                                        ? "border-primary bg-transparent"
                                        : "border-gray-200 bg-gray-100"
                                    )}
                                  />
                                  <span>{option.label}</span>
                                </RadioGroup.Label>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                    {formik.values.customerType === CustomerType.COMPANY ? (
                      <Fragment>
                        <div>
                          <Field
                            title={t("text_company_name")}
                            name="companyName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                            touched={touched.companyName}
                            errors={errors.companyName}
                          />
                        </div>
                        <div>
                          <Field
                            title={t("text_abn_no")}
                            name="abnNo"
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedValue = value.replace(/[^0-9]/g, "");
                              formik.setFieldValue("abnNo", updatedValue);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.abnNo}
                            touched={formik.touched.abnNo}
                            errors={formik.errors.abnNo}
                          />
                          {loadingAbn ? (
                            <div className="mt-4 text-center">
                              <Logo />
                            </div>
                          ) : abnDetails ? (
                            <div className="mt-4 rounded-lg bg-primary-50 p-2 text-sm text-primary-900 md:p-4">
                              <ul className="space-y-2">
                                <li>
                                  <b>{abnDetails.name}</b>
                                </li>
                                <li>
                                  ABN status: Active from{" "}
                                  {abnDetails.activeFrom}
                                </li>
                                <li>Entity type: {abnDetails.type}</li>
                                <li>
                                  Goods & Services Tax (GST): Registered from{" "}
                                  {abnDetails.registeredFrom}
                                </li>
                                <li>
                                  Main business location: {abnDetails.address}
                                </li>
                                <li className="flex flex-wrap">
                                  <span className="mr-2">Buiness names:</span>
                                  <Fragment>
                                    {abnDetails.business.map((b) => (
                                      <span
                                        key={`b-${toSlug(b)}`}
                                        className="mb-1 mr-1 inline-flex items-center rounded-full bg-primary-700 pl-2 pr-3 text-xs font-normal leading-6 text-white"
                                      >
                                        <span className="mr-1 h-3 w-3 rounded-full bg-white"></span>
                                        {b}
                                      </span>
                                    ))}
                                  </Fragment>
                                </li>
                              </ul>
                            </div>
                          ) : formik.values.abnNo.length === 11 ? (
                            <div className="text-center">
                              <span className="mt-4 inline-block text-sm text-red-500">
                                <sup>*</sup> {t("text_abn_no_info")}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div>
                          <Field
                            title={t("text_firstname")}
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            touched={touched.firstName}
                            errors={errors.firstName}
                          />
                        </div>
                        <div>
                          <Field
                            title={t("text_lastname")}
                            autoComplete="family-name"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            touched={touched.lastName}
                            errors={errors.lastName}
                          />
                        </div>
                      </Fragment>
                    )}
                    <div>
                      <Field
                        title={t("text_email_address")}
                        autoComplete="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        touched={touched.email}
                        errors={errors.email}
                      />
                    </div>
                    <div>
                      <Field
                        title={t("text_phone")}
                        autoComplete="tel"
                        name="phoneNumber"
                        type="tel"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        touched={touched.phoneNumber}
                        errors={errors.phoneNumber}
                      />
                    </div>
                    <div>
                      <FieldPassword
                        title={t("text_password")}
                        autoComplete="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        touched={touched.password}
                        errors={errors.password}
                      />
                    </div>
                    <div>
                      <Field
                        title={t("text_confirm_password")}
                        autoComplete="confirmation-password"
                        type="password"
                        name="passwordConfirmation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirmation}
                        touched={touched.passwordConfirmation}
                        errors={errors.passwordConfirmation}
                      />
                    </div>
                  </div>

                  <div className="flex items-start">
                    <Field
                      title={t("text_accept_terms")}
                      id="acceptTerms"
                      name="acceptTerms"
                      type="checkbox"
                      aria-describedby="acceptTerms-description"
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={false}
                      className="mt-1"
                    />
                    <label
                      htmlFor="acceptTerms"
                      className="ml-2 inline-block leading-[1]"
                    >
                      I accept the{" "}
                      <a
                        href="https://shop.alphafresh.com.au/policies/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms and conditions
                      </a>
                    </label>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      disabled={formik.isSubmitting}
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockOpenIcon
                          className="h-5 w-5 text-primary-300 group-hover:text-primary-500"
                          aria-hidden="true"
                        />
                      </span>

                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          Processing...
                        </>
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </div>

                  <div>
                    <div className="relative text-center text-base">
                      <div className="absolute top-0 bottom-0 m-auto h-px w-full bg-gray-200"></div>
                      <span className="relative z-10 inline-flex bg-white p-2">
                        Already have an account?
                      </span>
                    </div>
                    <Link
                      to="/auth/login"
                      className="group relative flex w-full justify-center rounded-md border border-gray-300 bg-transparent px-4 py-2.5 text-base font-medium text-primary hover:border-primary-500 hover:bg-primary-50 hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                      Sign In
                    </Link>
                  </div>
                </form>
                {error && (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          {error.message}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
