import { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { KeyIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import { Field, Response } from "../../../components/form";

import { AlertType, useAlert } from "../../../hooks/useAlert";

import { Spinner } from "../../../animations";
import { Head } from "../../../components/core";

import logo from "../../../assets/logo.svg";
const { REACT_APP_NAME } = process.env;

const ActivateSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount(
    $email: String!
    $token: String!
    $password: String!
  ) {
    activateAccount(
      input: { params: { email: $email, token: $token, password: $password } }
    ) {
      message
    }
  }
`;

export function Activate() {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);
  const isValidRequest = useRef(false);
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    status: false,
    title: "",
    message: "",
  });

  const ResponseAlert = useAlert({
    open: alert.status,
    title: alert.title,
    message: alert.message,
    type: AlertType.SUCCESS,
    delay: 10000,
    onDismiss: () => {
      setAlert({
        ...alert,
        status: false,
      });
      navigate("/auth/login");
    },
  });

  let [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");
  isValidRequest.current = token && email ? true : false;

  const [activateAccount] = useMutation(ACTIVATE_ACCOUNT);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: ActivateSchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      activateAccount({
        variables: {
          email: email,
          token: token,
          password: values.password,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.activateAccount) {
            setSearchParams({ token: "", email: "" });
            setAlert({
              status: true,
              title: "Account activated successfully",
              message: data.activateAccount.message,
            });
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  return (
    <>
      <Head title="Activate Account" />
      <header>
        <img
          className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
          src={logo}
          alt={REACT_APP_NAME}
        />
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          Activate Your Account
        </h2>
      </header>

      <div className="mt-8 mb-5">
        {(isValidRequest.current || alert.status) && (
          <>
            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div>
                <Field
                  title={t("text_create_password")}
                  autoComplete="password"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["password"]}
                  touched={formik.touched["password"]}
                  errors={formik.errors["password"]}
                />
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <Link
                    to="/auth/login"
                    className="font-medium text-primary-700 hover:text-primary-700"
                  >
                    Back to login page
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <KeyIcon
                      className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
                      aria-hidden="true"
                    />
                  </span>

                  {formik.isSubmitting ? (
                    <>
                      <Spinner />
                      {t("text_processing")}
                    </>
                  ) : (
                    t("text_activate_account")
                  )}
                </button>
              </div>
            </form>
            <ResponseAlert />
          </>
        )}
      </div>

      <Response
        response={response}
        onDismiss={() => {
          setResponse(null);
        }}
      />

      {!isValidRequest.current && !alert.status && (
        <div>
          <p className="mt-2 text-sm text-gray-600">
            The link you followed may be invalid or expired. Please try again.
          </p>
          <Link
            to="/auth/login"
            className="group relative mt-5 flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <KeyIcon
                className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
                aria-hidden="true"
              />
            </span>
            Back to login
          </Link>
        </div>
      )}
    </>
  );
}
