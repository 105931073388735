import { lazy, Suspense } from "react";
import {
  Link,
  RouteObject,
  useRoutes,
  Outlet,
  Navigate,
} from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { LayoutSplashScreen, NotFound } from "../../components/core";
import { classNames } from "../../utils";

import { ProfileResource } from "./pages/Profile";
import { PasswordResource } from "./pages/Password";
import { VenueResource } from "./pages/Venue";
import { OrdersResource } from "./pages/Orders";
import { ShippingResource } from "./pages/Shipping";
import { Avatar } from "../../components/presentation";
import { useAuth } from "../auth";
import { ProgressRadial } from "../../animations";
import { useAccount } from "../../contexts/GenContext";
import { CustomerType } from "../auth/core";

const ProfilePage = lazy(() => import("./pages/Profile"));
const PasswordPage = lazy(() => import("./pages/Password"));
const VenuePage = lazy(() => import("./pages/Venue"));
const OrderPage = lazy(() => import("./pages/Orders"));
const ShippingPage = lazy(() => import("./pages/Shipping"));

export function AccountPage() {
  let routes: RouteObject[] = [
    {
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Navigate to={ProfileResource.path} />,
        },
        {
          path: ProfileResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ProfilePage />
            </Suspense>
          ),
        },
        {
          path: VenueResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <VenuePage />
            </Suspense>
          ),
        },

        {
          path: PasswordResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PasswordPage />
            </Suspense>
          ),
        },
        {
          path: OrdersResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <OrderPage />
            </Suspense>
          ),
        },
        {
          path: ShippingResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ShippingPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}

function Layout() {
  const { currentUser } = useAuth();
  const { percentage, toggleAccount, open, isVerified } = useAccount();

  const navigation = [
    {
      id: "account",
      title: "Account",
      children: [
        {
          id: "profile",
          name: "Profile",
          path: "/account/profile",
          icon: "bi bi-person-gear",
        },
      ],
    },
    {
      id: "location",
      title: "Location",
      children: [
        {
          id: "venue",
          name: "Venue Details",
          path: "/account/venue",
          icon: "bi bi-map",
        },
        {
          id: "shipping",
          name: "Shipping Address",
          path: "/account/shipping",
          icon: "bi bi-truck",
        },
      ],
    },
    {
      id: "orders",
      title: "Orders",
      children: [
        {
          id: "orders",
          name: "All Orders",
          path: "/account/orders",
          icon: "bi bi-card-checklist",
        },
      ],
    },
    {
      id: "security",
      title: "Security",
      children: [
        {
          id: "password",
          name: "Change Password",
          path: "/account/password",
          icon: "bi bi-key",
        },
      ],
    },
  ];

  return (
    <div className="w-prev-container w-full flex-row md:flex">
      <div className="h-screen basis-1/3 px-4 py-4 md:py-8 md:pl-8">
        <div className="flex h-full flex-grow flex-col space-y-6 divide-y divide-gray-900 overflow-y-auto rounded-2xl bg-primary-100 p-4 text-lg md:space-y-8 md:p-6 lg:p-10">
          <div className="flex items-center rounded-full text-left focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50">
            <div className="relative mr-4 h-28 w-28 p-[3px]">
              <ProgressRadial
                progress={percentage}
                indicatorWidth={8}
                trackWidth={8}
                indicatorColor="#4ba84f"
                trackColor="#86c395"
                className="absolute top-0 left-0 z-10"
              />
              <Avatar
                className="bg-transparent"
                textClass="text-4xl font-semibold first-letter:text-gray-900/95 text-gray-900/90"
              />
              {currentUser?.customerType === CustomerType.COMPANY &&
              isVerified() ? (
                <CheckCircleIcon
                  className={classNames(
                    "absolute right-2 top-2 z-10 h-6 w-6 flex-shrink-0 rounded-full bg-primary-100 text-primary"
                  )}
                  aria-hidden="true"
                />
              ) : null}
            </div>
            {currentUser?.customerType === CustomerType.COMPANY ? (
              <div>
                <div className="font-medium">{currentUser?.companyName}</div>
                <span className="text-base text-black">Company</span>
              </div>
            ) : (
              <div>
                <div className="font-medium">{currentUser?.customerName}</div>
                <span className="text-base text-black">Individual</span>
              </div>
            )}
          </div>

          <nav className="space-y-4 pt-8 md:space-y-6">
            {navigation.map((nav) => (
              <div key={nav.id}>
                <h2 className="text-base font-medium text-black">
                  {nav.title}
                </h2>
                <ul className="mt-2">
                  {nav.children.map((child) => (
                    <li key={child.id} className="py-2">
                      <Link to={child.path}>
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <div className="flex h-12 w-12 items-center justify-center rounded-md text-black">
                              <span
                                className={classNames(
                                  "flex-shrink-0 text-center text-3xl text-current",
                                  child.icon
                                )}
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <div className="ml-2 w-0 flex-1">
                            <span className="truncate text-base text-black">
                              {child.name}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex-auto px-4 py-4 md:px-8 md:py-8">
        <Outlet />
      </div>
    </div>
  );
}

export const AccountResource: ResourceProps = {
  name: "Account",
  path: "account/*",
};
