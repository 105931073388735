import { Button } from "../../../components/form";
import { Product } from "../../../graphql/shop";
import SpecialCard from "./SpecialCard";

export default function SpecialSection({ products }: { products: Product[] }) {
  if (products.length === 0) return null;
  const filteredSpecials =
    products.length > 3 ? products.slice(0, 3) : products;
  return (
    <section className="mt-6 md:mt-8">
      <header className="mb-4 flex items-end justify-between">
        <h3 className="text-xl font-medium">Special offers</h3>
        <div>
          <Button type="link" href="/special" variant="secondary">
            View All
          </Button>
        </div>
      </header>
      <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-4">
        {filteredSpecials.map((product) => (
          <SpecialCard key={product.id} product={product} />
        ))}
      </div>
    </section>
  );
}
