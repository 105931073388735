import { Button } from "../../../components/form";
import { Product } from "../../../graphql/shop";
import RecentCard from "./RecentCard";

export default function RecentProducts({
  products,
  count,
}: {
  products: Product[];
  count: number;
}) {
  if (products.length === 0) return null;
  const filteredRecents = products.slice(0, count);
  return (
    <section className="mt-6 md:mt-8">
      <header className="mb-4 flex items-end justify-between">
        <h3 className="text-xl font-medium">Recent Products</h3>
        <div>
          <Button type="link" href="/shop" variant="secondary">
            View All
          </Button>
        </div>
      </header>
      <div className="grid gap-4 sm:grid-cols-2 2xl:grid-cols-3">
        {filteredRecents.map((product) => (
          <RecentCard key={product.id} product={product} />
        ))}
      </div>
    </section>
  );
}
