import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { ApolloSandbox } from "@apollo/sandbox/react";

import App from "../App";
import { MasterLayout } from "../layouts/MasterLayout";

import { AuthPage, Logout, useAuth } from "../modules/auth";
import { ErrorsPage } from "../modules/errors";

import { DashboardPage, DashboardResource } from "../modules/dashboard";
import { AccountPage, AccountResource } from "../modules/account";
import { ShippingPage, ShippingResource } from "../modules/shipping";
import { CheckoutPage, CheckoutResource } from "../modules/checkout";
import { CompletedPage, CompletedResource } from "../modules/completed";
import { CartPage, CartResource } from "../modules/cart";
import { ShopPage, ShopResource } from "../modules/shop";
import { SpecialPage, SpecialResource } from "../modules/special";

import { Loader } from "../animations";
import { NotMatch, NetworkStatus } from "../components/core";

const { REACT_APP_URL } = process.env;

export default function AppRoutes() {
  const { isLocked, refresh } = useAuth();

  let elements =
    !isLocked && refresh
      ? [
          {
            path: "auth/*",
            children: [
              {
                path: "login",
                element: <Navigate to="/" />,
              },
              {
                path: "register",
                element: <Navigate to="/" />,
              },
              {
                path: "forgot-password",
                element: <Navigate to="/" />,
              },
              {
                path: "reset-password",
                element: <Navigate to="/" />,
              },
              {
                path: "create-password",
                element: <Navigate to="/" />,
              },
              {
                path: "challenge/*",
                element: <Navigate to="/" />,
              },
            ],
          },
          {
            path: DashboardResource.path,
            element: (
              <MasterLayout>
                <DashboardPage />
              </MasterLayout>
            ),
          },
          {
            path: ShopResource.path,
            element: (
              <MasterLayout>
                <ShopPage />
              </MasterLayout>
            ),
          },
          {
            path: SpecialResource.path,
            element: (
              <MasterLayout>
                <SpecialPage />
              </MasterLayout>
            ),
          },
          {
            path: AccountResource.path,
            element: (
              <MasterLayout navBorder={false}>
                <AccountPage />
              </MasterLayout>
            ),
          },

          {
            path: CartResource.path,
            element: <CartPage />,
          },
          {
            path: ShippingResource.path,
            element: <ShippingPage />,
          },
          {
            path: CheckoutResource.path,
            element: <CheckoutPage />,
          },
          {
            path: CompletedResource.path,
            element: <CompletedPage />,
          },
          {
            index: true,
            element: <Navigate to="/dashboard" replace={true} />,
          },
        ]
      : [
          {
            path: "auth/*",
            element: <AuthPage />,
          },
          {
            path: "*",
            element: <Navigate to="/auth/login" replace={true} />,
          },
          {
            index: true,
            element: <Navigate to="/auth/login" replace={true} />,
          },
        ];

  let router = createBrowserRouter(
    [
      {
        path: "/",
        element: <App />,
        errorElement: <NotMatch />,
        children: [
          {
            path: "login",
            element: <Navigate to="/auth/login" />,
          },
          {
            path: "register",
            element: <Navigate to="/auth/register" />,
          },
          {
            path: "logout",
            element: <Logout />,
          },
          {
            path: "error/*",
            element: <ErrorsPage />,
          },
          ...elements,
        ],
      },
      {
        path: "graphql",
        element: (
          <div className="h-screen [&>*]:h-full">
            <ApolloSandbox initialEndpoint="https://api.alphafresh.planetmedia.dev/graphql" />
          </div>
        ),
      },
    ],
    {
      basename: REACT_APP_URL,
    }
  );

  return (
    <>
      <NetworkStatus />
      <RouterProvider router={router} fallbackElement={<Loader loading />} />
    </>
  );
}
