import { Fragment, useCallback, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/form";
import { useNotifyContext, NotifyType } from "../../contexts/NotifyContext";
import { ProductCard } from "./ProductCard";
import { useAccount } from "../../contexts/GenContext";
import { AlertModal, AlertType, useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../modules/auth";
import { useCart } from "../../modules/cart/core/CartProvider";
import { CartItem } from "../../graphql/cart";

export function Sidebar() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { addNotify } = useNotifyContext();
  const { cartOpen, toggleCart, cartItems, cartCount, cartTotal, clearCart } =
    useCart();
  const { currentUser } = useAuth();
  const { toggleAccount, isAbnCompleted } = useAccount();

  const handleCheckout = useCallback(async () => {
    if (cartCount === 0) return;
    if (currentUser?.customerType === 1 && !isAbnCompleted())
      return setOrderWarning(true);
    toggleCart(false);
    navigate("/checkout/shipping");
  }, [
    cartCount,
    currentUser?.customerType,
    isAbnCompleted,
    toggleCart,
    navigate,
  ]);

  const handleClearCart = useCallback(() => {
    setLoading(true);
    clearCart()
      .then(() => toggleCart(false))
      .catch((error: { message: string }) => {
        console.error(error);
        addNotify({
          type: NotifyType.ERROR,
          title: "Failed to clear cart!",
          message: error.message,
        });
      })
      .finally(() => setLoading(false));
  }, [clearCart, toggleCart]);

  const [isOrderWarning, setOrderWarning] = useState(false);

  const OrderWarning = useAlert({
    open: isOrderWarning,
    title: "Profile Incomplete!",
    message: "ABN number is required to place an order.",
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    type: AlertType.WARNING,
    cancelText: "Cancel",
    onCancel: () => {
      setOrderWarning(false);
    },
    confirmText: "Complete Profile",
    onConfirm() {
      setOrderWarning(false);
      toggleAccount();
    },
  });

  return (
    <>
      <OrderWarning />
      <Transition.Root show={cartOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleCart}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-10 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Shopping Cart
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-900 hover:text-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                              onClick={() => toggleCart(false)}
                            >
                              <span className="sr-only">Close cart</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 divide-y divide-gray-200 overflow-y-auto border-t border-gray-200 p-6">
                        {cartItems.length > 0 ? (
                          cartItems.map((cartItem: CartItem) => (
                            <ProductCard
                              key={cartItem.id}
                              cartItem={cartItem}
                            />
                          ))
                        ) : (
                          <div className="py-10 text-center md:py-16">
                            <p className="mb-4">
                              There are no items in your cart yet.
                            </p>
                            <Button
                              variant="tertiary"
                              onClick={() => {
                                toggleCart(false);
                                navigate("/shop");
                              }}
                            >
                              Continue shopping
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="relative p-6">
                        <div className="flex justify-end">
                          <Button
                            variant="text"
                            className="text-xs font-medium uppercase tracking-wider hover:underline"
                            onClick={handleClearCart}
                          >
                            Clear All
                          </Button>
                        </div>
                        <div className="space-y-4 rounded-lg border border-gray-200 p-5">
                          <div className="flex items-center justify-between text-base font-light">
                            Sub Total:
                            <span className="font-medium">${cartTotal}</span>
                          </div>
                          <div className="flex items-center justify-between text-base font-semibold">
                            Estimated Total
                            <span className="text-lg">${cartTotal}</span>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            <Button
                              variant="secondary"
                              className="w-full justify-center"
                              onClick={() => {
                                toggleCart(false);
                                navigate("/shop");
                              }}
                            >
                              Continue Shopping
                            </Button>
                            <Button
                              variant="tertiary"
                              className="w-full justify-center"
                              disabled={cartCount === 0 || loading}
                              onClick={handleCheckout}
                            >
                              Checkout
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
